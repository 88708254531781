import "@theme/styles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styleText } from "utils/FunctionsBundle";
import { CSSProperties } from "react";
// import { useTranslation } from "react-i18next";

// ############################# INTERFACES & PROPS #############################
// ITEMS LIST #############################
interface ItemsListProps {
  slider?: boolean;
  sliderSettings?: Object;
  title?: string;
  titleColor?: string;
  titleAlign?: "left" | "center" | "right";
  contentAlignX?: "left" | "center" | "right";
  contentAlignY?: "start" | "center" | "end";
  vertical?: boolean;
}

const ItemsListDefaultProps: ItemsListProps = {
  slider: false,
  titleColor: "black",
  titleAlign: "left",
  contentAlignX: "center",
  contentAlignY: "center",
  vertical: false,
};

// ITEMS LIST ITEM #############################
interface ItemsListItemProps {
  label?: string;
  labelColor?: string;
  labelSize?: string;
  imageURL?: string;
  imageHeight?: string;
  imageWidth?: string;
  imageStyle?: CSSProperties;
  icon?: boolean;
  margin?: boolean;
  marginLeft?: string;
  marginRight?: string;
  externalURL?: string;
  style?: CSSProperties;
}

const ItemsListItemDefaultProps: ItemsListItemProps = {
  labelColor: "black",
  labelSize: "auto",
  imageHeight: "auto",
  imageWidth: "auto",
  icon: false,
  margin: true,
  marginLeft: "32px",
  marginRight: "32px",
};

// SLIDER SETTINGS #############################
const sliderSettings = {
  accessibility: true, // Enable arrow key navigation
  adaptativeHeight: false, // Slide height auto
  afterChange: (index) => {}, // index change callback
  appendDots: (dots) => <ul>{dots}</ul>, // Custom dots
  arrows: false, // Show arrows
  asNavFor: undefined, // Ref to another slider and sync it
  autoplaySpeed: 3000, // Delay in each auto scroll (ms)
  beforeChange: (oldIndex, newIndex) => {}, // index change callback
  centerMode: false, // Center current slide
  centerPadding: "50px", // center padding
  className: "", // CSS class name
  customPaging: (i) => <button>{i}</button>, //

  swipeToSlide: true,
  slidesToShow: 2,
  infinite: false,
  dots: false,
  speed: 500,
  autoplay: false,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
  // responsive: [
  //   {
  //     breakpoint: 850,
  //     settings: {
  //       slidesToShow: 2.5,
  //     },
  //   },
  //   {
  //     breakpoint: 600,
  //     settings: {
  //       slidesToShow: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 480,
  //     settings: {
  //       slidesToShow: 1,
  //     },
  //   },
  // ],
};

// ############################# FUNCTION COMPONENTS #############################
// ITEMS LIST #############################
const ItemsList: React.FunctionComponent<ItemsListProps> = (props) => {
  // useTranslation(); // Auto translate text

  var ItemsListClass = "items-list ";
  if (props.slider) ItemsListClass += " margin-h-12px";
  ItemsListClass += " x-" + props.contentAlignX;
  ItemsListClass += " y-" + props.contentAlignY;

  var titleClass = "text-" + props.titleAlign;

  const settings = { ...sliderSettings, ...props.sliderSettings };

  return (
    <div className={ItemsListClass}>
      {props.title !== undefined ? (
        <h2 className={titleClass} style={{ color: props.titleColor }}>
          {styleText({ text: props.title })}
        </h2>
      ) : (
        <></>
      )}

      {props.slider ? (
        <Slider {...settings}>{props.children}</Slider>
      ) : (
        <div
          className="items-list-container"
          style={
            props.vertical
              ? {
                  flexDirection: "column",
                }
              : {}
          }
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

// ITEMS LIST ITEM #############################
const ItemsListItem: React.FunctionComponent<ItemsListItemProps> = (props) => {
  var iconClass = "";
  if (props.icon) iconClass += " as-icon";

  var marginStyle: CSSProperties = {
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    ...props.style,
  };

  return (
    <div className="list-item" style={props.margin ? marginStyle : {}}>
      {props.imageURL !== undefined ? (
        <img
          height={props.imageHeight}
          width={props.imageWidth}
          alt="item"
          className={iconClass}
          style={
            props.label !== undefined || props.children !== undefined
              ? {
                  marginRight: "16px",
                  height: props.imageHeight,
                  objectFit: "cover",
                  ...props.imageStyle,
                }
              : {
                  height: props.imageHeight,
                  objectFit: "cover",
                  ...props.imageStyle,
                }
          }
          src={props.imageURL}
        />
      ) : (
        <></>
      )}

      {props.label !== undefined ? (
        props.externalURL ? (
          <a
            className={iconClass + " as-h5"}
            href={props.externalURL}
            style={{ color: props.labelColor, fontSize: props.labelSize }}
          >
            {styleText({ text: props.label })}
          </a>
        ) : (
          <h5
            className={iconClass}
            style={{ color: props.labelColor, fontSize: props.labelSize }}
          >
            {styleText({ text: props.label })}
          </h5>
        )
      ) : (
        <></>
      )}

      {props.children}
    </div>
  );
};

// ############################# EXPORTS #############################
ItemsList.defaultProps = ItemsListDefaultProps;
ItemsListItem.defaultProps = ItemsListItemDefaultProps;

export default Object.assign(ItemsList, {
  Item: ItemsListItem,
});
