import "@theme/styles.scss";
import { CSSProperties } from "react";
import { parseRem } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// BREAKER #############################
interface BreakerProps {
  size?: string;
  style?: CSSProperties;
  background?: string;
  backgroundPosition?: "top" | "bottom" | "top-in" | "bottom-in" | "center";
  backgroundStyle?: CSSProperties;
  responsive?: boolean;
  imageObjectPosition?: string;
  gradient?: boolean;
}

const BreakerDefaultProps: BreakerProps = {
  size: "64px",
  backgroundPosition: "center",
  responsive: true,
  gradient: false,
};

// ############################# FUNCTION COMPONENTS #############################
// BREAKER #############################
const Breaker: React.FunctionComponent<BreakerProps> = (props) => {
  const rem = (val?: string) => {
    if (props.responsive && val !== undefined) return parseRem(val);
    else return val;
  };

  return (
    <div
      className="breaker"
      style={{ height: rem(props.size), ...props.style }}
    >
      {props.background !== undefined ? (
        <img
          src={props.background}
          className={
            "bg " +
            props.backgroundPosition +
            (props.imageObjectPosition ? " back-center " : "")
          }
          alt=""
          style={{
            ...props.backgroundStyle,
            objectPosition: props.imageObjectPosition,
          }}
        />
      ) : (
        <></>
      )}

      {props.gradient ? (
        <canvas id="gradient-canvas" data-transition-in></canvas>
      ) : (
        <></>
      )}

      {props.children}
    </div>
  );
};

// ############################# EXPORTS #############################
Breaker.defaultProps = BreakerDefaultProps;

export default Breaker;
