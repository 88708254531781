import HomeScreen from "@screens/HomeScreen";

function HomeContainer() {
  return (
    <>
      <HomeScreen />
    </>
  );
}

export default HomeContainer;
