import ContactForm from "@components/ContactForm";

function ContactFormContainer() {
  return (
    <>
      <ContactForm />
    </>
  );
}

export default ContactFormContainer;
