import "@theme/styles.scss";
import { CSSProperties } from "react";
// import { useTranslation } from "react-i18next";
import { styleText, styleTextOptions } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// TITLE #############################

interface TitleProps {
  head?: string;
  headColor?: string;

  main?: string;
  mainColor?: string;

  body?: string;
  bodyColor?: string;

  buttonText?: string;
  buttonURL?: string;

  align?: "left" | "right" | "center";
  styleTextOptions?: styleTextOptions;
  style?: CSSProperties;
}

const TitleDefaultProps: TitleProps = {
  head: "",
  headColor: "$color-primary",

  mainColor: "$color-primary",

  bodyColor: "$color-primary",

  buttonURL: "/",

  align: "center",
};

// ############################# FUNCTION COMPONENTS #############################
// TITLE #############################
const Title: React.FunctionComponent<TitleProps> = (props) => {
  // useTranslation(); // Auto translate text

  var style: CSSProperties = {
    textAlign: props.align,
    alignItems:
      props.align === "left"
        ? "flex-start"
        : props.align === "right"
        ? "flex-end"
        : "center",
  };

  return (
    <div className={"title"} style={{ ...style, ...props.style }}>
      <h1 className={"title-head"} style={{ color: props.headColor }}>
        {styleText({ ...props.styleTextOptions, text: props.head })}
      </h1>

      {props.main ? (
        <h1 className={"title-main"} style={{ color: props.mainColor }}>
          {styleText({ ...props.styleTextOptions, text: props.main })}
        </h1>
      ) : (
        <></>
      )}

      {props.body ? (
        <p className={"title-body"} style={{ color: props.bodyColor }}>
          {styleText({ ...props.styleTextOptions, text: props.body })}
        </p>
      ) : (
        <></>
      )}

      {props.buttonText ? (
        <a className="title-link" href={props.buttonURL}>
          {styleText({ ...props.styleTextOptions, text: props.buttonText })}
        </a>
      ) : (
        <></>
      )}

      {props.children}
    </div>
  );
};

// ############################# EXPORTS #############################
Title.defaultProps = TitleDefaultProps;

export default Title;
