import ContactFormContainer from "@containers/ContactFormContainer";
import Breaker from "@components/Breaker";
// import { imgObj } from "utils/ImageRepo";
// import ItemsList from "@components/ItemsList";
// import Marginalizer from "@components/Marginalizer";
// import Menu from "@components/Menu";
import Footer from "@components/Footer";
import Header from "@components/Header";
// import TranslateButton from "@components/TranslateButton";
// import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { parseRem } from "utils/FunctionsBundle";
import Band from "@components/Band";
import ImageHolder from "@components/ImageHolder";
import Title from "@components/Title";
//import "..src/i18n";
import React from "react";
import { useTranslation } from "react-i18next";
import TranslateButton from "@components/TranslateButton";
import { InlineWidget } from "react-calendly";


const ContactScreen = () => {
  const { t } = useTranslation();
  // const responsive500 = useMediaQuery({ query: "(max-width: 500px)" });
  // const responsive1024 = useMediaQuery({ query: "(max-width: 1024px)" });
  // const responsive1500 = useMediaQuery({ query: "(max-width: 1500px)" });

  return (
    <div className="screen">
      <Helmet>
        <title>Get Started Today | INOWU Development</title>
        <meta name="description" content="Ready to get started with your new website? Send INOWU a message 
          today – we'd be happy to help you."></meta>
      </Helmet>

      <Header />
      <TranslateButton/>

      <ImageHolder
        imageLayer={"bottom"}
        // imageStyle={{
        //   height: footerCoords.offsetTop,
        //   // height: footerRef.current
        //   //   ? `${footerRef.current.offsetTop}px`
        //   //   : `clamp(100vh, ${parseRem("1040px")}, ${parseRem("1440px")} )`,
        // }} // height: parseRem("980px")
        imagePosition={5}
        canvasPosition={"top-in"}
        style={{ width: "100%", height: "100%", position: "absolute" }}
        canvasStyle={{ width: "100%", height: "100%" }}
        imageStyle={{ width: "100%", height: "100%" }}
        gradient
      />

      <Breaker size={parseRem("152px")} />

      <Title
        head={t("contactScreen.title.head"/*`*Ready to work with us? $Send us a message$!*`*/)}
        headColor="white"
      />
      <Breaker size={parseRem("87px")} />

      <Band background="white">
        <div className="flex-column">
          <Breaker size={parseRem("57px")} />

          

            <Title
              head={t("contactScreen.title.subtitle")}
              styleTextOptions={{
                linkList: ["#popup1"],
                fontSizeList: [parseRem("54px")],
              }}
              
            />
          <div id="popup1" className="overlay">
            <div className="popup">
              <a className="close" href="/#">&times;</a>
              <div className="content">
                <InlineWidget url="https://calendly.com/inowudev/15min?month=2022-07" styles={{height: "1000px", marginTop: "2%"}}/>              
              </div>
            </div>
          </div>

          <ContactFormContainer />

          <Breaker size={parseRem("77px")} />
          
        </div>
      </Band>
      


      {/*
      <Marginalizer
        right={responsive1024 ? "100px" : "150px"}
        left={responsive1024 ? "100px" : "200px"}
        flex
        column={responsive1024}
      >
        <Menu
          title={"ContactScreen.Menu.0.title"}
          walled={responsive500}
          titleColor="white"
        >
          <Menu.Item
            head={"ContactScreen.Menu.0.item.0.head"}
            headColor="white"
            body={"ContactScreen.Menu.0.item.0.body"}
            bodyColor="white"
            headExternalURL="https://goo.gl/maps/J2bRjuxdpcEoabSJ6"
            bodyExternalURL="https://goo.gl/maps/J2bRjuxdpcEoabSJ6"
          />

          <Menu.Item
            head={"ContactScreen.Menu.0.item.1.head"}
            headColor="white"
            body={"ContactScreen.Menu.0.item.1.body"}
            bodyColor="white"
            headExternalURL="https://goo.gl/maps/Eb3JUa5euwEQujmz9"
            bodyExternalURL="https://goo.gl/maps/Eb3JUa5euwEQujmz9"
          />
        </Menu>
      </Marginalizer>

      <Breaker size="16px" />

      <ItemsList
        title={"ContactScreen.ItemsList.0.title"}
        titleColor="white"
        titleAlign="center"
        vertical={responsive500}
        slider={responsive1500 && !responsive500}
        sliderSettings={
          responsive1500 && !responsive500
            ? {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                speed: 4000,
                autoplaySpeed: 1000,
                variableWidth: true,
                infinite: true,
                responsive: [
                  {
                    breakpoint: 850,
                    settings: {
                      slidesToShow: 2.3,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ],
              }
            : {}
        }
        contentAlignX="left"
        contentAlignY="start"
      >
        <ItemsList.Item
          imageURL={imgObj.icon.facebook}
          label={"ContactScreen.ItemsList.0.label.0"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
          externalURL="https://www.facebook.com/inowudev/"
        />
        <ItemsList.Item
          imageURL={imgObj.icon.instagram}
          label={"ContactScreen.ItemsList.0.label.1"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
          externalURL="https://www.instagram.com/inowu_development/"
        />
        <ItemsList.Item
          imageURL={imgObj.icon.linkedin}
          label={"ContactScreen.ItemsList.0.label.2"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
          externalURL="https://www.linkedin.com/company/inowu-development/"
        />
        <ItemsList.Item
          imageURL={imgObj.icon.whatsapp}
          label={"ContactScreen.ItemsList.0.label.3"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
        />
        <ItemsList.Item
          imageURL={imgObj.icon.phone}
          label={"ContactScreen.ItemsList.0.label.4"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
        />
        <ItemsList.Item
          imageURL={imgObj.icon.mail}
          label={"ContactScreen.ItemsList.0.label.5"}
          labelColor="white"
          margin={responsive1500 && !responsive500}
          imageStyle={{ marginRight: "8px" }}
        />
      </ItemsList> */}

      <Footer breakerSize={parseRem("142px")} />
    </div>
  );
};

export default ContactScreen;
