import "@theme/styles.scss";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { styleText } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// BAND #############################
interface BandProps {
  title?: string;
  titleColor?: string;
  background?: string;
  shadow?: boolean;
  style?: CSSProperties;
}

const BandDefaultProps: BandProps = {
  titleColor: "white",
  background: "#003a54",
  shadow: false,
};

// ############################# FUNCTION COMPONENTS #############################
// BAND #############################
const Band: React.FunctionComponent<BandProps> = (props) => {
  useTranslation(); // Auto translate text

  return (
    <div
      className="band"
      style={{
        background: `${props.background}`,
        width: "100%",
        boxShadow: props.shadow ? "" : "none",
        ...props.style,
      }}
    >
      {props.title !== undefined ? (
        <h3 className="text-center" style={{ color: props.titleColor }}>
          {styleText({ text: props.title })}
        </h3>
      ) : (
        <></>
      )}

      {props.children}
    </div>
  );
};

// ############################# EXPORTS #############################
Band.defaultProps = BandDefaultProps;

export default Band;
