import React, { useEffect, useState } from "react";
import "@theme/styles.scss";
import { useTranslation } from "react-i18next";
import { imgObj } from "utils/ImageRepo";
import { styleText } from "utils/FunctionsBundle";


// ############################# INTERFACES & PROPS #############################
// HEADER #############################
interface HeaderProps {
  transition?: boolean;
}

const HeaderDefaultProps: HeaderProps = {
  transition: true,
};

// ############################# FUNCTION COMPONENTS #############################
// HEADER #############################
const Header: React.FunctionComponent<HeaderProps> = (props) => {
  useTranslation(); // Auto translate text
  // const retract = useMediaQuery({ query: "(max-width: 600px)" });
  const { t } = useTranslation();

  const [scrolled, setScrolled] = useState(window.scrollY > 100);
  const changeScroll = () => {
    if (window.scrollY > 100) setScrolled(true);
    else setScrolled(false);
  };

  useEffect(() => {
    if (props.transition) document.addEventListener("scroll", changeScroll);
    else setScrolled(true);
  }, [props.transition]);

  return (
    <div className={"header"}>
      {/* Background color for animation */}
      <div className={"header-background" + (scrolled ? " " : " off")} />

      <a className="header-logo" href="/">
        <img
          src={scrolled ? imgObj.logo.inowu_gradient : imgObj.logo.inowu_white}
          alt="Inowu logo"
        />
      </a>
      {/* <Marginalizer
              size={
                responsive470
                  ? "0rem"
                  : responsive770
                  ? parseRem("50px")
                  : parseRem("112px")
              }
        ></Marginalizer> */}

      <div className="header-links-container">
        <a
          className={"header-links " + (scrolled ? " " : " white")}
          href="/home"
        >
          {styleText({ text: t("header.link.0") })}
        </a>
        <a
          className={"header-links " + (scrolled ? " " : " white")}
          href="/services"
        >
          {styleText({ text:  t("header.link.1") })}
        </a>
        <a
          className={"header-links " + (scrolled ? " " : " white")}
          href="/portfolio"
        >
          {styleText({ text:  t("header.link.2") })}
        </a>
        <a
          className={"header-links " + (scrolled ? " " : " white")}
          href="/contact"
        >
          {styleText({ text:  t("header.link.3") })}
        </a>
      </div>
    </div>
  );
};

// ############################# EXPORTS #############################
Header.defaultProps = HeaderDefaultProps;

export default Header;
