import "@theme/styles.scss";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactSlider from "react-slider";
import { useSwipeable } from "react-swipeable";
import { boundaries, useOnScreen } from "utils/FunctionsBundle";

// import { styleText } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// Slider #############################
interface SliderProps {
  autoChange?: boolean;
  autoChangeTimer?: number;
  getIndex?: Function;
  focusAlign?: "center" | "left" | "right" | "auto";
  themeColor?: string;
  goOnWithBand?: boolean;
}

const SliderDefaultProps: SliderProps = {
  autoChange: false,
  autoChangeTimer: 5000,
  getIndex: (index: number) => {
    console.log(index);
  },
  focusAlign: "auto",
  themeColor: "#00e6be",
  goOnWithBand: false,
};

// ############################# FUNCTION COMPONENTS #############################
// Slider #############################
const Slider: React.FunctionComponent<SliderProps> = (props) => {
  // useTranslation(); // Auto translate text
  const [activeSlide, setActiveSlide] = useState(0);
  const [bandCoords, setBandCoords] = useState(0);
  const [bandSections, setBandSections] = useState(new Array<number>());
  const [timerActive, setTimerActive] = useState(props.autoChange);
  const [timerChange, setTimerChange] = useState(false);

  const num = React.Children.count(props.children);

  const refArr = useRef(Array<any>());
  const sliderBandRef = useRef<any>();
  const ref = useRef<any>();
  const visible = useOnScreen(ref);

  // Guardar todas las referencias de los objetos
  if (refArr.current.length !== num) {
    refArr.current = new Array(num).fill([]).map((_, index) => createRef());
  }

  const handleMoveThumb = useCallback(
    (value: number, thumbIndex: number) => {
      setActiveSlide(value);
      props.getIndex!(value);
      const obj = refArr.current[value].current;
      const elFocusX = obj.offsetLeft + obj.scrollWidth / 2;
      const bandWidth = sliderBandRef.current!.scrollWidth;
      const screenWidth = sliderBandRef.current!.offsetWidth;

      // console.log(elFocusX); // Y
      // console.log(bandWidth); // 100%
      // console.log(screenWidth); // X

      var calc: number;
      if (props.focusAlign === "left") {
        calc = obj.offsetLeft;
      } else {
        calc =
          elFocusX < screenWidth / 2
            ? 0
            : elFocusX > bandWidth - screenWidth / 2
            ? bandWidth - screenWidth
            : elFocusX - screenWidth / 2;
      }

      sliderBandRef.current!.style.transform = `translateX(-${calc}px)`;
    },
    [props.getIndex, props.focusAlign]
  );

  const swipeControls = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    delta: 1,
    preventDefaultTouchmoveEvent: true,
    onSwipeStart: (eventData) => {
      const aux = sliderBandRef.current!.style.transform;
      // const test = "translateX(-01231238px)";
      // Guardar la coordenada de la banda
      setBandCoords(Number.parseFloat(aux.slice(11, -3)));
      // Remover transición de la banda
      sliderBandRef.current!.style.transition = "none";
    },

    onSwiping: (eventData) => {
      const bandWidth = sliderBandRef.current!.scrollWidth;
      const screenWidth = sliderBandRef.current!.offsetWidth;

      var minLimit = screenWidth - bandWidth;
      if (props.focusAlign === "left") minLimit = -bandWidth;

      const resp = boundaries(minLimit, bandCoords + eventData.deltaX, 0);

      sliderBandRef.current!.style.transform = `translateX(${resp}px)`;
      var val = focusClosestElement();
      setActiveSlide(val);
      props.getIndex!(val);
    },

    onSwiped: (eventData) => {
      const aux = sliderBandRef.current!.style.transform;
      setBandCoords(Number.parseFloat(aux.slice(11, -3)));
      // Reponer transición de la banda
      sliderBandRef.current!.style.transition = "transform 0.4s ease";
    },

    onTap: ({ event }) => {
      const bandWidth = sliderBandRef.current!.scrollWidth;
      const screenWidth = sliderBandRef.current!.offsetWidth;

      const docMidPointX = event.view!["outerWidth"]; // tamaño total de la pantalla

      /*
        MouseEvent

        event.clientX => Coords desde el viewport (alias `event.x`)
        event.screenX => Coords desde la pantalla del dispositivo
        event.offsetX => Coords desde el objeto al que se hizo clic 
        event.layerX => depreciado
        event.pageX => Coords desde el documento entero, contando scrolls
      */

      var minLimit = screenWidth - bandWidth;
      if (props.focusAlign === "left") minLimit = -bandWidth;

      const resp = boundaries(
        minLimit,
        bandCoords + (docMidPointX / 2 - event["pageX"]),
        0
      );

      setBandCoords(resp);
      sliderBandRef.current!.style.transition = "transform 0.4s ease";
      sliderBandRef.current!.style.transform = `translateX(${resp}px)`;
      setActiveSlide(focusClosestElement());
    },
  });

  /**
   * Regresa el índice del elemento más cercano al medio de la pantalla
   * @returns un número de indice, entre `0` y `num`
   */
  function focusClosestElement() {
    var newBandSections = bandSections;

    if (bandSections.length === 0) {
      newBandSections = refArr.current.map((value, index) => {
        const elFocusX =
          value.current.offsetLeft - (0 * value.current.scrollWidth) / 1; // X
        const bandWidth = sliderBandRef.current!.scrollWidth;

        if (props.focusAlign === "left") {
          return value.current.offsetLeft - 128;
        }

        return (elFocusX / bandWidth) * 100;
      });

      setBandSections(newBandSections);
    }

    const bandWidth = sliderBandRef.current!.scrollWidth;
    const screenWidth = sliderBandRef.current!.offsetWidth;

    const aux = sliderBandRef.current!.style.transform;
    const coordsBand = Math.abs(Number.parseFloat(aux.slice(11, -3)));

    var porcentaje = (coordsBand / Math.abs(screenWidth - bandWidth)) * 100;

    if (props.focusAlign === "left") {
      porcentaje = coordsBand;
    }

    for (let i = newBandSections.length - 1; i > -1; i--) {
      if (porcentaje >= newBandSections[i]) return i;
    }

    return 0;
  }

  useEffect(() => {
    if (timerChange) {
      setTimerChange(false);

      if (timerActive && visible) {
        const change = num === activeSlide + 1 ? 0 : activeSlide + 1;

        handleMoveThumb(change, 0);
      }
    }
  }, [timerChange, timerActive, visible, num, activeSlide, handleMoveThumb]);

  useEffect(() => {
    const int = setInterval(() => setTimerChange(true), props.autoChangeTimer);

    return () => {
      clearInterval(int);
    };
  }, [props.autoChangeTimer]);

  const sliderElement = (
    <ReactSlider
      className="custom-slider-control"
      markClassName="custom-slider-mark"
      thumbClassName="custom-slider-thumb"
      trackClassName="custom-slider-track"
      defaultValue={0}
      marks
      orientation="horizontal"
      onChange={handleMoveThumb}
      min={0}
      max={num - 1}
      renderThumb={(tprops, state) => {
        return (
          <div
            {...tprops}
            style={{ ...tprops.style, borderColor: props.themeColor }}
          >
            {state.valueNow !== num - 1 ? (
              <div
                className="custom-slider-control-bar"
                style={{
                  borderBottomColor: props.themeColor,
                  width:
                    (sliderBandRef.current
                      ? sliderBandRef.current.offsetWidth
                      : 16) /
                    num /
                    2,
                }}
              />
            ) : (
              <></>
            )}
          </div>
        );
      }}
      renderMark={(mprops) => {
        return (
          <div
            {...mprops}
            style={{
              ...mprops.style,
              backgroundColor:
                mprops.key && mprops.key > activeSlide
                  ? undefined
                  : props.themeColor,
              borderColor: props.themeColor,
            }}
            className={
              mprops.key && mprops.key > activeSlide
                ? "custom-slider-mark off"
                : "custom-slider-mark on"
            }
          ></div>
        );
      }}
      renderTrack={(tprops) => {
        return (
          <div
            {...tprops}
            style={{
              ...tprops.style,
              borderBottomColor: tprops.className?.includes(
                "custom-slider-track-1"
              )
                ? undefined
                : props.themeColor,
            }}
          ></div>
        );
      }}
      value={activeSlide}
    />
  );

  // Renderizar objetos
  return (
    <div
      className="custom-slider"
      ref={ref}
      onMouseEnter={() => setTimerActive(false)}
      onMouseLeave={() => setTimerActive(props.autoChange)}
    >
      {!props.goOnWithBand ? (
        sliderElement
      ) : (
        <div style={{ overflow: "hidden", width: "100%" }}>
          <div
            style={{
              width:
                refArr.current[refArr.current.length - 1]?.current?.offsetLeft, // sliderBandRef.current?.scrollWidth,
              transition: "transform 0.4s ease",
              transform: `translateX(${-(
                (activeSlide *
                  (refArr.current[refArr.current.length - 1]?.current
                    ?.offsetLeft -
                    34)) /
                (num - 1)
              )}px)`,
            }}
          >
            {sliderBandRef.current?.scrollWidth &&
            refArr.current[refArr.current.length - 1]?.current?.offsetLeft ? (
              sliderElement
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      <div className="custom-slider-screen" {...swipeControls}>
        <div
          className="custom-slider-band"
          ref={sliderBandRef}
          style={{
            transform: "translateX(0px)",
            transition: "transform 0.4s ease",
          }}
        >
          {React.Children.map(props.children, (child, index) => {
            return (
              <div
                ref={refArr.current[index]}
                key={index}
                className={
                  "custom-slider-item no-selectable-all " +
                  (index === activeSlide ? "active" : "")
                }
                unselectable="on"
                onSelect={(e) => {
                  e.preventDefault();
                }}
                onSelectCapture={(e) => {
                  e.preventDefault();
                }}
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// ############################# EXPORTS #############################
Slider.defaultProps = SliderDefaultProps;

export default Slider;
