import PortfolioScreen from "@screens/PortfolioScreen";

const PortfolioContainer = () => {
  return (
    <>
      <PortfolioScreen />
    </>
  );
};

export default PortfolioContainer;
