import ContactScreen from "@screens/ContactScreen";

function ContactContainer() {
  return (
    <>
      <ContactScreen />
    </>
  );
}

export default ContactContainer;
