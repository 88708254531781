import Breaker from "@components/Breaker";
import Footer from "@components/Footer";
import ItemsList from "@components/ItemsList";
import {
  formatText,
  parseRem,
  styleText,
  suspendText,
} from "utils/FunctionsBundle";
import { imgObj } from "utils/ImageRepo";
import Header from "@components/Header";
import "@theme/styles.scss";
import { Helmet } from "react-helmet";
import Title from "@components/Title";
import TranslateButton from "@components/TranslateButton";
import { useTranslation } from "react-i18next";

const ServicesScreen = () => {
  const {t} = useTranslation();
  const servicesInfo = [
    {
      name: "custom_software_development",
      url: "custom-software-development",
      head: t("servicesScreen.customSofDev.head"),
      body: t("servicesScreen.customSofDev.body"),
    },
    {
      name: "website_development",
      url: "website-development",
      head: t("servicesScreen.websiteDev.head"),
      body: t("servicesScreen.websiteDev.body"),
    },
    {
      name: "mobile_app_development",
      url: "mobile-app-development",
      head: t("servicesScreen.mobileAppDev.head"),
      body: t("servicesScreen.mobileAppDev.body"),
    },
    {
      name: "react_development",
      url: "react-development",
      head: t("servicesScreen.reactDev.head"),
      body: t("servicesScreen.reactDev.body"),
    },
  ];

  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Custom Development Services | INOWU</title>
        <meta name="description" content="See how INOWU can help you: from custom app development, 
          to software development, to web development, and much more."></meta>
      </Helmet>
      <Header transition={false} />

      <Breaker size={parseRem("152px")} />

      <Title head={t("servicesScreen.titleMain")} />

      <Breaker size={parseRem("125px")} />

      <TranslateButton />

      <ItemsList>
        {servicesInfo.map((service, index) => {
          return (
            <div
              style={{
                width: parseRem("637px"),
                display: "abla",
                flexDirection: "column",
                marginBottom: parseRem("51px"),
              }}
              key={index}
            >
              <a
                href={`/services/${service.url}`}
                style={{ position: "relative" }}
              >
                <img
                  src={imgObj.bg.services[service.name]}
                  alt="service"
                  style={{
                    display:"flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    alignItems:"flex-start",
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                    border: "1px solid #EDEDED",
                  }}
                />
              </a>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: parseRem("24px"),
                  marginRight: parseRem("24px"),
                }}
              >
                <span
                  className="as-p"
                  style={{
                    marginTop: parseRem("24px"),
                    marginBottom: parseRem("10px", "4px"),
                    color: "initial",
                  }}
                >
                  {styleText({
                    text: service.head,
                    linkList: [`/services/${service.url}`],
                    linkStyles: [""],
                  })}
                </span>

                <span
                  className="as-p"
                  style={{
                    marginBottom: parseRem("15px", "7px"),
                  }}
                >
                  {suspendText(formatText({ text: service.body }), 115)}
                </span>

                <a
                  className={"custom-link pp grow right"}
                  href={`/services/${service.url}`}
                  style={{
                    fontWeight: 700,
                    fontSize: parseRem("24px"),
                    alignSelf: "flex-start",
                  }}
                >
                  {t("servicesScreen.servicesLink")}
                </a>
              </div>
            </div>
          );
        })}
      </ItemsList>

      <Footer breakerSize={parseRem("120px")} />
    </div>
  );
};

export default ServicesScreen;
