import "@theme/styles.scss";
import { useTranslation } from "react-i18next";
import TranslateIcon from '@mui/icons-material/Translate';
import { Fab } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import us_flag from "@theme/images/icon/us_flag.png";
import mx_flag from "@theme/images/icon/mx_flag.png";

// const languages = {
//   en: { nativeName: "English" },
//   es: { nativeName: "Español" },
// };

// ############################# INTERFACES & PROPS #############################
// TRANSLATE BUTTON #############################
interface TranslateProps {}

const TranslateDefaultProps: TranslateProps = {};

// ############################# FUNCTION COMPONENTS #############################
// TRANSLATE BUTTON #############################


const TranslateButton: React.FunctionComponent<TranslateProps> = (props) => {
  const { i18n } = useTranslation();
  const responsive920 = useMediaQuery({ query: "(max-width: 920px)" });
  const {t} = useTranslation();
  //const [isShown, setIsShown] = useState(false);

  function actionToggle() {
    var translate_menu = document.querySelector('.translate-menu');
    translate_menu?.classList.toggle('active')
  }
 
  return (
    <div className={"translate-menu"}
      onClick={() => actionToggle()}  
      //onMouseEnter={() => actionToggle()}
      //onMouseLeave={() => actionClose()}
      >
      <Fab
      sx={{
        border: '2px solid rgba(2,102,105)',
        ":hover": {
          background: "linear-gradient(45deg, #026669 30%, #002c2e 90%)"
        }
      }}
        size={responsive920 ? "medium" : "large"}
        style={{
        position:"fixed",
        right:"2%",
        bottom:"5%",
        color:"white",
        opacity:"95%",
        zIndex: 999,
        backgroundColor:"#026669",
        }}>
      <TranslateIcon fontSize={responsive920 ? "large" : "medium"}>
        <span>{i18n.resolvedLanguage === "en" ? "es" : "en"}</span>
      </TranslateIcon>
      </Fab>
        <ul>
          <li 
          onClick={() => {i18n.changeLanguage("en")}}>
            <img  
              style={{
                height: "10px",
                marginRight: "10%",
              }}
              alt = "deno-inowu-2"
              src={us_flag}/> 
          {t("translateButton.english")}</li>
          <li 
          onClick={() => {i18n.changeLanguage("es")}}>
            <img  
              style={{
                height: "10px",
                marginRight: "10%",
              }}
              alt ="img-inowu-3"
              src={mx_flag}/>
          {t("translateButton.spanish")}</li>
      </ul>
    </div>
  );
};

// ############################# EXPORTS #############################
TranslateButton.defaultProps = TranslateDefaultProps;

export default TranslateButton;

