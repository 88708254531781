import { useState } from 'react';
import emailjs from "@emailjs/browser";
emailjs.init("user_itfQnWMwlnZrVcVMwjZwQ"); // inowudev@gmail.com
const BillsScreen = () => {
    const WOOLFO_LOGO = "../../images/woolfo_logo.svg";
    const WOOLFO_LOGO_2 = "../../images/woolfo_gray_logo.svg";
    const logo_casa = "../../images/logos/casa_alicia.svg"
    const logo_milanesa = "../../images/logos/milanesas.svg"
    const logo_pollo = "../../images/logos/pollo_ramos.svg"
    const logo_civo = "../../images/logos/Civo.svg"
    const separate_line = "../../images/separate-line.svg"
    const X_SEPARATE = "../../images/x_separate.svg";
    const VIDEO_1 = "../../images/videos/video_1.gif"
    const VIDEO_2 = "../../images/videos/video_2.gif"
    const VIDEO_3 = "../../images/videos/video_3.gif"
    const VIDEO_4 = "../../images/videos/video_4.gif"
    const VIDEO_5 = "../../images/videos/video_5.gif"
    const logoPictures = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const [contact, setContact] = useState<any>({
        withSystem: 0,
        system: 0,
        bussinessType: 0,
        necessity: 0,
        box: 0,
        zone: 0,
        officeText: "",
        waiter: "",
        software: "",
        about: "",
        name: "",
        phone: "",
        email: "",
        commercialName: "",
        numberFranchise: "",
        state: "",
    });
    const [loader, setLoader] = useState<boolean>(false);
    const [system, setSystem] = useState<string>("");
    const [business, setBusiness] = useState<string>("");
    const [necessity, setNecessity] = useState<string>("");
    const systemChange = (option: number) => {
        setContact({
            ...contact, system: option
        })
    }
    const bussinessTypeChange = (option: number) => {
        setContact({
            ...contact, bussinessType: option
        })
    }
    const neccesityChange = (option: number) => {
        setContact({
            ...contact, necessity: option
        })
    }
    const boxChange = (option: number) => {
        setContact({
            ...contact, box: option
        })
    }
    const zoneChange = (option: number) => {
        setContact({
            ...contact, zone: option
        })
    }
    const submit = () => {
        setLoader(true);
        let contactData = {
            date: new Date(),
            withSystem: contact.withSystem,
            system: contact.system,
            bussinessType: contact.bussinessType,
            necessity: contact.necessity,
            box: contact.box,
            zone: contact.zone,
            officeText: contact.officeText,
            waiter: contact.waiter,
            software: contact.software,
            about: contact.about,
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            commercialName: contact.commercialName,
            numberFranchise: contact.numberFranchise,
            state: contact.state,
            withSystemText: "",
            systemText: "",
            bussinessTypeText: "",
            necessityText: "",
            boxText: "",
            zoneText: "",
        }
        if (contactData.withSystem === 0) {
            contactData.withSystemText = "Si"
            if (contactData.system === 0) {
                contactData.systemText = "SoftRestaurant"
            }
            if (contactData.system === 1) {
                contactData.systemText = system
            }
            if (contactData.bussinessType === 0) {
                contactData.bussinessTypeText = "Cafetería"
            }
            if (contactData.bussinessType === 1) {
                contactData.bussinessTypeText = "Restaurante"
            }
            if (contactData.bussinessType === 2) {
                contactData.bussinessTypeText = "Bar"
            }
            if (contactData.bussinessType === 3) {
                contactData.bussinessTypeText = "Antro"
            }
            if (contactData.bussinessType === 4) {
                contactData.bussinessTypeText = business
            }
            if (contactData.necessity === 0) {
                contactData.necessityText = "Capacitación"
            }
            if (contactData.necessity === 1) {
                contactData.necessityText = "Impresoras"
            }
            if (contactData.necessity === 2) {
                contactData.necessityText = "Soporte general"
            }
            if (contactData.necessity === 3) {
                contactData.necessityText = "Apertura de negocio"
            }
            if (contactData.necessity === 4) {
                contactData.necessityText = necessity
            }
            if (contactData.box === 0) {
                contactData.boxText = "1"
            }
            if (contactData.box === 1) {
                contactData.boxText = "2"
            }
            if (contactData.box === 2) {
                contactData.boxText = "Más de 3"
            }
            if (contactData.zone === 0) {
                contactData.zoneText = "1"
            }
            if (contactData.zone === 1) {
                contactData.zoneText = "2"
            }
            if (contactData.zone === 2) {
                contactData.zoneText = "Más de 3"
            }
        }
        if (contactData.withSystem === 1) {
            contactData.withSystemText = "No, de momento"
            if (contactData.bussinessType === 0) {
                contactData.bussinessTypeText = "Cafetería"
            }
            if (contactData.bussinessType === 1) {
                contactData.bussinessTypeText = "Restaurante"
            }
            if (contactData.bussinessType === 2) {
                contactData.bussinessTypeText = "Bar"
            }
            if (contactData.bussinessType === 3) {
                contactData.bussinessTypeText = "Antro"
            }
            if (contactData.bussinessType === 4) {
                contactData.bussinessTypeText = business
            }
            if (contactData.necessity === 0) {
                contactData.necessityText = "Capacitación"
            }
            if (contactData.necessity === 1) {
                contactData.necessityText = "Impresoras"
            }
            if (contactData.necessity === 2) {
                contactData.necessityText = "Soporte general"
            }
            if (contactData.necessity === 3) {
                contactData.necessityText = "Apertura de negocio"
            }
            if (contactData.necessity === 4) {
                contactData.necessityText = necessity
            }
            if (contactData.box === 0) {
                contactData.boxText = "1"
            }
            if (contactData.box === 1) {
                contactData.boxText = "2"
            }
            if (contactData.box === 2) {
                contactData.boxText = "Más de 3"
            }
            if (contactData.zone === 0) {
                contactData.zoneText = "1"
            }
            if (contactData.zone === 1) {
                contactData.zoneText = "2"
            }
            if (contactData.zone === 2) {
                contactData.zoneText = "Más de 3"
            }
        }
        if (contactData.withSystem === 2) {
            contactData.withSystemText = "Quiero un Software"
        }
        console.log(contactData)
        emailjs.send("service_ss9w9ws", "woolfo_customer_data", contactData).then(
            (response) => {
                console.log(response.status, response.text);
                setLoader(false);
                alert("Información Enviada");
            },
            (error) => {
                console.log(error);
                alert("error");
            }
        );
    }
    return (
        <div className="bills-container">
            <div className="logo-contain">
                <img src={WOOLFO_LOGO} className="logo" alt="logo-woolfo" />
                <div className="first-wave" />
            </div>
            <div className="container-2">
                <p className="text">
                    Empresa con más de 30 años de experiencia atendiendo restaurantes, bares y cualquier empresa que necesita software y hardware.
                </p>
            </div>
            <div className="container-3">
                <img src={VIDEO_1} alt="video-1" />
                <div className="second-wave" />
            </div>
            <div className="container-4">
                <p className="title">
                    Nuestros clientes...
                </p>
                <div className="third-wave" />
            </div>
            <div className="client-data">
                <div className="client-contain-1">
                    <div className="logo-image">
                        <img className="image" src={logo_casa} alt='img-2' />
                    </div>
                    <img className="video-gif" src={VIDEO_2} alt='video-2' />
                </div>
                <div className="client-contain-2">
                    <div className="logo-image">
                        <img className="image" src={logo_milanesa} alt='img-3' />
                    </div>
                    <img className="video-gif" src={VIDEO_3} alt='video-3' />
                </div>
                <div className="client-contain-1">
                    <div className="logo-image" style={{ backgroundColor: "#fffc01" }}>
                        <img className="image" src={logo_pollo} alt='img-4' />
                    </div>
                    <img className="video-gif" src={VIDEO_4} alt='video-4' />
                </div>
                <div className="client-contain-2">
                    <div className="logo-image" style={{ backgroundColor: "#f4f4f4", paddingBlock: 30 }}>
                        <img className="image" src={logo_civo} alt='img-5' />
                    </div>
                    <img className="video-gif" src={VIDEO_5} alt='video-5' />
                </div>
            </div>
            <div className="container-5">
                <p className="title">Son oficialmente</p>
                <img className="logo-gray" src={WOOLFO_LOGO_2} alt="gray-logo" />
                <img className="separate-1" src={separate_line} alt="first-separate" />
                <div className="logo-container">
                    {
                        logoPictures.map((val: any, index: number) => {
                            return (
                                <img className="image-logo" src={`../../images/logos_2/logo_${val}.svg`} alt={val} key={"logos_" + index} />
                            )
                        })
                    }
                </div>
                <img className="image-logo" src="../../images/logos_2/logo_19.svg" style={{ paddingBottom: 20 }} alt="final-logo" />
                <img className="separate-2" src={separate_line} alt="final-separate" />
            </div>
            <div className="container-6">
                <img src={X_SEPARATE} className="separate-x" alt="x-separate" />
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            ¿Cuentas con punto<br /> de venta?
                        </p>
                    </div>
                    <div className="button-contain">
                        <button className="btn-1" onClick={() => setContact({ ...contact, withSystem: 0 })} style={contact.withSystem === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>Si</button>
                        <button className="btn-2" onClick={() => setContact({ ...contact, withSystem: 1 })} style={contact.withSystem === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>No, de momento</button>
                        <button className="btn-2" onClick={() => setContact({ ...contact, withSystem: 2 })} style={contact.withSystem === 2 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>Quiero Software</button>
                    </div>
                </div>
                <div className="question">
                    {
                        contact.withSystem === 0 &&
                        <div className="data-contain">
                            <p className="text-titles">
                                ¿Qué sistema utilizas?
                            </p>
                            <div className="input-contain">
                                <div className="input-data" onClick={() => systemChange(0)}>
                                    <div className="input" style={contact.system === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>A</div>
                                    <p className="text-input" style={contact.system === 0 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Soft Restaurant</p>
                                </div>
                                <div className="input-data" onClick={() => systemChange(1)}>
                                    <div className="input" style={contact.system === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>B</div>
                                    <input
                                        style={contact.system === 1 ? { color: "#5BAC43", fontWeight: 600 } : {}}
                                        placeholder='Otro'
                                        onChange={(e: any) => setSystem(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (contact.withSystem === 1 || contact.withSystem === 0) &&
                        <>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Qué tipo de negocio?
                                </p>
                                <div className="input-contain">
                                    <div className="input-data" onClick={() => bussinessTypeChange(0)}>
                                        <div className="input" style={contact.bussinessType === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>A</div>
                                        <p className="text-input" style={contact.bussinessType === 0 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Cafetería</p>
                                    </div>
                                    <div className="input-data" onClick={() => bussinessTypeChange(1)}>
                                        <div className="input" style={contact.bussinessType === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>B</div>
                                        <p className="text-input" style={contact.bussinessType === 1 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Restaurante</p>
                                    </div>
                                    <div className="input-data" onClick={() => bussinessTypeChange(2)}>
                                        <div className="input" style={contact.bussinessType === 2 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>C</div>
                                        <p className="text-input" style={contact.bussinessType === 2 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Bar</p>
                                    </div>
                                    <div className="input-data" onClick={() => bussinessTypeChange(3)}>
                                        <div className="input" style={contact.bussinessType === 3 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>D</div>
                                        <p className="text-input" style={contact.bussinessType === 3 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Antro</p>
                                    </div>
                                    <div className="input-data" onClick={() => bussinessTypeChange(4)}>
                                        <div className="input" style={contact.bussinessType === 4 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>E</div>
                                        <input
                                            style={contact.bussinessType === 4 ? { color: "#5BAC43", fontWeight: 600 } : {}}
                                            placeholder='Otro'
                                            onChange={(e: any) => setBusiness(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Tienes alguna necesidad?
                                </p>
                                <div className="input-contain">
                                    <div className="input-data" onClick={() => neccesityChange(0)}>
                                        <div className="input" style={contact.necessity === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>A</div>
                                        <p className="text-input" style={contact.necessity === 0 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Capacitación</p>
                                    </div>
                                    <div className="input-data" onClick={() => neccesityChange(1)}>
                                        <div className="input" style={contact.necessity === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>B</div>
                                        <p className="text-input" style={contact.necessity === 1 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Impresoras</p>
                                    </div>
                                    <div className="input-data" onClick={() => neccesityChange(2)}>
                                        <div className="input" style={contact.necessity === 2 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>C</div>
                                        <p className="text-input" style={contact.necessity === 2 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Soporte general</p>
                                    </div>
                                    <div className="input-data" onClick={() => neccesityChange(3)}>
                                        <div className="input" style={contact.necessity === 3 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>D</div>
                                        <p className="text-input" style={contact.necessity === 3 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Apertura de negocio</p>
                                    </div>
                                    <div className="input-data" onClick={() => neccesityChange(4)}>
                                        <div className="input" style={contact.necessity === 4 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>E</div>
                                        <input
                                            style={contact.necessity === 4 ? { color: "#5BAC43", fontWeight: 600 } : {}}
                                            placeholder='Otro'
                                            onChange={(e: any) => setNecessity(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Cuántas cajas de cobranza tiene?
                                </p>
                                <div className="input-contain">
                                    <div className="input-data" onClick={() => boxChange(0)}>
                                        <div className="input" style={contact.box === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>A</div>
                                        <p className="text-input" style={contact.box === 0 ? { color: "#5BAC43", fontWeight: 600 } : {}}>1</p>
                                    </div>
                                    <div className="input-data" onClick={() => boxChange(1)}>
                                        <div className="input" style={contact.box === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>B</div>
                                        <p className="text-input" style={contact.box === 1 ? { color: "#5BAC43", fontWeight: 600 } : {}}>2</p>
                                    </div>
                                    <div className="input-data" onClick={() => boxChange(2)}>
                                        <div className="input" style={contact.box === 2 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>C</div>
                                        <p className="text-input" style={contact.box === 2 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Más de 3</p>
                                    </div>
                                </div>
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Cuántas zonas de comida tiene y cuales son?
                                </p>
                                <div className="input-contain">
                                    <div className="input-data" onClick={() => zoneChange(0)}>
                                        <div className="input" style={contact.zone === 0 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>A</div>
                                        <p className="text-input" style={contact.zone === 0 ? { color: "#5BAC43", fontWeight: 600 } : {}}>1</p>
                                    </div>
                                    <div className="input-data" onClick={() => zoneChange(1)}>
                                        <div className="input" style={contact.zone === 1 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>B</div>
                                        <p className="text-input" style={contact.zone === 1 ? { color: "#5BAC43", fontWeight: 600 } : {}}>2</p>
                                    </div>
                                    <div className="input-data" onClick={() => zoneChange(2)}>
                                        <div className="input" style={contact.zone === 2 ? { backgroundColor: "#5BAC43", color: "white" } : {}}>C</div>
                                        <p className="text-input" style={contact.zone === 2 ? { color: "#5BAC43", fontWeight: 600 } : {}}>Más de 3</p>
                                    </div>
                                </div>
                                <p>
                                    Ejemplos son: terraza, comedor, patio, etc..
                                </p>
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Tienen area de oficina?
                                </p>
                                <input
                                    placeholder="Texto"
                                    className="input-text"
                                    onChange={(e: any) => {
                                        setContact({ ...contact, officeText: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Tiene meseros?
                                </p>
                                <input
                                    placeholder="Texto"
                                    className="input-text"
                                    onChange={(e: any) => {
                                        setContact({ ...contact, waiter: e.target.value })
                                    }}
                                />
                            </div>
                        </>
                    }
                    {
                        contact.withSystem === 2 &&
                        <>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Qué tipo de software necesita?
                                </p>
                                <input
                                    placeholder="Texto"
                                    className="input-text"
                                    onChange={(e: any) => {
                                        setContact({ ...contact, software: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="data-contain">
                                <p className="text-titles">
                                    ¿Tiene actualmente algo hecho o es desde cero?
                                </p>
                                <input
                                    placeholder="Texto"
                                    className="input-text"
                                    onChange={(e: any) => {
                                        setContact({ ...contact, about: e.target.value })
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            Nombre
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, name: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            Teléfono
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, phone: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            Correo
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, email: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            Nombre Comercial
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, commercialName: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            No. de sucursales
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, numberFranchise: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="question">
                    <div className="data-contain">
                        <p className="text-titles">
                            Estado de la república principal de operación.
                        </p>
                        <input
                            placeholder="Texto"
                            className="input-text"
                            onChange={(e: any) => {
                                setContact({ ...contact, state: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div className="btn-contain">
                    {
                        loader
                            ? "Cargando..."
                            :
                            <button className="send-btn" onClick={submit}>
                                Enviar
                            </button>

                    }

                </div>
            </div>
        </div>
    );
}
export default BillsScreen;