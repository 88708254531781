import "@theme/styles.scss";
import React, { createRef, useEffect, useRef, useState } from "react";
// import ReactDOM from "react-dom";
import { useOnScreen } from "utils/FunctionsBundle";
// import { parseRem } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// DISPLAYER #############################
interface DisplayerProps {
  responsive?: boolean;
  autoChange?: boolean;
  autoChangeTimer?: number;
  column?: boolean;
  buttonColors?: string[];
}

const DisplayerDefaultProps: DisplayerProps = {
  responsive: true,
  autoChange: false,
  autoChangeTimer: 5000,
  column: false,
  buttonColors: ["#00e6be"],
};

// ############################# FUNCTION COMPONENTS #############################
// DISPLAYER #############################
const Displayer: React.FunctionComponent<DisplayerProps> = (props) => {
  const [activeChannel, setActiveChannel] = useState(0);
  const [timerActive, setTimerActive] = useState(props.autoChange);
  const [timerChange, setTimerChange] = useState(false);

  const num = React.Children.count(props.children);

  const ref = useRef(null);
  const refArr = useRef(Array<any>());
  const visible = useOnScreen(ref);

  // Guardar todas las referencias de los objetos
  if (refArr.current.length !== num) {
    refArr.current = new Array(num).fill([]).map((_, index) => createRef());
  }

  useEffect(() => {
    if (timerChange) {
      setTimerChange(false);

      if (timerActive && visible) {
        setActiveChannel((activeChannel) => {
          if (React.Children.count(props.children) === activeChannel + 1)
            return 0;
          else return activeChannel + 1;
        });
      }
    }
  }, [timerChange, props.children, timerActive, visible]);

  useEffect(() => {
    const int = setInterval(() => setTimerChange(true), props.autoChangeTimer);

    return () => {
      clearInterval(int);
    };
  }, [props.autoChangeTimer]);

  if (props.children === undefined || props.children === null) return <></>;

  // const rem = (val?: string) => {
  //   if (props.responsive && val !== undefined) return parseRem(val);
  //   else return val;
  // };

  const inColumn = props.column ? " in-column " : " ";

  return (
    <div
      className={"displayer" + inColumn}
      ref={ref}
      onMouseEnter={() => setTimerActive(false)}
      onMouseLeave={() => setTimerActive(props.autoChange)}
    >
      <div className={"displayer-controls-container" + inColumn}>
        {React.Children.map(props.children, (child, index) => (
          <button
            className={
              "displayer-controls" +
              inColumn +
              (index === activeChannel ? " selected" : "")
            }
            onClick={() => {
              setActiveChannel(index);
            }}
            style={
              index === activeChannel && props.buttonColors
                ? props.buttonColors[index]
                  ? { backgroundColor: props.buttonColors[index] }
                  : {
                      backgroundColor:
                        props.buttonColors[props.buttonColors.length - 1],
                    }
                : {}
            }
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className={"displayer-screens-container" + inColumn}>
        {React.Children.map(props.children, (child, index) => {
          // var op: any;
          // if (refArr.current[index].current) {
          //   op = window
          //     .getComputedStyle(refArr.current[index].current)
          //     .getPropertyValue("opacity");
          //   // console.log(op);
          //   // console.log(typeof op);
          // }
          return (
            <div
              ref={refArr.current[index]}
              className={
                "displayer-screens" +
                inColumn +
                (index === activeChannel ? " selected" : "")
              }
              style={
                index !== activeChannel &&
                refArr.current[index].current &&
                window
                  .getComputedStyle(refArr.current[index].current)
                  .getPropertyValue("opacity") === "0"
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
            >
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// ############################# EXPORTS #############################
Displayer.defaultProps = DisplayerDefaultProps;

export default Displayer;
