import Breaker from "@components/Breaker";
import Slider from "@components/CustomSlider";
import Footer from "@components/Footer";
import Header from "@components/Header";
import ImageHolder from "@components/ImageHolder";
import ItemsList from "@components/ItemsList";
import Marginalizer from "@components/Marginalizer";
import ReactiveContainer from "@components/ReactiveContainer";
import ScrollButton from "@components/ScrollButton";
import Subtitle from "@components/Subtitle";
import Title from "@components/Title";
import "@theme/styles.scss";
import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigate, useParams } from "react-router";
import { Helmet } from "react-helmet";
import {
  formatText,
  parseRem,
  shuffleArray,
  styleText,
  suspendText,
} from "utils/FunctionsBundle";
import { imgObj } from "utils/ImageRepo";
import { useTranslation } from "react-i18next";
import TranslateButton from "@components/TranslateButton";
// import ReactSlider from "react-slider";

const ProjectScreen = () => {
  const {t} = useTranslation();
  const scrollRef = useRef(null);

  const responsive470 = useMediaQuery({ query: "(max-width: 470px)" });
  const responsive770 = useMediaQuery({ query: "(max-width: 770px)" });
  const responsive820 = useMediaQuery({ query: "(max-width: 820px)" });
  const responsive920 = useMediaQuery({ query: "(max-width: 920px)" });
  const responsive1396 = useMediaQuery({ query: "(max-width: 1396px)" });
  const [sliderIndex, setSliderIndex] = useState(0);

  const projectsInfo = [
    {
      name: "cienpotencia",
      url: "cienpotencia",
      head: t("projectScreen.cienpotencia.sliderHead"),
      body: t("projectScreen.cienpotencia.sliderBody"),
    },
    {
      name: "bullground",
      url: "bullground",
      head: t("projectScreen.bullground.sliderHead"),
      body: t("projectScreen.bullground.sliderBody"),
    },
    {
      name: "medmex",
      url: "medmex",
      head: t("projectScreen.medmex.sliderHead"),
      body: t("projectScreen.medmex.sliderBody"),
    },
    {
      name: "gm_eshop",
      url: "gm-eshop",
      head: t("projectScreen.gmEshop.sliderHead"),
      body: t("projectScreen.gmEshop.sliderBody"),
    },
    {
      name: "cadefi",
      url: "cadefi",
      head: t("projectScreen.cadefi.sliderHead"),
      body: t("projectScreen.cadefi.sliderBody"),
    },
    {
      name: "lg_autopartes",
      url: "lg-autopartes",
      head: t("projectScreen.lgAutopartes.sliderHead"),
      body: t("projectScreen.lgAutopartes.sliderBody"),
    },
    {
      name: "academia_de_lenguas",
      url: "academia-de-lenguas",
      head: t("projectScreen.academiaLenguas.sliderHead"),
      body: t("projectScreen.academiaLenguas.sliderBody"),
    },
    {
      name: "wmobil",
      url: "wmobil",
      head: t("projectScreen.wmobil.sliderHead"),
      body: t("projectScreen.wmobil.sliderBody"),
    },
  ];

  const sliderProjectsMap = {
    cienpotencia: {
      title: t("projectScreen.cienpotencia.titleMain"),
      subtitle: t("projectScreen.cienpotencia.titleBody"),
      headline: t("projectScreen.cienpotencia.headline"),
      subtext: t("projectScreen.cienpotencia.subtext"),
      slider_title:t("projectScreen.cienpotencia.bottomSlider"),
      seo_title:"Cienpotencia | INOWU Development",
      seo_description:"Cienpotencia is an e-learning platform with classes on anything you can imagine. Thanks to INOWU, their website looks great, and is easy for users to navigate!",
      functionalities: [
        {
          name: t("projectScreen.cienpotencia.name1"),
          description: t("projectScreen.cienpotencia.description1"),
        },
        {
          name: t("projectScreen.cienpotencia.name2"),
          description: t("projectScreen.cienpotencia.description2"),
        },
        {
          name: t("projectScreen.cienpotencia.name3"),
          description: t("projectScreen.cienpotencia.description3"),
        },
      ],
      dominantColor: "#ED1C24",
    },
    bullground: {
      title: t("projectScreen.bullground.titleMain"),
      subtitle: t("projectScreen.bullground.titleBody"),
      headline: t("projectScreen.bullground.headline"),
      subtext: t("projectScreen.bullground.subtext"),
      slider_title:t("projectScreen.bullground.bottomSlider"),
      seo_title:"Bullground | INOWU Development",
      seo_description:"Bullground is Latin America's top financial e-learning platform. Learn about how INOWU gave them a beautiful website with great user functionality.",
      functionalities: [
        {
          name: t("projectScreen.bullground.name1"),
          description: t("projectScreen.bullground.description1"),
        },
        {
          name: t("projectScreen.bullground.name2"),
          description: t("projectScreen.bullground.description2"),
        },
        {
          name: t("projectScreen.bullground.name3"),
          description: t("projectScreen.bullground.description3"),
        },
        {
          name: t("projectScreen.bullground.name4"),
          description: t("projectScreen.bullground.description4"),
        },
        {
          name: t("projectScreen.bullground.name5"),
          description: t("projectScreen.bullground.description5"),
        },
        {
          name: t("projectScreen.bullground.name6"),
          description: t("projectScreen.bullground.description6"),
        },
        {
          name: t("projectScreen.bullground.name7"),
          description: t("projectScreen.bullground.description7"),
        },
        {
          name: t("projectScreen.bullground.name8"),
          description: t("projectScreen.bullground.description8"),
        },
        {
          name: t("projectScreen.bullground.name9"),
          description: t("projectScreen.bullground.description9"),
        },
        {
          name: t("projectScreen.bullground.name0"),
          description: t("projectScreen.bullground.description0"),
        },
      ],
      dominantColor: "#8C82FF",
    },
    medmex: {
      title: t("projectScreen.medmex.titleMain"),
      subtitle: t("projectScreen.medmex.titleBody"),
      headline: t("projectScreen.medmex.headline"),
      subtext: t("projectScreen.medmex.subtext"),
      slider_title:t("projectScreen.medmex.bottomSlider"),
      seo_title:"Medmex | INOWU Development",
      seo_description:"Medmex allows individuals to keep a clear record of their medical history. INOWU helped, adding features that made it even easier to keep track of their health.",
      functionalities: [
        {
          name: t("projectScreen.medmex.name1"),
          description: t("projectScreen.medmex.description1"),
        },
        {
          name: t("projectScreen.medmex.name2"),
          description: t("projectScreen.medmex.description2"),
        },
        {
          name: t("projectScreen.medmex.name3"),
          description: t("projectScreen.medmex.description3"),
        },
        {
          name: t("projectScreen.medmex.name4"),
          description: t("projectScreen.medmex.description4"),
        },
      ],
      dominantColor: "#009ED2",
    },
    gm_eshop: {
      title: t("projectScreen.gmEshop.titleMain"),
      subtitle: t("projectScreen.gmEshop.titleBody"),
      headline: t("projectScreen.gmEshop.headline"),
      subtext: t("projectScreen.gmEshop.subtext"),
      slider_title:t("projectScreen.gmEshop.bottomSlider"),
      seo_title:"GM E-Shop | INOWU Development",
      seo_description:"GM E-shop is an e-commerce website selling mainly technology and electronics. For this client, INOWU created a user-friendly website that drives sales.",
      functionalities: [
        {
          name: t("projectScreen.gmEshop.name1"),
          description: t("projectScreen.gmEshop.description1"),
        },
        {
          name: t("projectScreen.gmEshop.name2"),
          description: t("projectScreen.gmEshop.description2"),
        },
        {
          name: t("projectScreen.gmEshop.name3"),
          description: t("projectScreen.gmEshop.description3"),
        },
        {
          name: t("projectScreen.gmEshop.name4"),
          description: t("projectScreen.gmEshop.description4"),
        },
      ],
      dominantColor: "#00E6BE",
    },
    cadefi: {
      title: t("projectScreen.cadefi.titleMain"),
      subtitle: t("projectScreen.cadefi.titleBody"),
      headline: t("projectScreen.cadefi.headline"),
      subtext: t("projectScreen.cadefi.subtext"),
      slider_title:t("projectScreen.cadefi.bottomSlider"),
      seo_title:"CADEFI | INOWU Development",
      seo_description:"CADEFI provides accounting e-courses and certifications. INOWU added functionalities that allow users to navigate the website and get the education they need.",
      functionalities: [
        {
          name: t("projectScreen.cadefi.name1"),
          description: t("projectScreen.cadefi.description1"),
        },
        {
          name: t("projectScreen.cadefi.name2"),
          description: t("projectScreen.cadefi.description2"),
        },
        {
          name: t("projectScreen.cadefi.name3"),
          description: t("projectScreen.cadefi.description3"),
        },
        {
          name: t("projectScreen.cadefi.name4"),
          description: t("projectScreen.cadefi.description4"),
        },
      ],
      dominantColor: "#1C65AE",
    },
    lg_autopartes: {
      title: t("projectScreen.lgAutopartes.titleMain"),
      subtitle: t("projectScreen.lgAutopartes.titleBody"),
      headline: t("projectScreen.lgAutopartes.headline"),
      subtext: t("projectScreen.lgAutopartes.subtext"),
      slider_title:t("projectScreen.lgAutopartes.bottomSlider"),
      seo_description:"LG Autopartes is a search platform for auto parts – for them, INOWU improved the search function of their website by adding dynamic search, and more.",
      functionalities: [
        {
          name: t("projectScreen.lgAutopartes.name1"),
          description: t("projectScreen.lgAutopartes.description1"),
        },
        {
          name: t("projectScreen.lgAutopartes.name2"),
          description: t("projectScreen.lgAutopartes.description2"),
        },
        {
          name: t("projectScreen.lgAutopartes.name3"),
          description: t("projectScreen.lgAutopartes.description3"),
        },
        {
          name: t("projectScreen.lgAutopartes.name4"),
          description: t("projectScreen.lgAutopartes.description4"),
        },
        {
          name: t("projectScreen.lgAutopartes.name5"),
          description: t("projectScreen.lgAutopartes.description5"),
        },
      ],
      dominantColor: "#1e385e",
    },
    academia_de_lenguas: {
      title: t("projectScreen.academiaLenguas.titleMain"),
      subtitle: t("projectScreen.academiaLenguas.titleBody"),
      headline: t("projectScreen.academiaLenguas.headline"),
      subtext: t("projectScreen.academiaLenguas.subtext"),
      slider_title:t("projectScreen.academiaLenguas.bottomSlider"),
      seo_description:"For Academia de Lenguas, INOWU made their website interactive and easy to use with post and comment functionality. We also added an on-site payment platform.",
      functionalities: [
        {
          name: t("projectScreen.academiaLenguas.name1"),
          description: t("projectScreen.academiaLenguas.description1"),
        },
        {
          name: t("projectScreen.academiaLenguas.name2"),
          description: t("projectScreen.academiaLenguas.description2"),
        },
        {
          name: t("projectScreen.academiaLenguas.name3"),
          description: t("projectScreen.academiaLenguas.description3"),
        },
        {
          name: t("projectScreen.academiaLenguas.name4"),
          description: t("projectScreen.academiaLenguas.description4"),
        },
      ],
      dominantColor: "#ee1c34",
    },
    wmobil: {
      title: t("projectScreen.wmobil.titleMain"),
      subtitle: t("projectScreen.wmobil.titleBody"),
      headline: t("projectScreen.wmobil.headline"),
      subtext: t("projectScreen.wmobil.subtext"),
      slider_title:t("projectScreen.wmobil.bottomSlider"),
      seo_title:"WMobil | INOWU Development",
      seo_description:"See how INOWU created a user-friendly app for WMobil, a platform that allows restaurant owners to manage their businesses in the palm of their hand.",
      functionalities: [
        {
          name: t("projectScreen.wmobil.name1"),
          description: t("projectScreen.wmobil.description1"),
        },
        {
          name: t("projectScreen.wmobil.name2"),
          description: t("projectScreen.wmobil.description2"),
        },
        {
          name: t("projectScreen.wmobil.name3"),
          description: t("projectScreen.wmobil.description3"),
        },
        {
          name: t("projectScreen.wmobil.name4"),
          description: t("projectScreen.wmobil.description4"),
        },
        {
          name: t("projectScreen.wmobil.name5"),
          description: t("projectScreen.wmobil.description5"),
        },
        {
          name: t("projectScreen.wmobil.name6"),
          description: t("projectScreen.wmobil.description6"),
        },
        {
          name: t("projectScreen.wmobil.name7"),
          description: t("projectScreen.wmobil.description7"),
        },
      ],
      dominantColor: "#30AC2C",
    },
  };

  var { project } = useParams();
  project = project?.replace(/-/gim, "_");

  const projects = [
    "cienpotencia",
    "bullground",
    "medmex",
    "gm_eshop",
    "cadefi",
    "lg_autopartes",
    "academia_de_lenguas",
    "wmobil",
  ];

  const themeColor =
    project === undefined
      ? "#00e6be"
      : sliderProjectsMap[project].dominantColor;

  return (
    <div>
      {project === undefined || !projects.includes(project) ? (
        <Navigate to={"/"} />
      ) : (
        <>
          <Header />
      <Helmet>
        <title>{sliderProjectsMap[project].seo_title}</title>
        <meta name="description" content={sliderProjectsMap[project].seo_description}></meta>
      </Helmet>

          <ImageHolder
            imageSrc={imgObj.bg.projects_blur[project]}
            imageSize={
              responsive470
                ? ["auto", parseRem("720px")]
                : [
                    "clamp(1512px, 100vw, 100vw)",
                    `clamp(${parseRem("720px")}, 100%, 100%)`,
                  ]
            }
            imageLayer={"bottom"}
            canvasPosition={"bottom"}
            imagePosition={8}
          />

          <Breaker size={parseRem("64px")} />

          <ImageHolder
            imageSrc={imgObj.logo[`${project}_white`]}
            imageSize={["auto", "auto"]}
            imageLayer={"bottom"}
            canvasPosition={"bottom-in"}
            style={{ height: parseRem("221px") }}
            canvasHeight={"100%"}
            // canvasStyle={{ top: parseRem("-80px") }}
            imagePosition={5}
          />

          <Title
            head={sliderProjectsMap[project].title}
            styleTextOptions={{
              emphasisColor: themeColor,
            }}
            headColor="white"
            style={{
              maxWidth: "900px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Breaker size={parseRem("40px")} />

            <TranslateButton />

            <ScrollButton
              toElement={scrollRef}
              backgroundColor={themeColor}
              textColor={"white"}
              imageSytle={{
                filter:
                  "invert(0%) sepia(90%) saturate(2000%) hue-rotate(186deg) brightness(200%) contrast(105%)",
              }}
            />
          </Title>

          <Breaker size={parseRem("102px")} />

          <ImageHolder
            imageSrc={imgObj.bg.project_circles}
            imageSize={[parseRem("936px"), parseRem("447px")]}
            imageLayer={"bottom"}
            canvasPosition={"top"}
            imagePosition={2}
          />

          <Marginalizer size="0px" style={{ backgroundColor: "white" }}>
            <Breaker size={parseRem("80px")} />
            <Subtitle
              head={""} 
              body={sliderProjectsMap[project].subtitle}
              style={{
                maxWidth: "900px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              styleTextOptions={{
                emphasisColor: themeColor,
              }}
            />

            <div className="reference-object" ref={scrollRef}></div>

            <Breaker size={parseRem("80px")} />

            <Breaker
              size={
                responsive820
                  ? responsive470
                    ? parseRem("420px")
                    : parseRem("500px") // 638
                  : parseRem("660px")
              }
            />

            <ReactiveContainer index={sliderIndex}>
              {sliderProjectsMap[project].functionalities.map((func, index) => {
                return (
                  <ImageHolder
                    imageSrc={imgObj.item.functionality[project!][index]}
                    imageSize={
                      responsive820
                        ? responsive470
                          ? ["auto", parseRem("400px")]
                          : ["auto", parseRem("480px")] // 638
                        : ["auto", "auto"]
                    }
                    imageLayer={"top"}
                    imageStyle={{
                      bottom: "1.5rem",
                      //alignSelf:"center",
                    }}
                    canvasPosition={"bottom-in"}
                    canvasWidth={"100vw"}
                    imagePosition={8}
                    key={index}
                  />
                );
              })}
            </ReactiveContainer>

            <Marginalizer
              size={
                responsive470
                  ? "1rem"
                  : responsive770
                  ? parseRem("50px")
                  : parseRem("112px")
              }
            >
              <Slider
                autoChange={true}
                focusAlign={"left"}
                getIndex={setSliderIndex}
                themeColor={themeColor}
                goOnWithBand={!responsive820}
              >
                {sliderProjectsMap[project].functionalities.map(
                  (proj, index) => {
                    return (
                      <div
                        style={{
                          whiteSpace: "pre-line",
                          textAlign: "left",
                          maxWidth: "480px",
                          // marginLeft: "auto",
                          // marginRight: "auto",
                        }}
                        key={index}
                      >
                        <p
                          style={{
                            marginBottom: parseRem("10px"),
                            fontSize: parseRem("28px"),
                          }}
                        >
                          {styleText({
                            text: proj.name,
                            emphasisColor: themeColor,
                          })}
                        </p>
                        <p style={{ fontSize: parseRem("24px") }}>
                          {styleText({
                            text: proj.description,
                          })}
                        </p>
                      </div>
                    );
                  }
                )}
              </Slider>
            </Marginalizer>

            <Breaker size={parseRem("103px")} />
            <Marginalizer
              size={
                responsive470
                  ? "1rem"
                  : responsive770
                  ? parseRem("50px")
                  : parseRem("112px")
              }
            >
              <div
                style={{
                  background:
                    "linear-gradient(94.92deg, #003A54 13.01%, #052939 94.33%)",
                  borderRadius: parseRem("16px"),
                  whiteSpace: "pre-line",
                  height: parseRem("400px"),
                  display: "flow-root",
                }}
              >
                {responsive920 ? (
                  <></>
                ) : (
                  <ImageHolder
                    imageSrc={imgObj.item.band_image}
                    imageLayer={"top"}
                    imageSize={[parseRem("668px"), parseRem("398px")]}
                    canvasPosition={"top-in"}
                    canvasStyle={{
                      top: parseRem("-25px"),
                      borderRadius: parseRem("16px"),
                    }}
                    imageStyle={responsive1396 ? { opacity: "0.6" } : undefined}
                    imagePosition={3}
                  />
                )}
                <div
                  style={{
                    marginTop: parseRem("20px"),
                    marginLeft: parseRem("50px"),
                    zIndex: "6",
                    position: "relative",
                  }}
                >
                  <h2
                    style={{ color: "white", marginBottom: parseRem("12px") }}
                  >
                    {styleText({ text: sliderProjectsMap[project].headline })}
                  </h2>

                  <p style={{ color: "white", marginBottom: parseRem("12px") }}>
                    {styleText({
                      text: sliderProjectsMap[project].subtext,
                      format: true,
                    })}
                  </p>

                  <a
                    className="custom-link pp increase as-p"
                    href="/contact"
                    style={{
                      paddingLeft: "0px",
                      borderBottomColor: themeColor,
                    }}
                  >
                    {styleText({
                      text: t("projectScreen.projectLink"),
                      emphasisColor: "white",
                    })}
                  </a>
                </div>
              </div>
            </Marginalizer>

            <Breaker size={parseRem("88px")} />

            <Subtitle
              head={sliderProjectsMap[project].slider_title}
              styleTextOptions={{ emphasisColor: themeColor }}
            />

            <Breaker size={parseRem("48px")} />

            <Marginalizer size={"5vw" /*parseRem("112px")*/} responsive={false}>
              <ItemsList
                slider
                sliderSettings={{
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerMode: false,
                  responsive: [
                    {
                      breakpoint: 1064,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 770,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                      },
                    },
                    {
                      breakpoint: 420,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        canterMode: true,
                        infinite: true,
                        autoplay: true,
                      },
                    },
                  ],
                }}
              >
                {shuffleArray(projectsInfo)
                  .filter((proj) => {
                    return proj.name !== project;
                  })
                  .map((proj, index) => {
                    const remm = parseRem("20px");

                    return (
                      <ItemsList.Item margin={false} key={index}>
                        <div
                          style={{
                            width: parseRem("511px"),
                            //display: "flex",
                            flexDirection: "column",
                            marginBottom: parseRem("51px"),
                            marginLeft:
                              index !== 0 && !responsive470 ? remm : undefined,
                            marginRight:
                              index !== 7 && !responsive470 ? remm : undefined,
                          }}
                        >
                          <a href={`/portfolio/${proj.url}`}>
                            <img
                              src={imgObj.bg.projects[proj.name]}
                              alt="project"
                              style={{
                                width: "100%",
                                height: parseRem("270px"),
                                // height: "auto",
                                borderRadius: "10px",
                                marginTop:"0",
                                border: "1px solid #EDEDED",
                              }}
                            />
                          </a>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: parseRem("24px"),
                              marginRight: parseRem("24px"),
                            }}
                          >
                            <span
                              className="as-p"
                              style={{
                                marginTop: parseRem("24px"),
                                marginBottom: parseRem("10px", "4px"),
                                color: "initial",
                              }}
                            >
                              {styleText({
                                text: proj.head,
                                linkList: [`/portfolio/${proj.url}`],
                                linkStyles: [""],
                              })}
                            </span>

                            <span
                              className="as-p"
                              style={{
                                marginBottom: parseRem("15px", "7px"),
                              }}
                            >
                              {suspendText(
                                formatText({ text: proj.body }),
                                111
                              )}
                            </span>

                            <a
                              className={"custom-link pp grow right"}
                              href={`/portfolio/${proj.url}`}
                              style={{
                                fontWeight: 700,
                                fontSize: parseRem("24px"),
                                alignSelf: "flex-start",
                                color: themeColor,
                                borderBottomColor: themeColor,
                              }}
                            >
                              {t("servicesScreen.servicesLink")}
                            </a>
                          </div>
                        </div>
                      </ItemsList.Item>
                    );
                  })}
              </ItemsList>
            </Marginalizer>
          </Marginalizer>

          <Breaker
            size={parseRem("93px")}
            style={{ backgroundColor: "white" }}
          />
        </>
      )}
      <Footer />
    </div>
  );
};

export default ProjectScreen;
