import Breaker from "@components/Breaker";
import { parseRem, styleText } from "utils/FunctionsBundle";
import Header from "../components/Header";
import { imgObj } from "utils/ImageRepo";
import Subtitle from "@components/Subtitle";
import Title from "@components/Title";
import Marginalizer from "@components/Marginalizer";
import Band from "@components/Band";
import Displayer from "@components/Displayer";
import ScrollButton from "@components/ScrollButton";
import { useRef } from "react";
import ItemsList from "@components/ItemsList";
import Footer from "@components/Footer";
import { Helmet } from "react-helmet";
import ImageHolder from "@components/ImageHolder";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import "@theme/styles.scss";
import TranslateButton from "@components/TranslateButton";

const HomeScreen = () => {
  const { t } = useTranslation();
  const reference = useRef(null);
  const responsive1396 = useMediaQuery({ query: "(max-width: 1396px)" });
  const responsive920 = useMediaQuery({ query: "(max-width: 920px)" });
  const responsive620 = useMediaQuery({ query: "(max-width: 620px)" });
  const responsive470 = useMediaQuery({ query: "(max-width: 470px)" });
  const responsive770 = useMediaQuery({ query: "(max-width: 770px)" });

  // const tagManagerArgs = {
  //   gtm1: `<!-- Google Tag Manager -->
  //   <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-N59W3GC');</script>
  //   <!-- End Google Tag Manager -->`,

  //   gtm2: `<!-- Google Tag Manager (noscript) -->
  //   <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N59W3GC"
  //   height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  //   <!-- End Google Tag Manager (noscript) -->`
  // }

  // TagManager.initialize(tagManagerArgs)

  const recommendationsInfo = [
    {
      head: `cienpotencia`,
      body: t("homeScreen.cienpotencia.body"),
      footer: t("homeScreen.cienpotencia.footer"),
    },
    // {
    //   head: `bullground`,
    //   body: t("homeScreen.bullground.body"),
    //   footer: t("homeScreen.bullground.footer"),
    // },
    {
      head: `myinfo`,
      body: t("homeScreen.myInfo.body"),
      footer: t("homeScreen.myInfo.footer"),
    },
    {
      head: `gm_eshop`,
      body: t("homeScreen.gmEshop.body"),
      footer: t("homeScreen.gmEshop.footer"),
    },
    {
      head: `cadefi`,
      body: t("homeScreen.cadefi.body"),
      footer: t("homeScreen.cadefi.footer"),
    },
    {
      head: `lg_autopartes`,
      body: t("homeScreen.lgAutopartes.body"),
      footer: t("homeScreen.lgAutopartes.footer"),
    },
    {
      head: `direxio`,
      body: t("homeScreen.direxio.body"),
      footer: t("homeScreen.direxio.footer"),
    },
    {
      head: `sunum`,
      body: t("homeScreen.sunum.body"),
      footer: t("homeScreen.sunum.footer"),
    },
  ];

  const projects = [
    "cienpotencia",
    "medmex",
    "gm_eshop",
    "cadefi",
    // "lg_autopartes",
    // "academia_de_lenguas",
    "wmobil",
  ];

  const displayerInfo = {
    cienpotencia: {
      header: t("homeScreen.cienpotencia.header"),
      body: t("homeScreen.cienpotencia.displayerBody"),
      bandColor: `linear-gradient(92.25deg,  #B93C53 0%, #ED1C24 100%)`,
      buttonText: t("homeScreen.cienpotencia.btnText"),
      buttonUrl: "academia_de_lenguas",
      imagePosition: { right: parseRem("-0px") },
      dominantColor: "#ED1C24",
    },
    medmex: {
      header: t("homeScreen.medmex.header"),
      body: t("homeScreen.medmex.displayerBody"),
      bandColor: `#009ED2`,
      buttonText: t("homeScreen.medmex.btnText"),
      buttonUrl: "medmex",
      imagePosition: { right: parseRem("64px") },
      dominantColor: "#009ED2",
    },
    gm_eshop: {
      header: t("homeScreen.gmEshop.header"),
      body: t("homeScreen.gmEshop.displayerBody"),
      bandColor: `#222E42`,
      buttonText: t("homeScreen.gmEshop.btnText"),
      buttonUrl: "gm-eshop",
      imagePosition: { right: parseRem("-0px") },
      dominantColor: "#00E6BE",
    },
    cadefi: {
      header: t("homeScreen.cadefi.header"),
      body: t("homeScreen.cadefi.displayerBody"),
      bandColor: `linear-gradient(92.25deg, #1C65AE 0%, #002F3E 100%)`,
      buttonText: t("homeScreen.cadefi.btnText"),
      buttonUrl: "cadefi",
      imagePosition: { right: parseRem("-0px") },
      dominantColor: "#1C65AE",
    },
    wmobil: {
      header: t("homeScreen.wmobil.header"),
      body: t("homeScreen.wmobil.displayerBody"),
      bandColor: `#30AC2C`,
      buttonText: t("homeScreen.wmobil.btnText"),
      buttonUrl: "wmobil",
      imagePosition: { right: parseRem("64px") },
      dominantColor: "#30AC2C",
    },
    lg_autopartes: {
      header: t("homeScreen.lgAutopartes.header"),
      body: t("homeScreen.lgAutopartes.displayerBody"),
      dominantColor: "#1e385e",
    },
    academia_de_lenguas: {
      header: t("homeScreen.academiaLenguas.header"),
      body: t("homeScreen.academiaLenguas.displayerBody"),
      dominantColor: "#ee1c34",
    },
  };

  return (
    <div>
      <Helmet>
        <title>INOWU Development | Custom Website and App Development</title>
        <meta name="description" content="User-friendly websites and beautifully-designed apps with high 
        functionality from INOWU's expert team. We work with you to give you 
        exactly what you need."></meta>
      </Helmet>

      <Header />

      <ImageHolder
        imageLayer={"bottom"}
        style={{ zIndex: "-10" }}
        // imageSize={["1512px", "980px"]}
        imageStyle={{ height: "980px" }}
        imagePosition={5}
        canvasPosition={"bottom"}
        gradient
      />

      <Breaker
        size={parseRem("234px")}
        style={responsive1396 ? { zIndex: "-4" } : {}}
      >
        <ImageHolder
          imageSrc={imgObj.bg.about_float}
          imageLayer={responsive1396 ? "bottom" : "top"}
          imageSize={[parseRem(1018), parseRem(1183)]}
          imagePosition={6}
          canvasPosition={"bottom"}
          style={responsive1396 ? { zIndex: "-4" } : {}}
          imageStyle={
            responsive920
              ? { visibility: "hidden" }
              : responsive1396
                ? { opacity: "0.5" }
                : undefined
          }
        />
      </Breaker>

      <TranslateButton />

      <div className="z-index-auto" style={{ zIndex: "6" }}>
        <Marginalizer size={parseRem("89px")}>
          <Title
            align={responsive620 ? "center" : "left"}
            main={t("homeScreen.titleMain")}
            mainColor={"white"}
            styleTextOptions={{ emphasisColor: "rgba(255, 255, 255, 0.8)" }}
          >
            <Title
              align={responsive620 ? "center" : "left"}
              main={t("homeScreen.title")}
              mainColor={"white"}
              body={t("homeScreen.titleBody")}
              bodyColor={"white"}
              styleTextOptions={{ emphasisColor: "rgba(255, 255, 255, 0.8)" }}>
            </Title>

            <ScrollButton toElement={reference} />
          </Title>
        </Marginalizer>
      </div>

      <Breaker
        size={parseRem("272px")}
        background={imgObj.bg_trans.white_in}
        backgroundPosition="bottom-in"
        backgroundStyle={{
          zIndex: "-2",
        }}
      />

      <Marginalizer size="0px" style={{ backgroundColor: "white" }}>
        <Breaker
          size={responsive770 ? parseRem("144px") : parseRem("336px")}
          style={{ backgroundColor: "white", zIndex: "-2" }}
        />

        <ImageHolder
          imageSrc={imgObj.item.circles}
          imageSize={parseRem(108)}
          imageLayer={"top"}
          imageStyle={{ left: parseRem("105px") }}
          canvasPosition={{
            bottom: responsive770 ? parseRem("32px") : parseRem("96px"),
          }}
          imagePosition={4}
        />

        <ImageHolder
          imageSrc={imgObj.item.circles}
          imageSize={parseRem(176)}
          imageLayer={"top"}
          imageStyle={{ right: parseRem("-58px") }}
          canvasPosition="bottom"
          imagePosition={6}
        />

        <div ref={reference} style={{ margin: "0px 4rem" }}>
          <Subtitle
            head={t("homeScreen.subtitle")}
            body={t("homeScreen.subtitleBody")}
            buttonText={t("homeScreen.subtitleBtn")}
            buttonURL="/contact"
          />
        </div>
        <Breaker
          size={parseRem("87px")}
          background={imgObj.bg_trans.white_out}
          backgroundPosition="bottom"
        />
      </Marginalizer>

      <Breaker size={parseRem("87px")}>
        <ImageHolder
          imageSrc={imgObj.item.circles}
          imageSize={parseRem(343)}
          imageLayer={"top"}
          imageStyle={{ left: parseRem("-72px") }}
          canvasPosition={{ top: parseRem("-152px") }}
          imagePosition={4}
        />
      </Breaker>
      <Subtitle
        head={t("homeScreen.prevWork")}
      />
      <Breaker size={parseRem("87px")}></Breaker>
      <Displayer
        autoChange={false}
        column={responsive920}
        buttonColors={projects.map((item) => displayerInfo[item].dominantColor)}
      >

        {projects.map((project, index) => {
          return (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={index}
            >
              {responsive920 ? (
                <></>
              ) : (
                <ImageHolder
                  imageSrc={imgObj.item.displays[project]}
                  imageAlt={"macbook"}
                  imageSize={["auto", parseRem("530px")]} // parseRem("840px")
                  imageLayer={"top"}
                  imageStyle={{
                    opacity: responsive1396 ? "0.5" : undefined,
                    ...displayerInfo[project].imagePosition,
                  }}
                  canvasWidth={responsive620 ? "100%" : "100vw"}
                  canvasStyle={{ right: "0%", top: parseRem("0px") }}
                  imagePosition={responsive620 ? 7 : 9}
                />
              )}

              <Subtitle
                head={displayerInfo[project].header}
                align={responsive920 ? "center" : "left"}
                body={displayerInfo[project].body}
                buttonText={displayerInfo[project].buttonText}
                buttonURL={`/portfolio/${displayerInfo[project].buttonUrl}`}
                styleTextOptions={{
                  emphasisColor: displayerInfo[project].dominantColor,
                }}
                shrink
                buttonTransition={
                  responsive920 ? "shrink both" : "shrink right"
                }
                buttonStyle={{
                  borderBottomColor: displayerInfo[project].dominantColor,
                }}
                style={{
                  zIndex: 6,
                  paddingBottom: "1rem",
                  margin: responsive920 ? "2.5rem 1rem" : "",
                  width: !responsive920 ? parseRem("672px") : undefined,
                  minHeight: !responsive920 ? parseRem("400px") : undefined,
                }}
              />

              {!responsive920 ? (
                <></>
              ) : (
                <>
                  <Breaker size={parseRem("32px")} />
                  <ImageHolder
                    imageSrc={imgObj.item.displays[project]}
                    imageAlt={"macbook"}
                    imageSize={
                      responsive470
                        ? ["100vw", "auto"]
                        : ["auto", parseRem("530px")] // parseRem("840px")
                    }
                    imageLayer={"top"}
                    imageStyle={{ ...displayerInfo[project].imagePosition }}
                    canvasWidth={"100vw"}
                    canvasStyle={{
                      position: "relative",
                      top: parseRem("-48px"),
                    }}
                    imagePosition={9}
                  />
                </>
              )}

              <ImageHolder
                imageLayer="top"
                canvasPosition={{
                  right: "0%",
                  top: responsive920 ? "" : parseRem("24.5px"),
                  bottom: !responsive920 ? "" : "0px",
                }}
                canvasWidth={"100vw"}
                style={{ marginTop: "auto", zIndex: 4 }}
              >
                <Band
                  background={displayerInfo[project].bandColor}
                  style={{ zIndex: 3 }}
                >
                  <Breaker size="15.625rem" />
                </Band>
              </ImageHolder>
            </div>
          );
        })}
      </Displayer>

      <Breaker size={responsive920 ? "0px" : "15.625rem"} />

      <Breaker size={parseRem("126px")} />

      <Marginalizer
        size={
          responsive470
            ? "1rem"
            : responsive770
              ? parseRem("50px")
              : parseRem("112px")
        }
      >
        <div
          style={{
            background:
              "linear-gradient(94.92deg, #003A54 13.01%, #052939 94.33%)",
            borderRadius: parseRem("16px"),
            whiteSpace: "pre-line",
            height: parseRem("400px"),
            display: "flow-root",
          }}
        >
          {responsive920 ? (
            <></>
          ) : (
            <ImageHolder
              imageSrc={imgObj.item.band_image}
              imageLayer={"top"}
              imageSize={[parseRem("668px"), parseRem("398px")]}
              canvasPosition={"top-in"}
              canvasStyle={{
                top: parseRem("-25px"),
                borderRadius: parseRem("16px"),
              }}
              imageStyle={responsive1396 ? { opacity: "0.6" } : undefined}
              imagePosition={3}
            />
          )}
          <div
            style={{
              marginTop: parseRem("20px"),
              marginLeft: parseRem("50px"),
              zIndex: "6",
              position: "relative",
            }}
            className={"z-index-auto no-background"}
          >
            <h2 style={{ color: "white", marginBottom: parseRem("12px") }}>
              {styleText({ text: t("homeScreen.inowu") })}
            </h2>

            <p style={{ color: "white", marginBottom: parseRem("22px") }}>
              {styleText({
                text: t("homeScreen.inowuText"),
                format: true,
              })}
            </p>

            <a
              className="custom-link pp increase as-p"
              href="/contact"
              style={{ paddingLeft: "0px" }}
            >
              {styleText({
                text: t("homeScreen.getEstimate"),
                emphasisColor: "white",
              })}
            </a>
          </div>
        </div>
      </Marginalizer>

      <Breaker size={parseRem("140px")} />

      <Subtitle
        head={t("homeScreen.testimonialsHead")}
        body={t("homeScreen.testimonialsBody")}
      />

      <Breaker size={parseRem("72px")} />

      <ItemsList
        slider
        sliderSettings={{
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 1064,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 420,
              settings: {
                autoplay: true,
              },
            },
          ],
        }}
      >
        {recommendationsInfo.map((info, index) => {
          return (
            <ItemsList.Item
              key={index}
              style={{ height: "100%", alignSelf: "flex-start" }}
            >
              <div
                style={{
                  width: parseRem("463px"),
                  height: "10%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src={imgObj.logo[info.head]}
                  alt={info.head}
                  style={{
                    height: parseRem("70px"),
                    width: "auto",
                    //width: parseRem("250px"),
                  }}
                />

                <p
                  style={{
                    marginTop: parseRem("16px"),
                    marginBottom: parseRem("24px"),
                  }}
                >
                  {styleText({ text: info.body })}
                </p>

                <div
                  style={{
                    marginLeft: parseRem("19px"),
                    whiteSpace: "pre-line",
                  }}
                >
                  <p style={{ fontSize: parseRem("20px") }}>
                    {styleText({ text: info.footer })}
                  </p>
                </div>
              </div>
            </ItemsList.Item>
          );
        })}
      </ItemsList>

      <Breaker size={parseRem("274px")} />

      <ImageHolder
        imageSrc={imgObj.item.circles}
        imageSize={parseRem(343)}
        imageLayer={"bottom"}
        imageStyle={{ right: parseRem("-112px"), bottom: parseRem("-153px") }}
        canvasPosition="top"
        imagePosition={6}
      />

      <Band
        shadow
        background={"white"}
        style={{
          display: "flex",
          flexDirection: responsive1396 ? "column" : "row",
          position: "relative",
          paddingTop: parseRem("67px"),
          paddingBottom: parseRem("67px"),
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: responsive1396 ? parseRem("145px") : "0px",
            zIndex: "0",
          }}
        >
          <ImageHolder
            imageSrc={imgObj.item.imgcollection_03}
            imageSize={[parseRem("479px"), parseRem("376px")]}
            imagePosition={responsive1396 ? 5 : 7}
            imageStyle={responsive1396 ? undefined : { left: parseRem("77px") }}
            canvasPosition={responsive1396 ? { top: parseRem("-30px") } : { top: parseRem("100px") }}
            imageLayer={"top"}
          />
          <ImageHolder
            imageSrc={imgObj.item.imgcollection_02}
            imageSize={[parseRem("519px"), parseRem("393px")]}
            imagePosition={responsive1396 ? 5 : 7}
            imageStyle={
              responsive1396 ? undefined : { left: parseRem("117px") }
            }
            canvasPosition={responsive1396 ? { top: parseRem("10px") } : { top: parseRem("140px") }}
            imageLayer={"top"}
          />
          <ImageHolder
            imageSrc={imgObj.item.imgcollection_01}
            imageSize={[parseRem("548px"), parseRem("428px")]}
            imagePosition={responsive1396 ? 5 : 7}
            imageStyle={
              responsive1396 ? undefined : { left: parseRem("157px") }
            }
            canvasPosition={responsive1396 ? { top: parseRem("50px") } : { top: parseRem("180px") }}
            imageLayer={"top"}
          />
        </div>
        <div
          style={{
            marginLeft: responsive1396 ? "0" : parseRem("775px"),
            zIndex: "6",
          }}
          className={"z-index-auto"}
        >
          <Subtitle
            head={t("homeScreen.officesHead")}
            headStyle={
              responsive1396
                ? { position: "relative", top: parseRem("-33px") }
                : undefined
            }
            body={
              responsive1396
                ? ""
                : t("homeScreen.cdmxOffice")
            }
            align={responsive1396 ? "center" : "left"}
            styleTextOptions={{
              linkList: ["https://www.google.com/maps/place/Torre+Mitikah+Torre+M+Wework/@19.3590229,-99.169436,19.75z/data=!4m5!3m4!1s0x85d1ffeaeb8587cb:0x859de3cf5309ca7c!8m2!3d19.3590279!4d-99.1691285", "https://www.google.com/maps/place/Woolfo-Sis/@29.0871384,-110.9577901,19.25z/data=!4m5!3m4!1s0x0:0x62f5ebfe3a3b4d04!8m2!3d29.0871033!4d-110.9575216"],
              linkStyles: ["custom-link pp grow right"],
            }}
          />
        </div>
        {responsive1396 ? (
          <>
            <Breaker size={parseRem("424px")} />
            <Subtitle
              head={""}
              body={t("homeScreen.hilloOffice")}
              bodyStyle={{ position: "relative", top: "" }}
              align={"center"}
              styleTextOptions={{
                linkList: ["https://www.google.com/maps/place/Torre+Mitikah+Torre+M+Wework/@19.3590229,-99.169436,19.75z/data=!4m5!3m4!1s0x85d1ffeaeb8587cb:0x859de3cf5309ca7c!8m2!3d19.3590279!4d-99.1691285", "https://www.google.com/maps/place/Woolfo-Sis/@29.0871384,-110.9577901,19.25z/data=!4m5!3m4!1s0x0:0x62f5ebfe3a3b4d04!8m2!3d29.0871033!4d-110.9575216"],
                linkStyles: ["custom-link pp grow right"],
              }}
            />
          </>
        ) : (
          <></>
        )}
      </Band>

      <Breaker
        size={parseRem("156px")}
        background={imgObj.item.circles}
        backgroundStyle={{
          width: parseRem("343px"),
          height: parseRem("343px"),
          left: parseRem("-112px"),
          top: parseRem("-153px"),
        }}
      />

      <Title
        head={t("homeScreen.footerHead")}
        styleTextOptions={{
          linkList: ["/contact"],
          fontSizeList: [parseRem("54px")],
        }}
      />

      <Footer breakerSize={parseRem("141px")} />
    </div>
  );
};

export default HomeScreen;
