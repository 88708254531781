export const TRANSLATIONS_ES = {

    /////////////////////HEADER ES//////////////////////////////
    header: {
        link: [
            "Inicio",       //0
            "Servicios",    //1
            "Portafolio",   //2 
            "Contacto",     //3
        ],
    },
    /////////////////////HOME SCREEN EN//////////////////////////////
    translateButton: {
        english: "Inglés",
        spanish: "Español",
    },
    /////////////////////HOME SCREEN ES//////////////////////////////
    homeScreen: {
        ///STATIC PAGE CONTENT///
        titleMain:'*$Somos expertos en $*',
        title:'*&desarrollo de software.&*',
        titleBody:`;; Nuestro objetivo es proporcionar *soluciones* para ;;
        nuestros clientes - *no problemas*.`,
        subtitle:"*Si puedes imaginarlo, ;; $nosotros$ podemos crearlo.*",
        subtitleBody:`INOWU se especializa en desarrollar productos audaces y hermosos todo el tiempo ;;
        mientras se apega a los plazos, para que pueda concentrarse en administrar su negocio. ;; ;;
        Nuestro equipo tiene más de 10 años de experiencia en desarrollo con ;;
        ubicaciones de oficinas en los Estados Unidos y México, lo que nos permite ;;
        trabajar en el mismo horario que nuestros clientes.`,
        subtitleBtn:"Obtener un presupuesto del proyecto",
        prevWork:`*Nuestro trabajo $anterior$*`,
        inowu:"*¿Por qué trabajar con nosotros?*",
        inowuText:`INOWU tiene experiencia trabajando con empresas ;;
        en varias industrias: FinTech, salud, ;;
        gobierno, seguridad, Big Data y más. ;;
        ¿Serás el próximo?`,
        getEstimate:"$Obtenga un presupuesto de proyecto$",
        testimonialsHead:`*$Escuche$ de nuestros ;; clientes anteriores*`,
        testimonialsBody:`Vea lo que nuestro trabajo tiene para ofrecer al escuchar cómo ayudó INOWU
        ;; a sus clientes anteriores para aprovechar su potencial comercial.`,
        officesHead:"*Visite nuestras $oficinas$*",
        cdmxOffice:`*Ciudad de México, México* ;;
        Cto Interior Avenida Río Churubusco 601, Xoco, ;;
        Benito Juárez, 03330 ;; 
        =Ver en mapa= ;;
        ;; ;; 
        *Hermosillo, Sonora(HQ)* ;; 
        Bulevar Abelardo L. Rodríguez & Av. Everardo ;;
        Monroy 51, Centro, 83000 ;;
        =Ver en mapa=
        ;; ;;
        *Phoenix, Arizona* ;; 
        Muy pronto...`,
        hilloOffice:`*Ciudad de México, México (HQ)* ;;
        Cto Interior Avenida Río Churubusco 601, Xoco, ;;
        Benito Juárez, 03330 ;; 
        =Ver en mapa= ;;
        ;; ;; 
        *Hermosillo, Sonora* ;; 
        Bulevar Abelardo L. Rodríguez & Av. Everardo ;;
        Monroy 51, Centro, 83000 ;;
        =Ver en mapa=
        ;; ;;
        *Phoenix, Arizona* ;; 
        Muy pronto...`,
        footerHead:"^*¿Listo para comenzar con INOWU?*^ ;; =Programar una reunión ahora=",
        ///DYNAMIC PAGE CONTENT///
        cienpotencia: {
            //TESTIMONIALS//
            body:`"El equipo siempre estuvo dispuesto a ayudar". ;; ;;
            INOWU desarrolló con éxito un sitio fácil de usar completo con todas las funcionalidades
            que el cliente requería. Permanecieron muy accesibles y receptivos a la retroalimentación y
            sugerencias a lo largo del compromiso.`,
            footer:`*Patricio Madrazo* ;; #Co-Fundador, _Cien potencia_#`,
            //DISPLAYER CONTENT//
            header: `*Academia de $idiomas$ para convertirte en $Políglota$*`,
            displayerBody: `Academia de Lenguas Internacionales es una plataforma para ser políglota y 
            aprender a hablar 8 idiomas al mismo tiempo desde su plataforma web de estudios.`,
            btnText: "$Lea la historia de éxito de Academia de Lenguas$",
        },
        bullground: {
            //TESTIMONIALS//
            body: `"Dicen lo que piensan y buscan la mejor solución para resolver un problema comercial". ;; ;;
            El nuevo sitio ha aumentado significativamente los ingresos recurrentes mensuales del cliente. INOWU es
            sin miedo a pensar fuera de la caja.`,
            footer: `*Hector* ;; #CMO, _Bullground_#`,
            //DISPLAYER CONTENT//
            header: `*La principal plataforma de aprendizaje $electrónico$ ;; financiero de $América Latina$, Bullground*`,
            displayerBody: `Bullground ayuda a los estudiantes a aprender sobre finanzas, el mercado de valores y las inversiones - es
            considerada la opción número uno para hispanohablantes! Disponible como plataforma web y aplicación móvil.`,
            btnText: "$Lea la historia de éxito de Bullground$",
        },
        myInfo: {
            //TESTIMONIALS//
            body: `"El equipo de INOWU siempre ha estado dispuesto a escuchar". ;; ;;
            INOWU ha liderado un proyecto exitoso.
            Han creado un ambiente relajado pero profesional para las necesidades del cliente mientras
            animándolos a expresar sus preocupaciones.`,
            footer: `*Nicolas Blanco Storz* ;; #Consultor de desarrollo de aplicaciones, _My Info App_#`,
        },
        gmEshop: {
            //TESTIMONIALS//
            body: `"Un gran costo, siempre ofrecían descuentos en las iteraciones". ;; ;;
            Aunque el sitio aún no se ha lanzado,
            el sitio web tiene todas las características de un sitio de comercio electrónico gracias al trabajo de INOWU. El equipo se comunicó
            regularmente con reuniones diarias y fue flexible con las solicitudes.`,
            footer: `*Leonardo Guerrero* ;; #Gerente de Comercio Electrónico, _GM E-SHOP_#`,
            //DISPLAYER CONTENT//
            header: `*Rediseño de plataforma de $E-commerce$ para GM E-Shop*`,
            displayerBody: `El equipo de INOWU utilizó los principios de UX y UI para crear un comercio electrónico atractivo y muy útil
            plataforma para el minorista de productos electrónicos GM E-Shop. Haga clic a continuación para leer más.`,
            btnText: "$Lea la historia de GM E-shop$",
        },
        cadefi: {
            //TESTIMONIALS//
            body: `“Son muy creativos para cumplir con las tareas que les dan”. Con la nueva plataforma,
            el cliente ha notado varios resultados positivos: un aumento del 30% en la tasa de retención, un dramático
            reducción de costos y mayores ingresos anuales.`,
            footer: `*Nancy Avila* ;; #COO, _Cadefi_#`,
            //DISPLAYER CONTENT//
            header: `*$Talleres en vivo$ y cursos electrónicos para contadores a través de $CADEFI$*`,
            displayerBody: `CADEFI es una plataforma en línea que ofrece cursos en línea por suscripción para contadores – usuarios
            incluso puede obtener la certificación a través del sitio web.`,
            btnText: "$Leer más sobre CADEFI$",
        },
        lgAutopartes: {
            //TESTIMONIALS//
            body: `"Los trabajos que hemos recibido hasta ahora, ha sido una asociación productiva.
            El cliente está impresionado con el trabajo realizado por INOWU, elogiándolos por su puntualidad y
            Los resultados de calidad han sido excelentes y se completaron a tiempo".`,
            footer: `*Luis Gerardo Nuñez Alba* ;; #CEO, _LG AUTOPARTES_#`,
            //DISPLAYER CONTENT//
            header: `El mejor buscador de $productos de autopartes$ en $México$`,
            displayerBody: `LG es un e-commerce que vende autopartes en todo México teniendo
            un comercio electrónico UI|UX amigable que ayuda a los usuarios a encontrar productos más rápido.`,
        },
        direxio: {
            //TESTIMONIALS//
            body: `"El resultado final fue como lo imaginaron los diseñadores". El cliente ganó notable
            clientes potenciales desde que INOWU lanzó la página web. Su diseño fue tal como lo quería el equipo interno.
            Mientras tanto, la línea de tiempo organizada del proveedor ayudó a mantener la comodidad durante toda la transacción.`,
            footer: `*Gustavo Fernando Barreto Torija* ;; #CFO, _DIREXIO_#`,
        },
        sunum: {
            //TESTIMONIALS//
            body: `"La comunicación siempre fue efectiva para cualquier aclaración o cambio para lo que se necesitaba."
            INOWU entregó el sitio web a tiempo para el lanzamiento cumpliendo con las expectativas del cliente. El equipo
            comunicación efectiva establecida y las etapas de entrega se cumplieron a tiempo.`,
            footer: `*Manuel Millones* ;; #CEO, _SUNUM_#`,
        },
        medmex: {
            //DISPLAYER CONTENT//
            header: `*Medmex, una aplicación enfocada en el cliente para el seguimiento de $historial médico$*`,
            displayerBody: `Medmex permite una comunicación más clara entre pacientes y médicos, y realiza un seguimiento de
            fluctuaciones de datos de salud con gráficos fácilmente comprensibles.`,
            btnText: "$Lea la historia de éxito de Medmex$",
        },
        wmobil: {
            //DISPLAYER CONTENT//
            header: `*WMobil, una aplicación de monitoreo comercial para $dueños de restaurantes$*`,
            displayerBody: `El diseño intuitivo de WMobil permite a los propietarios monitorear fácilmente los descuentos, el flujo de efectivo, el crédito y el débito
            ventas y entradas en tiempo real, todo desde un teléfono.`,
            btnText: "$Lea la historia de WMobil$",
        },
        academiaLenguas: {
            //DISPLAYER CONTENT//
            header: `La forma más rápida de ayudar a un $políglota$ a través de una plataforma web.`,
            displayerBody: `Academia de Lenguas es una academia que se enfoca en enseñar a estudiantes de
            cero a políglota, en esta plataforma los usuarios tienen varias herramientas para ayudarlos
            como extra antes y después de las clases.`,
        },
    },
    /////////////////////SERVICES SCREEN ES//////////////////////////////
    servicesScreen: {
        titleMain:`*Nuestro equipo son expertos en las $tecnologías más nuevas.$*`,
        servicesLink:"Leer más",
        portfolioLink:"Visitar sitio web del cliente",
        customSofDev: {
            head:`*=Desarrollo de software personalizado=*`,
            body:`Trabaje con un equipo dedicado a cumplir con sus especificaciones exactas para su software personalizado.`,
        },
        websiteDev: {
            head:`*=Desarrollo de sitios web=*`,
            body:`Marque su presencia en el espacio digital con el desarrollo de sitios web altamente funcionales.`,
        },
        mobileAppDev: {
            head:`*=Desarrollo de aplicaciones móviles=*`,
            body:`Conéctese con su audiencia dondequiera que vaya con el desarrollo innovador de aplicaciones móviles.`,
        },
        reactDev: {
            head:`*=Desarrollo con ReactJS=*`,
            body:`Logre un mejor rendimiento, mayor flexibilidad e interfaces de usuario enriquecidas con ReactJS.`,
        },
    },
    /////////////////////SERVICES PAGE SCREEN ES//////////////////////////////
    servicesPageScreen: {
        ///STATIC PAGE CONTENT///
        subtitle:`*Eligiendo $INOWU$*`,
        projectLink:`$Cotizar proyecto$`,
        ///DYNAMIC PAGE CONTENT///
        customSofDev: {
            //BOTTOM SLIDER//
            sliderHead:`*=Desarrollo de software personalizado=*`,
            sliderBody:`Trabaje con un equipo dedicado a cumplir con sus especificaciones exactas para su software personalizado.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Soluciones de desarrollo de $software a la medida$.*`,
            titleBody: `Aprenda cómo el equipo de INOWU crea soluciones de software personalizadas que ;;
            lo ayudan a alcanzar sus objetivos.`,
            title1:`*¿Por qué invertir en $desarrollo de software$?*`,
            about1: `Muchas empresas tienen ideas o requisitos que no se pueden lograr con
            una solución SaaS preempaquetada. Aunque hay una plétora de opciones disponibles en
            el mercado de SaaS, no hay garantía de que una solución "universal" proporcione
            con la funcionalidad exacta que su proyecto necesita.`,
            about2: `El tiempo dedicado a buscar la solución "perfecta" puede emplearse mejor trabajando
            con un equipo talentoso cuyo objetivo principal es brindarle un entregable que es
            sus especificaciones exactas. ;; INOWU emplea no solo a desarrolladores experimentados, sino también a un equipo
            de diseñadores UX | UI - lo que significa que su solución de software personalizada no solo será altamente funcional,
            pero también muy usable y atractivo. ;; No pierda el tiempo buscando un software único para todos
            solución - deje que INOWU cree una para usted.`,
            processTitle:`*Nuestro $proceso$*`,
            headline: `*Eligiendo INOWU.*`,
            subtext:`Deje que INOWU ayude a su negocio entregando ;;
            aplicaciones profesionales y funcionales, sitios web, ;;
            y software personalizado. ¡Empiece hoy!`,
            moreServices:`*Vea más de nuestros $increíbles servicios$*`,
            contactBox:`^*¿Listo para empezar? ¡Nosotros también!*^ ;; =Ponte en contacto con INOWU=`,
            //OUR PROCESS SUBTITLES//
            name1: "*Recopilación de información*",
            description1: `Documentamos completamente tus procesos y metodologías ágiles para entender tus ideas y
            requisitos y comenzar a idear cómo mostrarlos mejor.`,
            name2: "*Diseño*",
            description2: `Nuestro equipo de diseño comienza su parte - tomando elementos y mejores prácticas de UX | UI en
            consideración a cada paso del camino.`,
            name3: "*Desarrollo*",
            description3: `Ahora que sabemos cómo hacer que su solución de software funcione para usted y cómo le gustaría
            que se vea, es hora de implementar. Nuestros desarrolladores se sumergirán en el proceso de construcción.`,
            name4: "*Pruebas*",
            description4: `INOWU cree que la prueba es la fase más importante. Evaluamos tu solución con
            flujos de trabajo tanto manuales como automatizados, asegurando que todo estará listo a tiempo para
            su fecha de lanzamiento objetivo.`,
        },
        websiteDev: {
            //BOTTOM SLIDER//
            sliderHead:`*=Desarrollo de sitios web=*`,
            sliderBody:`Marque su presencia en el espacio digital con el desarrollo de sitios web altamente funcionales.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Servicios de desarrollo de $sitios web$.*`,
            titleBody: `Migra tu negocio a lo digital con un desarrollo de sitios web atractivos y altamente funcionales.`,
            title1:`*¿Por qué invertir en $desarrollo web a medida$?*`,
            about1: `El mundo sigue dependiendo cada vez más de las plataformas digitales, especialmente debido a la
            efectos de la pandemia - esto significa que tener una presencia en línea en forma de un sitio web es cada vez más
            vital para el éxito de las empresas.
            ;; ;;
            El desarrollo web eficiente es más que una simple página de destino - su sitio web debe tener todo lo necesario
            funcionalidad que permite a los usuarios interactuar con su negocio de manera fácil y sin problemas. El desarrollo del sitio web de INOWU da
            un sitio web que no solo es completamente funcional, sino que también se puede actualizar continuamente con nuevas funciones y widgets como
            tu negocio crece.`,
            about2: `Podemos diseñar su sitio web para impulsar el tráfico y la conversión con características y funcionalidades diseñadas
            para la usabilidad y el atractivo. Somos expertos en convertir negocios en digitales. No importa el punto de partida, nuestro equipo está preparado
            con el conocimiento y la creatividad para recomendar el mejor camino para que su negocio tenga éxito.`,
            processTitle:`*El $proceso$ de desarrollo web*`,
            headline: `*¿Por qué elegir INOWU?*`,
            subtext:`Los equipos de INOWU tienen más de una década de experiencia ;;
            en la industria del desarrollo de sitios web personalizados. ;;
            ¡Te podemos ayudar!`,
            moreServices:`*Explora otros $servicios$ de INOWU*`,
            contactBox:`^*¿Listo para construir su sitio web?*^ ;; =Ponte en contacto con INOWU=`,
            //OUR PROCESS SUBTITLES//
            name1: "*Evaluar*",
            description1: `Trabajaremos contigo para determinar el material que necesitas para migrar a digital y recomendarte el ideal.
            estrategia de implementacion.`,
            name2: "*conceptualizar*",
            description2: `Nuestro equipo de UX | UI avanzará a la fase de diseño y documentará durante todo el proceso.`,
            name3: "*Entregar*",
            description3: `Una vez que nos hayamos alineado completamente con su visión para el sitio web, nuestro equipo de desarrollo entregará la versión final del
            proyecto en su dominio personal.`,
        },
        mobileAppDev: {
            //BOTTOM SLIDER//
            sliderHead:`*=Desarrollo de aplicaciones móviles=*`,
            sliderBody:`Conéctese con su audiencia dondequiera que vaya con el desarrollo innovador de aplicaciones móviles.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Desarrollo de $aplicaciones móviles personalizadas$.*`,
            titleBody: `Haga crecer su negocio con una aplicación moderna y personalizada desarrollada por INOWU.`,
            title1:`*Logre un mayor alcance con $aplicaciones móviles$*`,
            about1: `Mantener la relevancia en un mercado competitivo implica llegar a su audiencia donde más importa -
            a través de aplicaciones móviles. Las aplicaciones móviles permiten una experiencia de usuario mejorada, acceso sobre la marcha y, en algunos casos, pueden reemplazar
            su sitio web por completo. Mejore su negocio y llegue a un público más amplio con el desarrollo profesional de aplicaciones.`,
            about2: `Diseñar una aplicación no es tan fácil como parece. App Store y Play Store tienen políticas específicas que
            puede evitar que los clientes se den cuenta de su visión. INOWU puede ajustarse a tus necesidades implementando herramientas como Flutter,
            permitiéndonos reutilizar el código y crear aplicaciones móviles visualmente atractivas que sean compatibles con dispositivos iOS y Android.`,
            processTitle:`*Nuestro $proceso$ de desarrollo de aplicaciones*`,
            headline: `*Haz realidad tu visión con INOWU.*`,
            subtext:`Las aplicaciones móviles están despegando más que nunca. ;;
            Trabaje con una compañía que pueda ayudarlo a hacerlo bien.`,
            moreServices:`*Otros $servicios$ de INOWU*`,
            contactBox:`^*¿Listo para lanzar su aplicación personalizada?*^ ;; =Empezar con INOWU=`,
            //OUR PROCESS SUBTITLES//
            name1: "*Investigación y Alineación*",
            description1: `Comenzamos recopilando información sobre las funciones y los diseños que desea incluir en su aplicación personalizada.`,
            name2: "*Ajustar y adaptar*",
            description2: `Para cumplir con las políticas de la tienda, trabajamos con usted para ajustar sus procesos y garantizar que su aplicación sea compatible
            en App Store y Play Store.`,
            name3: "*Diseño y Desarrollo*",
            description3: `Nuestro equipo de UX| UI formulará un diseño para la máxima usabilidad y estética, que luego se pasará a
            nuestro equipo de desarrollo de Flutter.`,
            name4: "*Probar y entregar*",
            description4: `Finalmente, probamos la aplicación en dispositivos iPhone y Android para garantizar que se logre la funcionalidad completa antes de la entrega.
            el producto final.`,
        },
        reactDev: {
            //BOTTOM SLIDER//
            sliderHead:`*=Desarrollo con React=*`,
            sliderBody:`Logre un mejor rendimiento, mayor flexibilidad e interfaces de usuario enriquecidas con ReactJS.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Desarrollo con $ReactJS$.*`,
            titleBody: `ReactJS es una biblioteca de JavaScript que permite a los desarrolladores crear interfaces de usuario altamente interactivas.`,
            title1:`*¿Por qué $ReactJS$ es la opción preferida?*`,
            about1: `Debido a su popularidad y alta usabilidad, muchos de nuestros clientes especifican que les gustaría trabajar con ReactJS
            – esta petición es música para nuestros oídos. Nuestros desarrolladores experimentados pueden combinar el marco ReactJS con otros paquetes,
            lo que permite tiempos de carga más rápidos y capacidades de edición basadas en componentes - modifique una cosa en un solo lugar, y el cambio es
            reflejado en toda la aplicación o programa.`,
            about2: `Muchos de nuestros primeros proyectos se crearon con ReactJS, lo que nos brinda la ventaja de tener una amplia experiencia con él.
            Además, INOWU trabaja con frecuencia con pilas que combinan ReactJS con otras herramientas, incluidas Firebase, MySQL y más. Gracias
            Gracias a esto, y a las capacidades basadas en componentes de ReactJS, podemos desarrollar ágilmente - más fácil y rápidamente que otras empresas.`,
            processTitle:`*Visión general del $proceso$*`,
            headline: `*¿Por qué elegir INOWU?*`,
            subtext:`Trabajando con una compañía de desarrollo que ;;
            entiende sus objetivos y el proceso es ;; 
            crucial - hacemos precisamente eso.`,
            moreServices:`*¿Cómo podemos $ayudarle$?*`,
            contactBox:`^*¿Listo para comenzar a desarrollar?*^ ;; =Ponte en contacto con INOWU hoy=`,
            //OUR PROCESS SUBTITLES//
            name1: "*Análisis e investigación*",
            description1: `Nuestro equipo analiza cómo podemos resolver mejor los problemas y adaptarnos a sus necesidades. ReactJS alberga bibliotecas que resuelven
            algunos problemas, pero no son compatibles con otros, por lo que nos tomamos el tiempo para seleccionar los que mejor se adapten a sus necesidades.`,
            name2: "*Recursos y prototipos*",
            description2: `Al mismo tiempo que la primera versión de la UX | UI Se está entregando , un arquitecto de INOWU se encarga de instalar
            todos los paquetes y bibliotecas que nos ayudarán a desarrollar más rápido. Para ello utilizamos Typescript y ReactJS.`,
            name3: "*Componentes y desarrollo*",
            description3: `Mientras que el prototipo de la UX | UI Se está finalizando, analizamos qué componentes aparecen con frecuencia en toda la aplicación.
            Al saber esto, sabemos cuáles se pueden reutilizar. Aquí es también cuando comienza la fase de desarrollo.`,
            name4: "*Entregables y revisiones*",
            description4: `Los proyectos de desarrollo de INOWU se ejecutan bajo un plan de Scrum: nuestro equipo proporciona entregables actualizados cada dos semanas. esto te da
            visibilidad de todo lo que estamos haciendo, además de permitirnos iterar en función de sus comentarios.`,
            name5: "*Pruebas y flujo*",
            description5: `INOWU considera que las pruebas son el paso más importante de cualquier proceso de desarrollo. Cada componente se somete a pruebas unitarias, por lo que se realizan pruebas de extremo a extremo
            es crucial ya que nos permite asegurarnos de que el flujo de toda la aplicación en su conjunto es correcto.`,
            name6: "*Entrega y recorrido*",
            description6: `Una vez que se completan las pruebas y se determina que la aplicación es completamente funcional, le entregamos el producto terminado y
            asegúrese de comprender cada parte de su nueva aplicación y su funcionalidad.`,
        },
    },
    /////////////////////PORTFOLIO SCREEN ES//////////////////////////////
    portfolioScreen: {
        titleMain:`*Descubre algunos de los $mejores trabajos$ de INOWU.*`,
        ribbon: `Subcontratado`,
        ribbon_proj: `Proyecto`,
        bullground: {
            head:`*=Bullground=*`,
            body:`INOWU desarrolló un sitio web y una aplicación móvil para esta plataforma de aprendizaje electrónico financiero.`,
        },
        wmobil: {
            head:`*=WMobil=*`,
            body:`INOWU creó una aplicación totalmente funcional para esta plataforma de seguimiento de negocios de restaurantes.`,
        },
        medmex: {
            head:`*=Medmex=*`,
            body:`INOWU desarrolló una aplicación para esta plataforma de seguimiento de información y antecedentes médicos.`,
        },
        gmEshop: {
            head:`*=GM E-shop - Cellphone Ecommerce=*`,
            body:`INOWU creó un sitio web de comercio electrónico con consideraciones de UX y UI para este cliente.`,
        },
        cadefi: {
            head:`*=CADEFI=*`,
            body:`INOWU creó un sitio web para este taller de contabilidad en vivo y proveedor de cursos.`,
        },
        lgAutopartes: {
            head:`*=LG Autopartes=*`,
            body:`INOWU ayudó a los usuarios a encontrar productos más rápido mediante la creación de una plataforma de comercio electrónico.`,
        },
        academiaLenguas: {
            head:`*=Academia de Lenguas=*`,
            body:`INOWU proporcionó a los estudiantes oportunidades de aprendizaje de idiomas mediante la creación de este sitio web.`,
        },
        cienpotencia: {
            head:`*=Cienpotencia=*`,
            body:`INOWU creó un sitio web para esta plataforma, que ofrece contenido premium de entrenadores expertos.`,
        },
        autio: {
            head:`*=autio - Nunca has hecho un viaje como este=*`,
            body:`INOWU está desarrollando y manteniendo la aplicación nativa de Android.`,
        },
        herdx: {
            head:`*=HERDX - Etiquetar, rastrear, tocar=*`,
            body:`INOWU está ayudando en el mantenimiento de la aplicación flutter y desarrollando nuevas funciones.`,
        },
        neta: {
            head:`*=Tonetip - La nueva forma de deteción de audios=*`,
            body:`INOWU desarrolló por completo la plataforma web y la aplicación móvil diseñadas para la detección automática de audios, ofreciendo una solución eficiente e innovadora.`,
        },
        atease: {
            head:`*=Videoremixes - Plataforma para descarga de musica y videos.=*`,
            body:`INOWU contribuyó al desarrollo e implementación de una innovadora plataforma para la descarga masiva de música y videos, diseñada específicamente para DJs en Estados Unidos y México.`,
        },
    },
    /////////////////////PROJECT SCREEN ES//////////////////////////////
    projectScreen: {
        ///STATIC PAGE CONTENT///
        projectLink:`$Obtenga un presupuesto de proyecto$`,
        ///DYNAMIC PAGE CONTENT///
        bullground: {
            //PROJECT SLIDER//
            sliderHead:`*=Bullground=*`,
            sliderBody:`INOWU desarrolló un sitio web y una aplicación móvil para esta plataforma de aprendizaje electrónico financiero.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*La mejor plataforma de $e-learning$ financiero en $Latinoamérica$.*`,
            titleBody: `Bullground es considerado como la mejor opción en América Latina para online
            educación financiera INOWU diseñó una plataforma web y una aplicación móvil para ayudar a los hispanohablantes
            los estudiantes aprenden sobre finanzas, el mercado de valores y las inversiones.`,
            headline: `*¿Por qué trabajar con nosotros?*`,
            subtext:`Ya sea que su proyecto sea atención médica, ;;
            gobierno, seguridad, Big Data o relacionado con ;;
            FinTech, INOWU tiene los recursos y ;;
            la experiencia para ayudar.`,
            bottomSlider:`*Sumérgete en más de nuestro $mejor trabajo$*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Página principal$*",
            description1: `Explore los últimos cursos que buscó en la plataforma mientras continúa su
            video actual desde la misma pantalla.`,
            name2: "*$Inicio de sesión y registro$*",
            description2: `Los usuarios pueden iniciar sesión y registrarse mediante la autenticación de Facebook o Google - no
            es necesario crear una cuenta.`,
            name3: "*$Clasificación de usuarios$*",
            description3: `¡Sé competitivo! INOWU creó un marcador donde los usuarios pueden ver cuántos
            puntos que han ganado otros usuarios.`,
            name4: "*$Recomendación de portafolio$*",
            description4: `Agregamos una función paga que brinda a los usuarios recomendaciones de portafolio una vez que
            compren una suscripción premium.`,
            name5: "*$API del mercado de la bolsa de valores$*",
            description5: `INOWU usó una API que extrae los precios de las acciones en vivo todos los días y los extrae
            en el sitio.`,
            name6: "*$Juego de preguntas$*",
            description6: `Los usuarios ahora pueden luchar entre sí y robar puntos al participar en
            trivia cara a cara.`,
            name7: "*$Páginas del curso$*",
            description7: `Cada curso tiene un video, junto con una descripción y todo el curso requerido.
            materiales`,
            name8: "*$Pruebas del curso$*",
            description8: `Algunos cursos incluyen cuestionarios que permiten a los usuarios ganar puntos dependiendo de
            puntaje general.`,
            name9: "*$Modelo de suscripción$*",
            description9: `Los usuarios tienen la opción de suscribirse a la plataforma y desbloquear contenido premium.`,
            name0: "*$Aplicación movil$*",
            description0: `INOWU implementó todas estas características en una aplicación. Disponible en Play Store y App Store.`,
        },
        cienpotencia: {
            //PROJECT SLIDER//
            sliderHead:`*=Cienpotencia=*`,
            sliderBody:`INOWU creó un sitio web para esta plataforma, que ofrece contenido premium de entrenadores expertos.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*$Cursos$ en línea de alta calidad sobre cualquier cosa, $para todos$.*`,
            titleBody: `Cienpotencia es una plataforma web con varios entrenadores enseñando sobre diferentes temas.
            Los usuarios pueden suscribirse a instructores individuales y ver su contenido premium a pedido.`,
            headline: `*¿Por qué INOWU?*`,
            subtext:`Si está buscando un equipo talentoso y de acción rápida ;;
            para su próximo proyecto de desarrollo, está en el lugar correcto. ;;
            Comience con INOWU hoy.`,
            bottomSlider:`*¿Buscas más de nuestros $mejores proyectos$?*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Funcionalidad$*",
            description1: `Los usuarios pueden navegar por la plataforma, suscribirse a entrenadores individuales y
            dar donaciones a sus favoritos.`,
            name2: "*$Donaciones y suscripciones$*",
            description2: `Suscríbase a múltiples instructores en una cuenta - todas sus suscripciones se almacenan y pueden administrarse.`,
            name3: "*$Oportunidades de trabajo$*",
            description3: `Los usuarios pueden encontrar oportunidades laborales a través de ¡Cienpotencia! Cargar sus documentos y pagar la tarifa es fácil.`,
        },
        medmex: {
            //PROJECT SLIDER//
            sliderHead:`*=Medmex=*`,
            sliderBody:`INOWU desarrolló una aplicación para esta plataforma de seguimiento de información y antecedentes médicos.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*$Seguimiento médico$ fácil de usar y centrado en el cliente.*`,
            titleBody: `Medmex permite a los usuarios realizar un seguimiento de la evolución de su salud, incluido el peso, la presión arterial y otros
            datos médicos importantes. La capacidad de compartir fácilmente significa que esta información se puede transferir fácilmente entre
            usuarios, incluida la transferencia de paciente a médico.`,
            headline: "*Trabaja con INOWU.*",
            subtext:`Desde el desarrollo de aplicaciones y sitios web hasta la creación ;;
            de soluciones de software personalizadas, INOWU puede ayudar a ;;
            clientes en muchas industrias diferentes con sus ;;
            proyectos de desarrollo.`,
            bottomSlider:`*Ver más del $mejor trabajo$ de INOWU*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Gráficos$*",
            description1: `Los usuarios pueden crear gráficos personalizados con sus datos de salud para visualizar el peso, la presión arterial,
            y otras medidas de progresión.`,
            name2: "*$Autenticación$*",
            description2: `Para acceder a Medmex, los usuarios pueden usar la autenticación de Facebook, Google o Apple.`,
            name3: "*$Perfiles compartibles$*",
            description3: `Los usuarios pueden compartir sus perfiles con los médicos a través de un código QR o enviando un código de usuario.`,
            name4: "*$Recordatorios de medicamentos$*",
            description4: `Cree notificaciones dentro de la aplicación para recordar al usuario que debe tomar su medicación.`,
        },
        gmEshop: {
            //PROJECT SLIDER//
            sliderHead:"*=GM E-shop=*",
            sliderBody:`INOWU creó un sitio web de comercio electrónico con consideraciones de UX y UI para este cliente.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Compras en línea fáciles con una ;; experiencia intuitiva de $e-commerce$.*`,
            titleBody: `GM E-Shop es un minorista en línea de productos electrónicos. INOWU les ayudó a crear un sistema totalmente funcional
            sitio web de comercio electrónico con experiencia de usuario y consideraciones de interfaz.`,
            headline: "*¿Listo para trabajar con nosotros?*",
            subtext:`Deje que INOWU ayude a su negocio brindando servicios profesionales ;;
            y aplicaciones funcionales, sitios web y software personalizado. ;;
            ¡Empiece hoy mismo!`,
            bottomSlider:`*Ver más del $mejor trabajo$ de INOWU*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Diseño intuitivo de UI | UX$*",
            description1: `INOWU desarrolló un sitio web funcional pero hermoso con consideraciones de UI y UX.`,
            name2: "*$Funcionalidad de comercio electrónico$*",
            description2: `El sitio está lleno de características de comercio electrónico: carrito de compras, filtro de productos, producto
            especificaciones y pago.`,
            name3: "*$Opciones de métodos de pago$*",
            description3: `Los usuarios pueden elegir su método de pago - PayPal, OXXO, Mercado Pago u Openpay.`,
            name4: "*$Integración con DHL$*",
            description4: `Cuando se completa un pedido, se envía al usuario información de seguimiento de DHL en tiempo real.`,
        },
        cadefi: {
            //PROJECT SLIDER//
            sliderHead:"*=CADEFI=*",
            sliderBody:`INOWU creó un sitio web para este taller de contabilidad en vivo y proveedor de cursos.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Exclusivos $talleres$ y $cursos en vivo$ de contabilidad.*`,
            titleBody: `CADEFI es un proveedor de aprendizaje en línea para aquellos interesados ​​en contabilidad. INOWU los ayudó
            mediante la construcción de una plataforma web altamente interactiva para que los usuarios obtengan conocimientos y certificaciones.`,
            headline: "*¡Trabaja con nosotros!*",
            subtext:`NOWU trabaja con empresas de FinTech, salud, ;;
            gobierno, seguridad, Big Data y mucho más. Nos encantaría ;;
            ayudarte con tu proyecto de desarrollo.`,
            bottomSlider: `*Descubre más del $trabajo$ de INOWU*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Servidor de transmisión$*",
            description1: `La plataforma tiene un servidor de transmisión integrado, que divide el ancho de banda del usuario y reduce los tiempos de retraso.`,
            name2: "*$Seguridad de la cuenta$*",
            description2: `Solo un usuario puede acceder a una cuenta a la vez, lo que mejora la seguridad de la plataforma.`,
            name3: "*$Opciones de pago individuales$*",
            description3: `Los usuarios pueden pagar los cursos con PayPal, Mercado Pago, OXXO o tarjeta de crédito/débito a través de Stripe.`,
            name4: "*$Certificaciones en línea$*",
            description4: `Al finalizar un curso, el usuario recibe un certificado de finalización descargable.`,
        },
        lgAutopartes: {
            //PROJECT SLIDER//
            sliderHead:"*=LG Autopartes=*",
            sliderBody:`INOWU ayudó a los usuarios a encontrar productos más rápido mediante la creación de una plataforma de comercio electrónico.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*La principal plataforma de búsqueda de $autopartes$ en $México$.*`,
            titleBody: `LG Autopartes llegó a INOWU buscando una plataforma intuitiva con consideraciones de UX y UI,
            permitiendo a los usuarios buscar fácilmente productos y obtener lo que necesitan más rápido.`,
            headline: "*Trabajando con INOWU.*",
            subtext:`Nuestro talentoso equipo de diseñadores y desarrolladores de UX/UI puede ;;
            completar proyectos sin problemas a tiempo, dentro del presupuesto y ;;
            según las especificaciones exactas de nuestros clientes.`,
            bottomSlider: `*Obtenga más información sobre nuestros $grandes logros$*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Búsqueda dinámica de autos$*",
            description1: `Los usuarios pueden filtrar los resultados por marca, modelo y año, lo que les permite buscar
            para su producto más rápido.`,
            name2: "*$Robusta plataforma de comercio electrónico$*",
            description2: `Con más de 10 000 productos, INOWU se aseguró de que la plataforma tuviera una sólida infraestructura de back-end.`,
            name3: "*$Sección de blogs$*",
            description3: `El sitio web permite la publicación continua de contenido dinámico, incluidos títulos, imágenes y entradas.`,
            name4: "*$Reservas en línea$*",
            description4: `Los usuarios pueden reservar citas en tiendas físicas, incluida la ubicación, el mecánico y la selección de tiempo.`,
            name5: "*$Integraciones de pago$*",
            description5: `Los usuarios pueden pagar su compra con PayPal, o con su tarjeta de crédito o débito con una integración de Stripe.`,
        },
        academiaLenguas: {
            //PROJECT SLIDER//
            sliderHead:"*=Academia de Lenguas=*",
            sliderBody: `INOWU proporcionó a los estudiantes oportunidades de aprendizaje de idiomas mediante la creación de este sitio web.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Una plataforma de aprendizaje en línea para $estudiantes$ de idiomas.*`,
            titleBody: `Academia de Lenguas es una academia en línea que ayuda a los estudiantes a aprender y dominar nuevos idiomas. INOWU agregó algunos intuitivos
            características a su plataforma en línea, haciéndola más fácil de usar.`,
            headline: "*Trabajando con INOWU.*",
            subtext: `Desde FinTech hasta atención médica, gobierno, seguridad y Big Data, ;;
            el equipo de INOWU es capaz de completar cualquier proyecto. ;;
            ¿Que estás esperando?`,
            bottomSlider: `*¿Quieres ver más de $nuestro trabajo$?*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Publicaciones y secciones de comentarios.$*",
            description1: `Los usuarios pueden publicar una pregunta sobre la clase, lo que permite a los administradores y otros estudiantes comentar y compartir sus opiniones.`,
            name2: "*$Búsqueda personalizada$*",
            description2: `La sólida función de búsqueda permite a los usuarios buscar por tema y un algoritmo ayuda a corregir los errores cometidos durante la búsqueda.`,
            name3: "*$Pago alojado en la plataforma$*",
            description3: `Los usuarios pueden suscribirse a la plataforma, pagar con OXXO, tarjeta de crédito/débito o SPEI y continuar con el pago a través de Conekta.`,
            name4: "*$Vales de OXXO$*",
            description4: `Si el usuario opta por pagar con OXXO, se genera un bono que le permite llevarlo a una tienda física y utilizarlo.`,
        },
        wmobil: {
            //PROJECT SLIDER//
            sliderHead:"*=WMobil=*",
            sliderBody:`INOWU creó una aplicación totalmente funcional para esta plataforma de seguimiento de negocios de restaurantes.`,
            //////////////////PAGE CONTENT//////////////////
            titleMain: `*Rastree sus $restaurantes$ desde cualquier lugar, $en cualquier momento$.*`,
            titleBody: `Disponible en Play Store y App Store, WMobil es una plataforma de seguimiento creada para ayudar a los restaurantes
            los propietarios monitorean sus restaurantes con datos en tiempo real. De descuentos, a rebajas, a efectivo, débito o crédito
            transacciones - toda esta información está en la palma de su mano.`,
            headline: "*¿Por qué elegir INOWU?*",
            subtext: `Nuestros experimentados equipos de diseñadores y desarrolladores de UX y UI ;;
            tienen la experiencia y el conocimiento necesarios para ;;
            crear aplicaciones móviles, sitios web y software ;;
            personalizado totalmente funcionales.`,
            bottomSlider: `*Lea más sobre nuestros $mejores proyectos$*`,
            //OUR PROCESS SUBTITLES//
            name1: "*$Ventas de restaurantes en tiempo real$*",
            description1: `Los usuarios pueden ver sus datos de ventas en tiempo real.
            Desde efectivo, hasta crédito y débito, hasta propinas y más.`,
            name2: "*$Principales datos de productos y personal$*",
            description2: `Obtenga una vista holística de sus datos, incluida la clasificación por top
            las ventas de productos o los ingresos de los empleados.`,
            name3: "*$Administración del lugar$*",
            description3: `Verifique los tickets de los clientes para verificar las ventas de productos o realizar un seguimiento de los ingresos
            con totales de ticket, en tiempo real.`,
            name4: "*$Informes$*",
            description4: `Genere informes a partir de un período de tiempo establecido para realizar un seguimiento de las ventas, el movimiento,
            cancelaciones o descuentos`,
            name5: "*$Aplicación de escritorio$*",
            description5: `INOWU tradujo todas estas características en una práctica aplicación móvil -
            rastrea tus datos sobre la marcha!`,
            name6: "*$Vista web$*",
            description6: `Creamos capacidades de doble acceso con una versión de escritorio de
            la plataforma WMobil.`,
            name7: "*$Suscripciones a restaurantes$*",
            description7: `Da de alta oficialmente tu restaurante y descarga la app en tu móvil,
            computadora de escritorio o portátil!`,
        },
    },
    /////////////////////CONTACT SCREEN ES//////////////////////////////
    contactScreen: {
        title: {
            subtitle:`*=$Agenda tu reunión ;; aquí$!=*`,
            head:`*¿Listo para trabajar con nosotros? $Envíanos un mensaje$!*`,
        },
        boxText: {
            name:`Nombre*`,
            email:`Correo electrónico*`,
            phone:`Teléfono*`,
            phoneMsg:`Número de teléfono invalido`,
            phoneMsg2:`Por favor, ingrese un número de teléfono`,
            phoneMsg3:`Número de teléfono obligatorio`,
            project:`Descripción del proyecto*`,
            reqMsg:`Este campo es obligatorio`,
            emailMsg:`Por favor, introduzca una dirección de correo electrónico válida`,
            submit:`Enviar mensaje`,
            submitMsg:`#Enviando...#`,
        },
        popup: {
            head:`Éxito`,
            headErr:`¡Oh no!`,
            body:`¡Enviado con éxito! Nos estaremos comunicando con usted en las próximas 24 horas.`,
            bodyErr:`¡Algo salió mal! ;; ¡Vuelve a intentarlo en unos minutos!`,
            successfulBtn:`¡Excelente!`,
            errBtn:`Entendido`,
        },
    },
    /////////////////////FOOTER ES//////////////////////////////
    footer: {
        privacy:`*Política de Privacidad*`,
        contact:`*Contacto*`,
        follow:`*Síguenos*`,
        terms:`*Términos de Servicio*`,
    },
    
   };