import "@theme/styles.scss";
import { useTranslation } from "react-i18next";
import { imgObj } from "utils/ImageRepo";
import Breaker from "@components/Breaker";
import { parseRem, styleText } from "utils/FunctionsBundle";
import { useMediaQuery } from "react-responsive";
import mx_flag from "@theme/images/icon/mx_flag.png";


// ############################# INTERFACES & PROPS #############################
// FOOTER #############################
interface FooterProps {
  breakerSize?: string;
  stickToBottom?: boolean;
  getRef?: any;
}

const FooterDefaultProps: FooterProps = {
  breakerSize: "0px",
  stickToBottom: false,
};

// ############################# FUNCTION COMPONENTS #############################
// FOOTER #############################
const Footer: React.FunctionComponent<FooterProps> = (props) => {
  // const { i18n } = useTranslation();
  const { t } = useTranslation();
  const responsive1080 = useMediaQuery({ query: "(max-width: 1020px)" });
  const lb = responsive1080 ? "  " : ";;";
  const lb2 = responsive1080 ? " " : ";;";

   const sectionLogo = (
    <div
      className="footer-section big"
      style={
        responsive1080
          ? { marginLeft: "2rem", justifyContent: "center", height: "auto" }
          : { paddingTop: parseRem("50px") }
      }
    >
      <a href="/" className="logo">
        <img alt="logo" src={imgObj.logo.inowu_gradient} />
      </a>
    </div>
  );
  const sectionPrivacy = (
    <div
      className="footer-section"
      style={
        responsive1080
          ? { paddingTop: "0px", alignItems: "center", textAlign: "center" }
          : undefined
      }
    >
      <p>
        {styleText({
          text: //t("footer.privacy")+`${lb}` + 
                //t("footer.terms")+ `;;
                `;; $INOWUDEV © 2022$`,
          emphasisColor: "rgba(0, 58, 84, 0.8)",
        })}
      </p>
    </div>
  );
  const sectionContact = (
    <div
      className="footer-section"
      style={
        responsive1080
          ? { paddingTop: "1rem", alignItems: "center" }
          : undefined
      }
    >
      {/* CONTACT */}
      <p
        style= {{
          
          marginLeft: responsive1080 ? "25%" : "15%"}}>
        
        {styleText({
          text: t("footer.contact")+`${lb2}`, 
                // =$(+52) 662 329 0233$= ${lb}
                // =$(+1) 646 266 0233$= ${lb}
                // =$kevin.woolfolk@inowu.dev$=`,
          emphasisColor: "rgba(0, 58, 84, 0.8)",
          linkList: ["tel:+52-662-329-0233", "mailto:kevin.woolfolk@inowu.dev"],
          linkStyles: ["as-a", "as-a"],
        })}
      </p>
      {/* MX FLAG */}
      <p>
        <img  
        style={{
          flex: 1,
          width:25,
          height:15,
          marginRight:15,
        }}
        alt = "img-inowu"
        src={mx_flag}/> 
        {styleText({
          text:    `=$(+52) 662 329 0233$= ${lb}`,
                
          emphasisColor: "rgba(0, 58, 84, 0.8)",
          linkList: ["tel:+52-662-329-0233", "mailto:hello@inowu.dev"],
          linkStyles: ["as-a", "as-a"],
        })}
      </p>
      {/* US FLAG */}
      {/* E-MAIL */}
      <p
        style= {{
          
          marginLeft: responsive1080 ? "20%" : "7%"}}>
        {styleText({
          text: //t("footer.contact")+`${lb2}`, 
          //`=$(+52) 662 329 0233$= ${lb}`,
                //`=$(+1) 646 266 0233$= ${lb}`,
                 `=$hello@inowu.dev$=`,
          emphasisColor: "rgba(0, 58, 84, 0.8)",
          linkList: ["tel:+52-662-329-0233", "mailto:hello@inowu.dev"],
          linkStyles: ["as-a", "as-a"],
        })}
      </p>
    </div>
  );
  const sectionSocial = (
    <div
      className="footer-section"
      style={
        responsive1080
          ? { alignItems: "center", marginRight: "2rem" }
          : undefined
      }
    >
      <p>
        {styleText({
          text: t("footer.follow"),
        })}
      </p>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <a className="social-link" href={"https://www.facebook.com/inowudev/"}>
          <img src={imgObj.icon.facebook_inside} alt="facebook" />
        </a>

        <a
          className="social-link"
          href={"https://www.instagram.com/inowu_development/"}
        >
          <img src={imgObj.icon.instagram_inside} alt="instagram" />
        </a>

        <a className="social-link" href={"https://clutch.co/profile/inowu"}>
          <img src={imgObj.icon.clutch_hollow} alt="clutch" />
        </a>

        <a
          className="social-link"
          href={"https://mx.linkedin.com/company/inowu-development"}
        >
          <img src={imgObj.icon.linkedin_inside} alt="linkedin" />
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Breaker
        size={props.stickToBottom ? "0" : props.breakerSize}
        style={
          props.stickToBottom
            ? { height: `calc(${props.breakerSize} + ${parseRem(200)})` }
            : {}
        }
      />

      <div
        className="footer"
        ref={props.getRef}
        style={
          props.stickToBottom
            ? { position: "absolute", bottom: "0px" }
            : undefined
        }
      >
        {responsive1080 ? (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {sectionLogo}
              {sectionSocial}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "3",
              }}
            >
              {sectionContact}
              {sectionPrivacy}
            </div>
          </>
        ) : (
          <>
            {sectionLogo}
            {sectionPrivacy}
            {sectionContact}
            {sectionSocial}
          </>
        )}

        {/* <div className="footer-section big"> </div> */}
      </div>
    </>
  );
};

// ############################# EXPORTS #############################
Footer.defaultProps = FooterDefaultProps;

export default Footer;
