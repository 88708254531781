import "@theme/styles.scss";
import { CSSProperties } from "react";
// import { parseRem } from "utils/FunctionsBundle";

// ############## INTERFACES & PROPS ################
// ImageHolder ##############
interface ImageHolderProps {
  imageSrc?: string;
  imageAlt?: string;
  imageLayer?: "top" | "bottom";
  imageSize?: [string, string] | string;
  imagePosition?: number;

  debug?: boolean;

  canvasWidth?: string;
  canvasHeight?: string;
  canvasPosition?:
    | {
        top?: string;
        left?: string;
        bottom?: string;
        right?: string;
      }
    | "top"
    | "bottom"
    | "bottom-in"
    | "top-in";

  style?: CSSProperties;
  imageStyle?: CSSProperties;
  canvasStyle?: CSSProperties;
  gradient?: boolean;
}

const ImageHolderDefaultProps: ImageHolderProps = {
  imageAlt: "displayed image",
  imageLayer: "bottom",
  canvasPosition: "bottom",
  canvasWidth: "100%",
  canvasHeight: "auto",
  imagePosition: 7,
  imageSize: "auto",
  debug: false,
  gradient: false,
};

// ############## FUNCTION COMPONENTS ###############
// ImageHolder ##############
const ImageHolder: React.FunctionComponent<ImageHolderProps> = (props) => {
  var canvasPosition = {};

  switch (props.canvasPosition) {
    case "bottom":
      canvasPosition = { top: "100%" };
      break;

    case "bottom-in":
      canvasPosition = { bottom: "0%" };
      break;

    case "top":
      canvasPosition = { bottom: "100%" };
      break;

    case "top-in":
      canvasPosition = { top: "0%" };
      break;

    default:
      canvasPosition = { ...props.canvasPosition };
      break;
  }

  var canvasDisplay = {};
  if (
    props.imagePosition === 2 ||
    props.imagePosition === 5 ||
    props.imagePosition === 8
  )
    canvasDisplay = { justifyContent: "center" };
  else if (
    props.imagePosition === 3 ||
    props.imagePosition === 6 ||
    props.imagePosition === 9
  )
    canvasDisplay = { justifyContent: "flex-end" };
  else canvasDisplay = { justifyContent: "flex-start" };

  if (
    props.imagePosition === 4 ||
    props.imagePosition === 5 ||
    props.imagePosition === 6
  )
    canvasDisplay = { ...canvasDisplay, alignItems: "center" };
  else if (
    props.imagePosition === 1 ||
    props.imagePosition === 2 ||
    props.imagePosition === 3
  )
    canvasDisplay = { ...canvasDisplay, alignItems: "flex-end" };
  else canvasDisplay = { ...canvasDisplay, alignItems: "flex-start" };

  var sizeX =
    typeof props.imageSize !== "string" ? props.imageSize![0] : props.imageSize;

  var sizeY =
    typeof props.imageSize !== "string" ? props.imageSize![1] : props.imageSize;

  var layer = props.imageLayer === "top" ? "5" : "-5";

  return (
    <div
      className="image-holder no-selectable-all"
      style={{
        zIndex: layer,
        ...props.style,
      }}
    >
      <div
        className="image-holder-canvas"
        onTouchStart={(e) => {
          e.preventDefault();
        }}
        style={{
          zIndex: layer,
          width: props.canvasWidth,
          height: props.canvasHeight,
          background: props.debug
            ? `repeating-linear-gradient(45deg, #606cbc2a, #606cbc2a 10px, #4652982a 10px, #4652982a 20px)`
            : "",
          ...canvasPosition,
          ...canvasDisplay,
          ...props.canvasStyle,
        }}
      >
        {props.children ? (
          props.children
        ) : !props.gradient ? (
          <img
            src={props.imageSrc}
            alt={props.imageAlt}
            style={{
              width: sizeX,
              height: sizeY,
              zIndex: layer,

              ...props.imageStyle,
            }}
          />
        ) : (
          <canvas
            id="gradient-canvas"
            data-transition-in
            style={{
              width: sizeX,
              height: sizeY,
              zIndex: layer,

              ...props.imageStyle,
            }}
          ></canvas>
        )}
      </div>
    </div>
  );
};

// ################################ EXPORTS
ImageHolder.defaultProps = ImageHolderDefaultProps;

export default ImageHolder;
