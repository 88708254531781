import ServicePageScreen from "@screens/ServicePageScreen";

function ServicesProjectContainer() {
  return (
    <>
      <ServicePageScreen />
    </>
  );
}

export default ServicesProjectContainer;
