import "@theme/styles.scss";
import React, { CSSProperties } from "react";
// import { useTranslation } from "react-i18next";
import { styleText, styleTextOptions } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// SUBTITLE #############################
interface SubtitleProps {
  head: string;
  headColor?: string;
  headStyle?: CSSProperties;

  body?: string;
  bodyColor?: string;
  bodyStyle?: CSSProperties;

  buttonText?: string;
  buttonURL?: string;
  buttonTransition?:
    | "grow both"
    | "grow left"
    | "grow right"
    | "shrink both"
    | "shrink left"
    | "shrink right"
    | "increase"
    | "decrease";
  buttonStyle?: CSSProperties;

  align?: "left" | "right" | "center";
  styleTextOptions?: styleTextOptions;
  shrink?: boolean;
  style?: CSSProperties;
}

const SubtitleDefaultProps: SubtitleProps = {
  head: "",
  headColor: "#003a54",

  bodyColor: "#003a54",

  buttonURL: "/",
  buttonTransition: "grow both",

  align: "center",
  shrink: false,
};

// ############################# FUNCTION COMPONENTS #############################
// SUBTITLE #############################
const Subtitle: React.FunctionComponent<SubtitleProps> = (props) => {
  // useTranslation(); // Auto translate text

  var style: CSSProperties = {
    textAlign: props.align,
    alignItems:
      props.align === "left"
        ? "flex-start"
        : props.align === "right"
        ? "flex-end"
        : "center",
  };

  return (
    <div
      className={"subtitle"}
      style={{
        ...style,
        ...props.style,
      }}
    >
      <h2
        className={"subtitle-head" + (props.shrink ? " shrink" : "")}
        style={{ color: props.headColor, ...props.headStyle }}
      >
        {styleText({ ...props.styleTextOptions, text: props.head })}
      </h2>

      {props.body ? (
        <p
          className={"subtitle-body" + (props.shrink ? " shrink" : "")}
          style={{ color: props.bodyColor, ...props.bodyStyle }}
        >
          {styleText({ ...props.styleTextOptions, text: props.body })}
        </p>
      ) : (
        <></>
      )}

      {props.buttonText ? (
        <a
          className={
            "subtitle-link custom-link " +
            props.buttonTransition +
            (props.shrink ? " shrink" : "")
          }
          href={props.buttonURL}
          style={{ ...props.buttonStyle }}
        >
          {styleText({ ...props.styleTextOptions, text: props.buttonText })}
        </a>
      ) : (
        <></>
      )}

      {props.children}
    </div>
  );
};

// ############################# EXPORTS #############################
Subtitle.defaultProps = SubtitleDefaultProps;

export default Subtitle;
