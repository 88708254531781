import ContactContainer from "@containers/ContactContainer";
import HomeContainer from "@containers/HomeContainer";
import PortfolioContainer from "@containers/PortfolioContainer";
import ServicesContainer from "@containers/ServicesContainer";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./theme/styles.scss";
import PortfolioProjectContainer from "@containers/PortfolioProjectContainer";
import ServicesProjectContainer from "@containers/ServicesProjectContainer";
import BillsContainer from "@containers/BillsContainer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log("billscreen");
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/home" element={<HomeContainer />} />
          <Route path="/services" element={<ServicesContainer />} />
          <Route
            path="/services/:service"
            element={<ServicesProjectContainer />}
          />
          <Route path="/portfolio" element={<PortfolioContainer />} />
          <Route
            path="/portfolio/:project"
            element={<PortfolioProjectContainer />}
          />
          <Route path="/contact" element={<ContactContainer />} />
          <Route path="/convencion" element={<BillsContainer />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
