import "@theme/styles.scss";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";

import emailjs from "@emailjs/browser";
import { formatText, parseRem, styleText } from "utils/FunctionsBundle";
import { useTranslation } from "react-i18next";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
emailjs.init("user_itfQnWMwlnZrVcVMwjZwQ"); // inowudev@gmail.com

// ############################# INTERFACES & PROPS #############################
// CONTACT FORM #############################
interface ContactFormProps {}

const ContactFormDefaultProps: ContactFormProps = {};


// ############################# FUNCTION COMPONENTS #############################
// CONTACT FORM #############################

const ContactForm: React.FunctionComponent<ContactFormProps> = (props) => {
  useTranslation(); // Auto translate text
  const { t } = useTranslation();
  const [sendingMessage, setSendingMessage] = useState("initial");
  const [show, setShow] = useState(true);
  const [phone, setPhone] = useState("");

  const handleSubmit = (values) => {
    if (sendingMessage === "in process") return;

    setSendingMessage("in process");
    const msg = {
      ...values,
      phone: phone,
      date: new Date(),
    };

    // PRODUCTION ENABLED
    emailjs.send("service_11aqbq5", "inowu_contact", msg).then(
      (response) => {
        console.log(response.status, response.text);
        setSendingMessage("successful");
      },
      (error) => {
        console.log(error);
        setSendingMessage("error");
      }
    );

    // SIMULATE DELAY
    // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    // Promise.resolve(delay(5000)).then(() => {
    //   console.log(msg);
    //   setSendingMessage("successful");
    // });
  };


  const handleModalClose = () => {
    setSendingMessage("initial");
    setShow(true);
    window.location.reload();
  };

  const schema = yup.object().shape({
    name: yup.string().required(formatText({ text: t("contactScreen.boxText.reqMsg") })),
    email: yup
      .string()
      .email(formatText({ text: t("contactScreen.boxText.emailMsg") }))
      .required(formatText({ text: t("contactScreen.boxText.reqMsg") })),
    phone: yup.string(),
    // .required(formatText({ text: "ContactForm.error.required" })),
    description: yup
      .string()
      .required(formatText({ text: t("contactScreen.boxText.reqMsg") })),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          description: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleReset,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form
            className="contact-form"
            autoComplete="false"
            onSubmit={(e) => {
              if (phone && isValidPhoneNumber(phone)) handleSubmit(e);
              else e.preventDefault();
            }}
          >

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: parseRem("40px"),
              }}
            >
              <div className="form-field">
                <div>
                  <label htmlFor="name">{styleText({ text: t("contactScreen.boxText.name") })}</label>
                  <span className="form-error">{errors.name}</span>
                </div>

                <input
                  id="name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>

              <div className="form-field">
                <div>
                  <label htmlFor="email" autoSave="false">
                    {styleText({ text: t("contactScreen.boxText.email") })}
                  </label>
                  <span className="form-error">{errors.email}</span>
                </div>

                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </div>

              <div
                className="form-field"
                style={{ maxWidth: parseRem("450px") }}
              >
                <div>
                  <label htmlFor="phone">{styleText({ text: t("contactScreen.boxText.phone") })}</label>
                  <span className="form-error">
                    {phone
                      ? !isValidPhoneNumber(phone)
                        ? t("contactScreen.boxText.phoneMsg")
                        : undefined
                      : t("contactScreen.boxText.phoneMsg2")}
                  </span>
                </div>

                <PhoneInputWithCountrySelect
                  onBlur={() => {}}
                  international
                  defaultCountry={"US"}
                  id={"phone"}
                  value={phone}
                  onChange={(val) => {
                    console.log(
                      val
                        ? isValidPhoneNumber(val)
                          ? undefined
                          : t("contactScreen.boxText.phoneMsg")
                        : t("contactScreen.boxText.phoneMsg3")
                    );
                    setPhone(val ? val : "");
                  }}
                  style={{ marginBottom: parseRem("24px") }}
                  // setCustomValidity={isValidPhoneNumber(phone)}
                />
              </div>
            </div>

            <div className="form-field">
              <div>
                <label htmlFor="description"
                style={{ marginTop: '12%'}}>
                  {styleText({ text: t("contactScreen.boxText.project") })}
                </label>
                <span className="form-error">{errors.description}</span>
              </div>
              <textarea
                id="description"
                name="description"
                onChange={handleChange}
                style={{ resize: "none" }}
                value={values.description}
              />

              <button
                type="submit"
                className="custom-link pp increase"
                disabled={sendingMessage === "in process"}
                style={{ alignSelf: "flex-end", fontSize: parseRem("37px") }}
              >
                {sendingMessage === "in process"
                  ? styleText({ text: t("contactScreen.boxText.submitMsg") })
                  : styleText({ text: t("contactScreen.boxText.submit") })}
              </button>
            </div>
            {/* <button 
              className="calendlyPopup"
              onClick={() => calendlyOpen()}
              >
              {styleText({text: '*Schedule your meeting*'})}
              
            </button> */}
            {/* <PopupWidget
              url="https://calendly.com/your_scheduling_page"
              rootElement={document.getElementById("calendlyPopup")}
              text="Click here to schedule!"
              textColor="#ffffff"
              color="#00a2ff"
            /> */}

            {/* <InlineWidget url="https://calendly.com/inowudev/15min?month=2022-07" 
                styles={{
                  height: '780px',
                  width: '400px',
                  minWidth: '300px',
                  marginLeft: '5%',
                  marginRight: '2%',
                }}/> */}
          </form>
        )}
      </Formik>

      <Modal
        show={
          show &&
          (sendingMessage === "error" || sendingMessage === "successful")
        }
        onHide={() => {
          setShow(false);
        }}
        onExited={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {sendingMessage === "successful"
              ? styleText({ text: t("contactScreen.popup.head") })
              : styleText({ text: t("contactScreen.popup.headErr") })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sendingMessage === "successful"
            ? styleText({
                text: t("contactScreen.popup.body"),
              })
            : styleText({
                text: t("contactScreen.popup.bodyErr"),
              })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            {sendingMessage === "successful"
              ? styleText({ text: t("contactScreen.popup.successfulBtn") })
              : styleText({ text: t("contactScreen.popup.errBtn") })}
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

// ############################# EXPORTS #############################
ContactForm.defaultProps = ContactFormDefaultProps;

export default ContactForm;
