import "@theme/styles.scss";
import React, { CSSProperties } from "react";
import { parseRem } from "utils/FunctionsBundle";

// ############################# INTERFACES & PROPS #############################
// MARGINALIZER #############################
interface MarginalizerProps {
  size?: string;
  left?: string;
  right?: string;
  paddSize?: string;
  paddLeft?: string;
  paddRight?: string;
  flex?: boolean;
  flowRoot?: boolean;
  column?: boolean;
  style?: CSSProperties;
  responsive?: boolean;
}

const MarginalizerDefaultProps: MarginalizerProps = {
  size: "64px",
  paddSize: "0px",
  flex: false,
  flowRoot: false,
  column: false,
  responsive: true,
};

// ############################# FUNCTION COMPONENTS #############################
// MARGINALIZER #############################
const Marginalizer: React.FunctionComponent<MarginalizerProps> = (props) => {
  var MargClass = "Marginalizer";
  if (props.flex) MargClass += " flex";
  if (props.flowRoot) MargClass += " flow-root";
  if (props.column) MargClass += " flex-column";

  const rem = (val?: string) => {
    if (props.responsive && val !== undefined) return parseRem(val);
    else return val;
  };

  return (
    <div
      className={MargClass}
      style={{
        marginLeft: rem(props.left) ?? rem(props.size),
        marginRight: rem(props.right) ?? rem(props.size),
        paddingLeft: rem(props.paddLeft) ?? rem(props.paddSize),
        paddingRight: rem(props.paddLeft) ?? rem(props.paddSize),
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

Marginalizer.defaultProps = MarginalizerDefaultProps;

export default Marginalizer;
