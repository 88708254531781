import "@theme/styles.scss";
import { CSSProperties } from "react";
import { imgObj } from "utils/ImageRepo";

// ############## INTERFACES & PROPS ################
// ScrollButton ###########
interface ScrollButtonProps {
  amount?: number;
  coords?: Array<number>;
  toElement?: React.MutableRefObject<any>;
  scrollByUnit?: "px" | "screens";

  backgroundColor?: string;
  textColor?: string;
  useIcon?: boolean;
  imageSytle?: CSSProperties;
}

const ScrollButtonDefaultProps: ScrollButtonProps = {
  amount: 0,
  coords: [],
  scrollByUnit: "px",
  backgroundColor: "white",
  textColor: "#00e6be",
  useIcon: true,
};

// ############## FUNCTION COMPONENTS ###############
// ScrollButton
const ScrollButton: React.FunctionComponent<ScrollButtonProps> = (props) => {
  return (
    <div className="scroll-button-container">
      <button
        className="scroll-button"
        onClick={() => {
          if (props.toElement && props.toElement.current) {
            props.toElement.current.scrollIntoView({ block: "center" });
            return;
          }

          if (props.coords && props.coords[0]) {
            window.scrollTo(
              props.coords[0],
              props.coords[1] ? props.coords[1] : props.coords[0]
            );
            return;
          }

          if (props.amount) {
            window.scrollBy(
              0,
              props.scrollByUnit === "screens"
                ? props.amount * window.innerHeight
                : props.amount
            );
          }
        }}
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}
      >
        {props.useIcon ? (
          <img
            src={imgObj.icon.arrow_down}
            alt="arrow"
            style={
              props.amount! < 0
                ? { transform: "rotate(180deg)", ...props.imageSytle }
                : { ...props.imageSytle }
            }
          />
        ) : props.amount! < 0 ? (
          "↑"
        ) : (
          "↓"
        )}

        {/* ▲▼ */}
      </button>
    </div>
  );
};

// ################################ EXPORTS
ScrollButton.defaultProps = ScrollButtonDefaultProps;

export default ScrollButton;
