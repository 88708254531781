import Breaker from "@components/Breaker";
//import Slider from "@components/CustomSlider";
import Footer from "@components/Footer";
import Header from "@components/Header";
import ImageHolder from "@components/ImageHolder";
import ItemsList from "@components/ItemsList";
import Marginalizer from "@components/Marginalizer";
//import ReactiveContainer from "@components/ReactiveContainer";
import ScrollButton from "@components/ScrollButton";
import Subtitle from "@components/Subtitle";
import Title from "@components/Title";
import "@theme/styles.scss";
import { useRef } from "react";
//import { alignPropType } from "react-bootstrap/esm/types";
import { useMediaQuery } from "react-responsive";
import { Navigate, useParams } from "react-router";
import { Helmet } from "react-helmet";
import {
  formatText,
  parseRem,
  shuffleArray,
  styleText,
  suspendText,
} from "utils/FunctionsBundle";
import { imgObj } from "utils/ImageRepo";
import TranslateButton from "@components/TranslateButton";
import { useTranslation } from "react-i18next";
//import ProjectScreen from "./ProjectScreen";

const ServicePageScreen = () => {
  const {t} = useTranslation();
  const scrollRef = useRef(null);

  const responsive470 = useMediaQuery({ query: "(max-width: 470px)" });
  const responsive770 = useMediaQuery({ query: "(max-width: 770px)" });
  const responsive820 = useMediaQuery({ query: "(max-width: 820px)" });
  const responsive920 = useMediaQuery({ query: "(max-width: 920px)" });
  const responsive1396 = useMediaQuery({ query: "(max-width: 1396px)" });

  const servicesInfo = [
    {
      name: "custom_software_development",
      url: "custom-software-development",
      head: t("servicesPageScreen.customSofDev.sliderHead"),
      body: t("servicesPageScreen.customSofDev.sliderBody"),
    },
    {
      name: "website_development",
      url: "website-development",
      head: t("servicesPageScreen.websiteDev.sliderHead"),
      body: t("servicesPageScreen.websiteDev.sliderBody"),
    },
    {
      name: "mobile_app_development",
      url: "mobile-app-development",
      head: t("servicesPageScreen.mobileAppDev.sliderHead"),
      body: t("servicesPageScreen.mobileAppDev.sliderBody"),
    },
    {
      name: "react_development",
      url: "react-development",
      head: t("servicesPageScreen.reactDev.sliderHead"),
      body: t("servicesPageScreen.reactDev.sliderBody"),
    },
  ];

  const serviceMap = {
    custom_software_development: {
      seo_title:`Custom Software Development | INOWU`,
      seo_description:`INOWU can help you develop custom software for your company. By improving user functionality and overall look and feel, you can drive sales and see growth.`,
      main_title: t("servicesPageScreen.customSofDev.titleMain"),
      main_description:t("servicesPageScreen.customSofDev.titleBody"),
      title1:t("servicesPageScreen.customSofDev.title1"),
      description1: t("servicesPageScreen.customSofDev.about1"),
      description2: t("servicesPageScreen.customSofDev.about2"),
      process_title:t("servicesPageScreen.customSofDev.processTitle"),
      headline: t("servicesPageScreen.customSofDev.headline"),
      subtext:t("servicesPageScreen.customSofDev.subtext"),
      more_services:t("servicesPageScreen.customSofDev.moreServices"),
      contact_box:t("servicesPageScreen.customSofDev.contactBox"),
      //OUR PROCESS SUBTITLES
      processes: [
        {
          name: t("servicesPageScreen.customSofDev.name1"),
          description: t("servicesPageScreen.customSofDev.description1"),
          icon: "search_icon"
        },
        {
          name: t("servicesPageScreen.customSofDev.name2"),
          description: t("servicesPageScreen.customSofDev.description2"),
          icon: "paint_pad"
        },
        {
          name: t("servicesPageScreen.customSofDev.name3"),
          description: t("servicesPageScreen.customSofDev.description3"),
          icon: "development"
        },
        {
          name: t("servicesPageScreen.customSofDev.name4"),
          description: t("servicesPageScreen.customSofDev.description4"),
          icon: "rocket"
        },
      ],
      dominantColor: "#00E6BE",
    },
    website_development: {
      seo_title:`Custom Website Development | INOWU`,
      seo_description:`Functional websites not only give a good impression of your company, they also allow users to navigate your product or service more easily than ever before.`,
      main_title: t("servicesPageScreen.websiteDev.titleMain"),
      main_description:t("servicesPageScreen.websiteDev.titleBody"),
      title1:t("servicesPageScreen.websiteDev.title1"),
      description1: t("servicesPageScreen.websiteDev.about1"),
      description2: t("servicesPageScreen.websiteDev.about2"),
      process_title:t("servicesPageScreen.websiteDev.processTitle"),
      headline: t("servicesPageScreen.websiteDev.headline"),
      subtext:t("servicesPageScreen.websiteDev.subtext"),
      more_services:t("servicesPageScreen.websiteDev.moreServices"),
      contact_box:t("servicesPageScreen.websiteDev.contactBox"),
      //OUR PROCESS SUBTITLES
      processes: [
        {
          name: t("servicesPageScreen.websiteDev.name1"),
          description: t("servicesPageScreen.websiteDev.description1"),
          icon: "can"
        },
        {
          name: t("servicesPageScreen.websiteDev.name2"),
          description: t("servicesPageScreen.websiteDev.description2"),
          icon: "cloud"
        },
        {
          name: t("servicesPageScreen.websiteDev.name3"),
          description: t("servicesPageScreen.websiteDev.description3"),
          icon: "stars"
        },
      ],
      dominantColor: "#00E6BE",
    },
    mobile_app_development: {
      seo_title:`Custom Mobile App Development | INOWU`,
      seo_description:`As the world relies more and more on mobile devices, it's crucial to have an app that not only looks good, but works well. The good news is, INOWU can help.`,
      main_title: t("servicesPageScreen.mobileAppDev.titleMain"),
      main_description:t("servicesPageScreen.mobileAppDev.titleBody"),
      title1:t("servicesPageScreen.mobileAppDev.title1"),
      description1: t("servicesPageScreen.mobileAppDev.about1"),
      description2: t("servicesPageScreen.mobileAppDev.about2"),
      process_title:t("servicesPageScreen.mobileAppDev.processTitle"),
      headline: t("servicesPageScreen.mobileAppDev.headline"),
      subtext:t("servicesPageScreen.mobileAppDev.subtext"),
      more_services:t("servicesPageScreen.mobileAppDev.moreServices"),
      contact_box:t("servicesPageScreen.mobileAppDev.contactBox"),
      //OUR PROCESS SUBTITLES
      processes: [
        {
          name: t("servicesPageScreen.mobileAppDev.name1"),
          description: t("servicesPageScreen.mobileAppDev.description1"),
          icon: "search_icon"
        },
        {
          name: t("servicesPageScreen.mobileAppDev.name2"),
          description: t("servicesPageScreen.mobileAppDev.description2"),
          icon: "spanner"
        },
        {
          name: t("servicesPageScreen.mobileAppDev.name3"),
          description: t("servicesPageScreen.mobileAppDev.description3"),
          icon: "rocket"
        },
        {
          name: t("servicesPageScreen.mobileAppDev.name4"),
          description: t("servicesPageScreen.mobileAppDev.description4"),
          icon: "probe"
        },
      ],
      dominantColor: "#00E6BE",
    },
    react_development: {
      seo_title:`Custom React Development | INOWU`,
      seo_description:`The ReactJS library allows INOWU's experienced developers to build highly-interactive, attractive mobile assets. Get in touch with INOWU today to get started.`,
      main_title: t("servicesPageScreen.reactDev.titleMain"),
      main_description:t("servicesPageScreen.reactDev.titleBody"),
      title1:t("servicesPageScreen.reactDev.title1"),
      description1: t("servicesPageScreen.reactDev.about1"),
      description2: t("servicesPageScreen.reactDev.about2"),
      process_title:t("servicesPageScreen.reactDev.processTitle"),
      headline: t("servicesPageScreen.reactDev.headline"),
      subtext:t("servicesPageScreen.reactDev.subtext"),
      more_services:t("servicesPageScreen.reactDev.moreServices"),
      contact_box:t("servicesPageScreen.reactDev.contactBox"),
      //OUR PROCESS SUBTITLES
      processes: [
        {
          name: t("servicesPageScreen.reactDev.name1"),
          description: t("servicesPageScreen.reactDev.description1"),
          icon: "search_icon"
        },
        {
          name: t("servicesPageScreen.reactDev.name2"),
          description: t("servicesPageScreen.reactDev.description2"),
          icon: "paint_pad"
        },
        {
          name: t("servicesPageScreen.reactDev.name3"),
          description: t("servicesPageScreen.reactDev.description3"),
          icon: "development"
        },
        {
          name: t("servicesPageScreen.reactDev.name4"),
          description: t("servicesPageScreen.reactDev.description4"),
          icon: "check_arrow"
        },
        {
          name: t("servicesPageScreen.reactDev.name5"),
          description: t("servicesPageScreen.reactDev.description5"),
          icon: "probe"
        },
        {
          name: t("servicesPageScreen.reactDev.name6"),
          description: t("servicesPageScreen.reactDev.description6"),
          icon: "stars"
        },
      ],
      dominantColor: "#00E6BE",
    },
  };

  var { service } = useParams();
  service = service?.replace(/-/gim, "_");

  const services = [
    "custom_software_development",
    "website_development",
    "mobile_app_development",
    "react_development",
  ];

  const themeColor =
    service === undefined
      ? "#00e6be"
      : serviceMap[service].dominantColor;

  return (
    <div>
      {service === undefined || !services.includes(service) ? (
        <Navigate to={"/"} />
      ) : (
        <>
          <Header />
          <Helmet>
            <title>{serviceMap[service].seo_title}</title>
            <meta name="description" content={serviceMap[service].seo_description}></meta>
          </Helmet>

          <ImageHolder
            imageSrc={imgObj.bg.services_blur[service]}
            imageSize={
              responsive470
                ? ["auto", parseRem("720px")]
                : [
                    "clamp(1512px, 100vw, 100vw)",
                    `clamp(${parseRem("720px")}, 100%, 100%)`,
                  ]
            }
            imageLayer={"bottom"}
            canvasPosition={"bottom"}
            imagePosition={8}
          />

          <Breaker size={parseRem("235px")} />

          <TranslateButton />

          <Title
            head={serviceMap[service].main_title}
            styleTextOptions={{
              emphasisColor: themeColor,
            }}
            headColor="white"
            style={{
              maxWidth: "1000px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Breaker size={parseRem("87px")} />
          <Title
            align={responsive470 ? "center" : "center"}
            head={""}
            body={serviceMap[service].main_description}
            bodyColor={"white"}
            styleTextOptions={{ emphasisColor: "rgba(255, 255, 255, 0.8)" }}
          >
          </Title>
            
            <Breaker size={parseRem("40px")} />

            <ScrollButton
              toElement={scrollRef}
              backgroundColor={themeColor}
              textColor={"white"}
              imageSytle={{
                filter:
                  "invert(0%) sepia(90%) saturate(2000%) hue-rotate(186deg) brightness(200%) contrast(105%)",
              }}
            />
          </Title>

          <Breaker size={parseRem("102px")} />

          <ImageHolder
            imageSrc={imgObj.bg.project_circles}
            imageSize={[parseRem("936px"), parseRem("447px")]}
            imageLayer={"bottom"}
            canvasPosition={"top"}
            imagePosition={2}
          />

          <Marginalizer 
            size="1rem" style={{ backgroundColor: "white" }}>
            <Breaker size={parseRem("87px")} />
            <Subtitle
            head={serviceMap[service].title1}
            headStyle={{
              marginBottom: "0"
            }}
            styleTextOptions={{
              emphasisColor: themeColor,
            }}
            headColor="#003A54"
            style={{
              maxWidth: "900px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></Subtitle>
            <Subtitle
              head={""} 
              body={serviceMap[service].description1}
              style={{
                maxWidth: "1100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              styleTextOptions={{
                emphasisColor: themeColor,
              }}
            />
          <Breaker size={parseRem("32px")} />
          <Subtitle
            head={t("servicesPageScreen.subtitle")}
            styleTextOptions={{
              emphasisColor: themeColor,
            }}
            headColor="#003A54"
            style={{
              maxWidth: "900px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            
          </Subtitle>
            <Subtitle
              head={""} 
              headStyle={{
                marginBottom: "0"
              }}
              body={serviceMap[service].description2}         
              style={{
                maxWidth: "1100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              styleTextOptions={{
                emphasisColor: themeColor,
              }}
            />

            <div className="reference-object" ref={scrollRef}></div>

            <Breaker size={parseRem("87px")} />
            <Breaker
              size={
                responsive820
                  ? responsive470
                    ? parseRem("420px")
                    : parseRem("500px")
                  : parseRem("660px")
              }
            />
            
            <ImageHolder
                    imageSrc={imgObj.item.service_functionality[service!]}
                    imageSize={
                      responsive820
                        ? responsive470
                          ? ["auto", parseRem("400px")]
                          : ["auto", parseRem("480px")]
                        : ["auto", "auto"]
                    }
                    imageLayer={"top"}
                    imageStyle={{
                      bottom: "-1.5rem",
                    }}
                    canvasPosition={"bottom-in"}
                    canvasWidth={"100vw"}
                    imagePosition={8}
                    key={""}
              />
            <Subtitle
              head={serviceMap[service].process_title}
              headStyle={{
                marginBottom: "0"
              }}
              styleTextOptions={{
                emphasisColor: themeColor,
              }}
              headColor="#003A54"
              style={{
                maxWidth: "900px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            ></Subtitle>
            <Subtitle
              head={""}
              bodyStyle={{ position: "relative", top: "" }}
              align={"center"}
              style={{
                maxWidth: "1100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <ItemsList
            vertical={true}
            >
              {serviceMap[service].processes.map((info, index) => {
          return (
            <ItemsList.Item
              key={index}
            >
              <div 
              style={{
                textAlign:"center",
                maxWidth: "930px",
                /*marginLeft: "auto",
                marginRight: "auto",*/
              }} 
              >
                <div
                style={{
                  display:"flex",
                  flexDirection: "row",
                  justifyContent:"center",
                  /*marginLeft: "auto",
                  marginRight: "auto",*/
                }}  
                >
                <img
                  src={imgObj.icon[info.icon]}
                  alt = "img-inowu-4"
                  style={responsive1396 ? 
                    {height: "23px",
                    padding:"5px",
                    marginRight: "3px",}
                    :
                    {height: "100%",
                    padding:"8px",
                    marginRight: "7px",}
                  }
                />
                <p style={{ fontSize: parseRem("24px")}}>
                  {styleText({ text: info.name })}
                </p>
                </div>
                  <p style={{ fontSize: parseRem("24px") }}>
                    {styleText({ text: info.description })}
                  </p>
              </div>
            </ItemsList.Item>
          );
        })}
            </ItemsList>
            <Breaker size={parseRem("87px")} />

            <Marginalizer
              size={
                responsive470
                  ? "0px" 
                  : responsive770
                  ? parseRem("50px")
                  : parseRem("112px")
              }
            >
            </Marginalizer>

            <Breaker size={parseRem("0px")} />
            <Marginalizer
              size={
                responsive470
                  ? "1rem"
                  : responsive770
                  ? parseRem("50px")
                  : parseRem("112px")
              }
            >
              <div
                style={{
                  background:
                    "linear-gradient(94.92deg, #003A54 13.01%, #052939 94.33%)",
                  borderRadius: parseRem("16px"),
                  whiteSpace: "pre-line",
                  height: parseRem("373px"),
                  display: "flow-root",
                }}
              >
                {responsive920 ? (
                  <></>
                ) : (
                  <ImageHolder
                    imageSrc={imgObj.item.band_image}
                    imageLayer={"top"}
                    imageSize={[parseRem("668px"), parseRem("398px")]}
                    canvasPosition={"top-in"}
                    canvasStyle={{
                      top: parseRem("-25px"),
                      borderRadius: parseRem("16px"),
                    }}
                    imageStyle={responsive1396 ? { opacity: "0.6" } : undefined}
                    imagePosition={3}
                  />
                )}
                <div
                  style={{
                    marginTop: parseRem("45px"),
                    marginLeft: parseRem("50px"),
                    zIndex: "6",
                    position: "relative",
                  }}
                >
                  <h2
                    style={{ color: "white", marginBottom: parseRem("12px") }}
                  >
                    {styleText({ text: serviceMap[service].headline })}
                  </h2>

                  <p style={{ color: "white", marginBottom: parseRem("22px") }}
                  >
                    {styleText({ text: serviceMap[service].subtext, format: true })}
                  </p>

                  <a
                    className="custom-link pp increase as-p"
                    href="/contact"
                    style={{
                      paddingLeft: "0px",
                      borderBottomColor: themeColor,
                    }}
                  >
                    {styleText({
                      text: t("servicesPageScreen.projectLink"),
                      emphasisColor: "white",
                    })}
                  </a>
                </div>
              </div>
            </Marginalizer>

            <Breaker size={parseRem("88px")} />

            <Subtitle
              head={serviceMap[service].more_services}
              style={{
                paddingLeft: "5%",
                borderBottomColor: themeColor,
              }}
              align={"left"}
            />

            <Breaker size={parseRem("48px")} />

            <Marginalizer size={"5vw"} responsive={false}>
              <ItemsList
                slider
                sliderSettings={{
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerMode: false,
                  responsive: [
                    {
                      breakpoint: 1064,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 770,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                      },
                    },
                    {
                      breakpoint: 420,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        canterMode: true,
                        infinite: true,
                        autoplay: true,
                      },
                    },
                  ],
                }}
              >
                {shuffleArray(servicesInfo)
                  .filter((serv) => {
                    return serv.name !== service;
                  })
                  .map((serv, index) => {
                    const remm = parseRem("20px");

                    return (
                      <ItemsList.Item margin={false} key={index}>
                        <div
                          style={{
                            width: parseRem("525px"),
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: parseRem("51px"),
                            marginLeft:
                              index !== 0 && !responsive470 ? remm : undefined,
                            marginRight:
                              index !== 7 && !responsive470 ? remm : undefined,
                          }}
                        >
                          <a href={`/services/${serv.url}`}>
                            <img
                              src={imgObj.bg.services[serv.name]}
                              alt="project"
                              style={{
                                width: "100%",
                                height: parseRem("270px"),
                                borderRadius: "10px",
                                border: "1px solid #EDEDED",
                              }}
                            />
                          </a>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: parseRem("24px"),
                              marginRight: parseRem("24px"),
                            }}
                          >
                            <span
                              className="as-p"
                              style={{
                                marginTop: parseRem("24px"),
                                marginBottom: parseRem("10px", "4px"),
                                color: "initial",
                              }}
                            >
                              {styleText({
                                text: serv.head,
                                linkList: [`/services/${serv.url}`],
                                linkStyles: [""],
                              })}
                            </span>

                            <span
                              className="as-p"
                              style={{
                                marginBottom: parseRem("15px", "7px"),
                              }}
                            >
                              {suspendText(
                                formatText({ text: serv.body }),
                                111
                              )}
                            </span>

                            <a
                              className={"custom-link pp grow right"}
                              href={`/services/${serv.url}`}
                              style={{
                                fontWeight: 700,
                                fontSize: parseRem("24px"),
                                alignSelf: "flex-start",
                                color: themeColor,
                                borderBottomColor: themeColor,
                              }}
                            >
                              {t("servicesScreen.servicesLink")}
                            </a>
                          </div>
                        </div>
                      </ItemsList.Item>
                    );
                  })}
              </ItemsList>
            </Marginalizer>
          </Marginalizer>

          <Breaker
            size={parseRem("93px")}
            style={{ backgroundColor: "white" }}
          />
          <Breaker
        size={parseRem("156px")}
        background={imgObj.item.circles}
        backgroundStyle={{
          width: parseRem("343px"),
          height: parseRem("343px"),
          left: parseRem("-112px"),
          top: parseRem("-153px"),
        }}
      />

      <Title
        head={serviceMap[service].contact_box}
        styleTextOptions={{
          linkList: ["/contact"],
          fontSizeList: [parseRem("54px")],
        }}
      />
        </>
      )}
      <Footer breakerSize={parseRem("141px")} />
    </div>
  );
};

export default ServicePageScreen;
