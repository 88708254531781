import Breaker from "@components/Breaker";
import Footer from "@components/Footer";
import ItemsList from "@components/ItemsList";
import {
  formatText,
  parseRem,
  styleText,
  suspendText,
} from "utils/FunctionsBundle";
import { imgObj } from "utils/ImageRepo";
import Header from "@components/Header";
import "@theme/styles.scss";
import ImageHolder from "@components/ImageHolder";
import { Helmet } from "react-helmet";
import Title from "@components/Title";
import TranslateButton from "@components/TranslateButton";
import { useTranslation } from "react-i18next";

const PortfolioScreen = () => {
  const { t } = useTranslation();
  const projectsInfo = [
    // {
    //   name: "bullground",
    //   url: "bullground",
    //   head: t("portfolioScreen.bullground.head"),
    //   body: t("portfolioScreen.bullground.body"),
    // },
    {
      name: "wmobil",
      url: "wmobil",
      head: t("portfolioScreen.wmobil.head"),
      body: t("portfolioScreen.wmobil.body"),
    },
    {
      name: "medmex",
      url: "medmex",
      head: t("portfolioScreen.medmex.head"),
      body: t("portfolioScreen.medmex.body"),
    },
    {
      name: "gm_eshop",
      url: "gm-eshop",
      head: t("portfolioScreen.gmEshop.head"),
      body: t("portfolioScreen.gmEshop.body"),
    },
    {
      name: "cadefi",
      url: "cadefi",
      head: t("portfolioScreen.cadefi.head"),
      body: t("portfolioScreen.cadefi.body"),
    },
    {
      name: "lg_autopartes",
      url: "lg-autopartes",
      head: t("portfolioScreen.lgAutopartes.head"),
      body: t("portfolioScreen.lgAutopartes.body"),
    },
    // {
    //   name: "academia_de_lenguas",
    //   url: "academia-de-lenguas",
    //   head: t("portfolioScreen.academiaLenguas.head"),
    //   body: t("portfolioScreen.academiaLenguas.body"),
    // },
    // {
    //   name: "cienpotencia",
    //   url: "cienpotencia",
    //   head: t("portfolioScreen.cienpotencia.head"),
    //   body: t("portfolioScreen.cienpotencia.body"),
    // },
  ];

  const outsourcingInfo = [
    {
      name: "autio",
      url: `https://autio.com/`,
      head: t("portfolioScreen.autio.head"),
      body: t("portfolioScreen.autio.body"),
    },
    {
      name: "herdx",
      url: `https://www.herdx.com/`,
      head: t("portfolioScreen.herdx.head"),
      body: t("portfolioScreen.herdx.body"),
    },
    {
      name: "atease",
      url: `https://videoremixes.netlify.app/trends`,
      head: t("portfolioScreen.atease.head"),
      body: t("portfolioScreen.atease.body"),
    },
    {
      name: "neta",
      url: `https://tonetip.com/`,
      head: t("portfolioScreen.neta.head"),
      body: t("portfolioScreen.neta.body"),
    },
  ];
  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Our Best Work | INOWU Development</title>
        <meta name="description" content="Using our expertise, we've helped many companies with their development needs, from 
        software development, to app development, website development, 
        and more."></meta>
      </Helmet>
      <Header transition={false} />

      <Breaker size={parseRem("152px")} />

      <Title head={t("portfolioScreen.titleMain")} />

      <TranslateButton />

      <Breaker size={parseRem("125px")} />
      <ItemsList>
        {outsourcingInfo.map((proj, index) => {
          return (

            <div
              style={{
                width: parseRem("637px"),
                display: "flex",
                flexDirection: "column",
                marginBottom: parseRem("51px"),
              }}
              key={index}
            >
              <a
                href={proj.url}
                target={"_blank"}
                rel="noreferrer"
                style={{ position: "relative" }}
              >
                <a
                  className="outSourcingRibbon"
                  href={proj.url}
                  target={"_blank"}
                  rel="noreferrer"
                >{t("portfolioScreen.ribbon")}
                </a>

                <ImageHolder
                  imageSrc={imgObj.logo[`${proj.name}_white`]}
                  canvasPosition={"top-in"}
                  canvasHeight={"100%"}
                  imageLayer={"top"}
                  imagePosition={5}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                />
                <img
                  src={imgObj.bg.projects[proj.name]}
                  alt="project"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                    border: "1px solid #EDEDED",
                  }}
                />
              </a>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: parseRem("24px"),
                  marginRight: parseRem("24px"),
                }}
              >
                <span
                  className="as-p"
                  style={{
                    marginTop: parseRem("24px"),
                    marginBottom: parseRem("10px", "4px"),
                    color: "initial",
                  }}
                >
                  {styleText({
                    text: proj.head,
                    linkList: [proj.url],
                    linkStyles: [""],
                  })}
                </span>

                <span
                  className="as-p"
                  style={{
                    marginBottom: parseRem("15px", "7px"),
                  }}
                >
                  {suspendText(formatText({ text: proj.body }), 126)}
                </span>

                <a
                  className={"custom-link pp grow right"}
                  href={proj.url}
                  target={"_blank"}
                  rel="noreferrer"
                  style={{
                    fontWeight: 700,
                    fontSize: parseRem("24px"),
                    alignSelf: "flex-start",
                    color: "#003a54",
                    borderBottomColor: "#003a54",
                  }}
                >
                  {t("servicesScreen.portfolioLink")}
                </a>
              </div>
            </div>
          );
        })}
      </ItemsList>

      <ItemsList>
        {projectsInfo.map((proj, index) => {
          return (

            <div
              style={{
                width: parseRem("637px"),
                display: "flex",
                flexDirection: "column",
                marginBottom: parseRem("51px"),
              }}
              key={index}
            >
              <a
                href={`/portfolio/${proj.url}`}
                style={{ position: "relative" }}
              >
                <a
                  className="outSourcingRibbon"
                  style={{ backgroundColor: "#49d198" }}
                  href={`/portfolio/${proj.url}`}
                >{t("portfolioScreen.ribbon_proj")}
                </a>
                <ImageHolder
                  imageSrc={imgObj.logo[`${proj.name}_white`]}
                  canvasPosition={"top-in"}
                  canvasHeight={"100%"}
                  imageLayer={"top"}
                  imagePosition={5}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                />
                <img
                  src={imgObj.bg.projects[proj.name]}
                  alt="project"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                    border: "1px solid #EDEDED",
                  }}
                />
              </a>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: parseRem("24px"),
                  marginRight: parseRem("24px"),
                }}
              >
                <span
                  className="as-p"
                  style={{
                    marginTop: parseRem("24px"),
                    marginBottom: parseRem("10px", "4px"),
                    color: "initial",
                  }}
                >
                  {styleText({
                    text: proj.head,
                    linkList: [`/portfolio/${proj.url}`],
                    linkStyles: [""],
                  })}
                </span>

                <span
                  className="as-p"
                  style={{
                    marginBottom: parseRem("15px", "7px"),
                  }}
                >
                  {suspendText(formatText({ text: proj.body }), 126)}
                </span>

                <a
                  className={"custom-link pp grow right"}
                  href={`/portfolio/${proj.url}`}
                  style={{
                    fontWeight: 700,
                    fontSize: parseRem("24px"),
                    alignSelf: "flex-start",
                  }}
                >
                  {t("servicesScreen.servicesLink")}
                </a>
              </div>
            </div>
          );
        })}
      </ItemsList>

      <Footer breakerSize={parseRem("120px")} />
    </div>
  );
};

export default PortfolioScreen;
