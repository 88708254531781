import "@theme/styles.scss";
import React, { CSSProperties } from "react";

// ############################# INTERFACES & PROPS #############################
// REACTIVE CONTAINER #############################
interface ReactiveContainerProps {
  style?: CSSProperties;
  index?: number;
}

const ReactiveContainerDefaultProps: ReactiveContainerProps = {
  index: 0,
};

// ############################# FUNCTION COMPONENTS #############################
// REACTIVE CONTAINER #############################
const ReactiveContainer: React.FunctionComponent<ReactiveContainerProps> = (
  props
) => {
  return (
    <div className="reactive-container">
      {React.Children.map(props.children, (child, index) => {
        return (
          <div
            className="reactive-container-object"
            style={props.index === index ? { opacity: 1 } : { opacity: 0 }}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

// ############################# EXPORTS #############################
ReactiveContainer.defaultProps = ReactiveContainerDefaultProps;

export default ReactiveContainer;
