import { t } from "i18next";
import { CSSProperties, useEffect, useState } from "react";

//###############################################################################################################
//###############################################################################################################

export interface formatTextProps {
  text?: string;
  newLine?: string; // = ";;",
  translate?: boolean; // = true
}

/**
 * Formats a text correctly in the sense of spacing and line-breaking.
 * It also replaces any given text string with a line-break of better manipulation.
 * (By line-break we refer to the '\n' character with the 'ENTER' key)
 *
 * @param text string of any type without a correct format (e.g. many spaces or line-breaks).
 * @param newLine (optional) A string value to search for and replace with a line-break, functioning like a manual line-break.
 * (By default: ';;') If an empty string is given <""> it won't add any line-break at all.
 * @param attemptTranslation (Boolean optional) Default: `true`, whether or not interpretate the given string as a `i18next` key for translation
 * @returns A `string` limited with one space between each word, with all line-breaks removed and any replaced.
 */
export function formatText(props: formatTextProps) {
  if (props.text === undefined) return "";

  var replaceAllAllowed = true;
  try {
    "string".replaceAll("s", "t");
    // console.log("allowed");
  } catch (error) {
    replaceAllAllowed = false;
    // console.log("not allowed");
  }

  props.newLine = props.newLine ? props.newLine : ";;";
  props.translate = props.translate !== undefined ? props.translate : false; // TRANSLATION DEACTIVATED

  if (props.translate) props.text = t(props.text);

  props.text = props.text!.trim(); // Remove white spaces
  if (replaceAllAllowed) props.text = props.text.replaceAll("\n", " ");
  // Replace break-lines
  else props.text = props.text.replace(/\n+/gm, " ");
  const textArray = props.text.split(" ").filter((t) => t !== ""); // Separate valid text strings

  var newString = "";

  for (let i = 0; i < textArray.length; i++) {
    newString += textArray[i];

    if (
      i !== textArray.length - 1 &&
      textArray[i + 1] !== props.newLine &&
      textArray[i] !== props.newLine
    )
      newString += " ";
  }

  if (replaceAllAllowed) return newString.replaceAll(props.newLine!, "\n");
  // Replace break-lines
  else return newString.replace(new RegExp(`${props.newLine}`, "gm"), "\n");
}

//###############################################################################################################
//###############################################################################################################

export const parseRem = (value: string | number, min?: string | number) => {
  const number = Number.parseFloat(value.toString());
  if (isNaN(number)) return value.toString();
  const unit = value.toString().split(number.toString()).splice(-1)[0];
  var rem: number;

  switch (unit) {
    case "px":
      rem = number / 16;
      break;

    case "":
      rem = number / 16;
      break;

    case "rem":
      rem = number;
      break;

    default:
      rem = number;
      break;
  }

  // 1536px -> value
  // window.screen.width -> resp

  // return (
  //   Math.max(
  //     (window.screen.width * number) / 1536,
  //     Number.parseFloat((min || "0").toString())
  //   ) + "px"
  // );

  if (min === undefined) return rem + "rem";

  const min_rem = parseRem(min);
  const min_number = Number.parseFloat(min_rem);

  return Math.max(min_number, rem) + "rem";
};

//###############################################################################################################
//###############################################################################################################
export interface styleTextOptions {
  text?: string;
  boldWrap?: string;
  italicsWrap?: string;
  crossWrap?: string;
  muteWrap?: string;
  underlineWrap?: string;
  emphasisWrap?: string;
  emphasisColor?: string;
  linkWrap?: string;
  linkList?: Array<string>;
  linkStyles?: Array<string>;
  fontSizeWrap?: string;
  fontSizeList?: Array<string>;
  fontSizeStyles?: Array<CSSProperties>;
  format?: boolean;
  formatNewLine?: string;
  formatTranslate?: boolean;
}

export function styleText(props: styleTextOptions) {
  if (props.text === undefined) return <></>;

  if (props.format !== false)
    props.text = formatText({
      text: props.text,
      translate: props.formatTranslate,
      newLine: props.formatNewLine,
    });

  const options = {
    boldWrap: props.boldWrap ? props.boldWrap : "*",
    italicsWrap: props.italicsWrap ? props.italicsWrap : "_",
    crossWrap: props.crossWrap ? props.crossWrap : "~",
    muteWrap: props.muteWrap ? props.muteWrap : "#",
    underlineWrap: props.underlineWrap ? props.underlineWrap : "&",
    emphasisWrap: props.emphasisWrap ? props.emphasisWrap : "$",
    emphasisColor: props.emphasisColor ? props.emphasisColor : "#00e6be", // rgb(0, 205, 175)
    linkWrap: props.linkWrap ? props.linkWrap : "=",
    linkList: props.linkList ? props.linkList : ["/"],
    linkStyles: props.linkStyles
      ? props.linkStyles
      : ["custom-link pp grow both"],
    fontSizeWrap: props.fontSizeWrap ? props.fontSizeWrap : "^",
    fontSizeList: props.fontSizeList ? props.fontSizeList : [""],
    fontSizeStyles: props.fontSizeStyles ? props.fontSizeStyles : [{}],
  };

  const fields = [
    "bold",
    "italics",
    "cross",
    "mute",
    "underline",
    "emphasis",
    "link",
    "fontSize",
  ];

  const refill = {
    bold: ["<span style='font-weight: 800;'>", "</span>"],
    italics: ["<span style='font-style: italic;'>", "</span>"],
    cross: ["<span style='text-decoration: line-through;'>", "</span>"],
    mute: ["<span style='opacity: 40%;'>", "</span>"],
    underline: ["<span style='text-decoration: underline'>", "</span>"],
    emphasis: [`<span style='color: ${options.emphasisColor}'>`, "</span>"],
    link: [
      `<a class=${options.linkStyles[0]} href=${options.linkList[0]} target={"_blank"}> `,
      "</a>",
    ],
    fontSize: [
      `<span style='font-size: ${options.fontSizeList[0]}'>`,
      "</span>",
    ],
  };

  const regEx = {};

  for (let i = 0; i < fields.length; i++) {
    try {
      regEx[fields[i]] = new RegExp(
        "(?<!\\\\)\\" + options[`${fields[i]}Wrap`].replace(/./gim, "$&"),
        "gim"
      );
    } catch (e) {
      console.log("regexp not supported");
      regEx[fields[i]] = new RegExp(
        "\\" + options[`${fields[i]}Wrap`].replace(/./gim, "$&"),
        "gim"
      );
    }
  }

  const sections = {};

  for (let i = 0; i < fields.length; i++) {
    sections[fields[i]] = new Array<Number>();
  }

  for (let i = 0; i < fields.length; i++) {
    while (true) {
      var match = regEx[fields[i]].exec(props.text);
      if (match === null) break;

      sections[fields[i]].push(match.index);
    }
  }

  const process = new Array<Array<String | Number>>();

  for (let i = 0; i < fields.length; i++) {
    for (let j = 0; j < sections[fields[i]].length; j += 2) {
      const pos1 = sections[fields[i]][j];
      const pos2 = sections[fields[i]][j + 1];

      if (pos2 !== undefined) {
        process[pos1] = [`${fields[i]}`, 0];
        process[pos2] = [`${fields[i]}`, 1];
      }
    }
  }

  var newText = "";
  var prevCut = 0;
  var lastLink = 0;
  var lastStyle = 0;

  var lastfontSize = 0;

  for (let i = 0; i < process.length; i++) {
    if (process[i] !== undefined) {
      // Si se va a insertar un link, incrustar la url correspondiente
      if (process[i][0] === "link" && process[i][1] === 0) {
        newText +=
          props.text.slice(prevCut, i) +
          `<a class='${options.linkStyles[lastStyle]}' href=${options.linkList[lastLink]} target={"_blank"}>`;

        if (options.linkList[lastLink + 1] !== undefined) lastLink++;
        if (options.linkStyles[lastStyle + 1] !== undefined) lastStyle++;
      } else if (process[i][0] === "fontSize" && process[i][1] === 0) {
        newText +=
          props.text.slice(prevCut, i) +
          `<span style='font-size: ${options.fontSizeList[lastfontSize]}'>`;

        if (options.fontSizeList[lastfontSize + 1] !== undefined)
          lastfontSize++;
      } else {
        newText +=
          props.text.slice(prevCut, i) +
          refill[process[i][0].toString()][process[i][1]];
      }
      prevCut = i + 1;
    }
  }
  newText += props.text.slice(prevCut);

  return <span dangerouslySetInnerHTML={{ __html: newText }} />;
}

//###############################################################################################################
//###############################################################################################################

// Hook para conocer si un elemento está a la vista (en pantalla)
export function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    var observer = {
      disconnect: () => {},
      observe: (_) => {},
    };

    try {
      observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
      observer.observe(ref.current);
    } catch (e) {
      setIntersecting(true);
    }

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

//###############################################################################################################
//###############################################################################################################

/**
 * Regresa un `number` random de entre el rango de valores que se presenten
 * @param _min Inicio del rango (inclusivo)
 * @param _max Final del regno (inclusivo)
 * @returns Un `int` random
 */
export function randomRange(_min: number, _max: number) {
  var _diff = _max - _min;
  return Math.round(_min + _diff * Math.random());
}

//###############################################################################################################
//###############################################################################################################

/**
 * Representa límites de un rango de números
 * @param _min El mínimo valor del número
 * @param _value El número a evaluar
 * @param _max El máximo valor del número
 * @param _wrap En caso de desborde, si se regresa al otro lado del rango
 * @returns Un número correspondiente
 */
export function boundaries(
  _min: number,
  _value: number,
  _max?: number,
  _wrap?: boolean
) {
  const limMax = _max !== undefined ? _max : _value;

  if (!_wrap) {
    if (_value < _min) return _min;
    if (_value > limMax) return limMax;
    return _value;
  }

  if (_value < _min) return limMax;
  if (_value > limMax) return _min;
  return _value;
}

//###############################################################################################################
//###############################################################################################################

/**
 * Se acerca paso a paso desde el inicio, hasta el final, con una cantidad determinada
 * @param _start Valor del cual partir
 * @param _end Valor al cual terminar
 * @param _amount Cantidad del paso a dar
 * @returns Un nuevo valor desde el `_start` con el paso añadido
 */
export function approach(_start: number, _end: number, _amount: number) {
  if (_start < _end) return Math.min(_start + _amount, _end);
  else return Math.max(_start - _amount, _end);
}

// experimental
export function useDocumentHeight() {
  const [height, setHeight] = useState(document.body.scrollHeight);

  document.addEventListener("DOMContentLoaded", () => {
    setHeight(document.body.scrollHeight);
    console.log("done");

    window.addEventListener("resize", () => {
      setHeight(document.body.scrollHeight);
      console.log("done 2", document.body.scrollHeight);
    });
  });

  return height;
}

//###############################################################################################################
//###############################################################################################################

// Sumamente no optimizado
// Hook para conocer las coordenadas de un elemento
export function useGetCoords(ref: any) {
  const [coords, setCoords] = useState({
    offsetTop: 0,
  });

  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.offsetTop);

      setCoords({
        offsetTop: ref.current.offsetTop,
      });
    }
  }, [ref]);

  window.addEventListener("resize", () => {
    console.log("resized");
    if (ref.current && ref.current.offsetTop !== coords.offsetTop) {
      console.log(ref.current.offsetTop);

      setCoords({
        offsetTop: ref.current.offsetTop,
      });
    }
  });

  return coords;
}

//###############################################################################################################
//###############################################################################################################

/**
 * Fisher-Yates Shuffle. Shuffles content inside an array. Courtsey of Mike Bostock.
 */
export function shuffle(array: any[]) {
  var currentIndex = array.length;
  var randomIndex: number;
  const newArray = array;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ];
  }

  return array;
}

export function shuffleArray(array: any[]) {
  const newArray = [...array];
  for (var i = newArray.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = temp;
  }
  return newArray;
}

//###############################################################################################################
//###############################################################################################################

export function suspendText(
  text: string,
  x?: string[] | string | number,
  toStringLen?: "min" | "max"
) {
  var finalLen: number;
  if (toStringLen === undefined) toStringLen = "min";

  if (x === undefined) {
    finalLen = text.length;
  } else if (typeof x === "number") {
    finalLen = x;
  } else if (typeof x === "string") {
    finalLen = x.length;
  } else if (x[0] !== undefined) {
    finalLen = x[0].length;

    x.forEach((str, index) => {
      if (toStringLen === "min") {
        if (str.length < finalLen) finalLen = str.length;
      } else {
        if (str.length > finalLen) finalLen = str.length;
      }
    });
  } else {
    return `${text}...`;
  }

  var croppedText = text.slice(0, finalLen - 3); //+ "...";

  return croppedText;
}
