import ProjectScreen from "@screens/ProjectScreen";

function PortfolioProjectContainer() {
  return (
    <>
      <ProjectScreen />
    </>
  );
}

export default PortfolioProjectContainer;
