import bg_about from "@theme/images/bg/about.png";
import bg_about_float from "@theme/images/bg/about_float.png";
import bg_project_academia_de_lenguas from "@theme/images/bg/project_academia_de_lenguas.png";
import bg_project_academia_de_lenguas_blur from "@theme/images/bg/project_academia_de_lenguas_blur.png";
import bg_project_bullground from "@theme/images/bg/project_bullground.png";
import bg_services_custom_software_development from "@theme/images/bg/services_custom_software_development.png";
import bg_services_custom_software_development_blur from "@theme/images/bg/services_custom_software_development_blur.png";
import bg_services_website_development from "@theme/images/bg/services_website_development.png";
import bg_services_website_development_blur from "@theme/images/bg/services_website_development_blur.png";
import bg_services_mobile_app_development from "@theme/images/bg/services_mobile_app_development.png";
import bg_services_mobile_app_development_blur from "@theme/images/bg/services_mobile_app_development_blur.png";
import bg_services_react_development from "@theme/images/bg/services_react_development.png";
import bg_services_react_development_blur from "@theme/images/bg/services_react_development_blur.png";
import bg_project_bullground_blur from "@theme/images/bg/project_bullground_blur.png";
import bg_project_cadefi from "@theme/images/bg/project_cadefi.png";
import bg_project_cadefi_blur from "@theme/images/bg/project_cadefi_blur.png";
import bg_project_cienpotencia from "@theme/images/bg/project_cienpotencia.png";
import bg_project_cienpotencia_blur from "@theme/images/bg/project_cienpotencia_blur.png";
import bg_project_gm_eshop from "@theme/images/bg/project_gm_eshop.png";
import bg_project_gm_eshop_blur from "@theme/images/bg/project_gm_eshop_blur.png";
import bg_project_lg_autopartes from "@theme/images/bg/project_lg_autopartes.png";
import bg_project_lg_autopartes_blur from "@theme/images/bg/project_lg_autopartes_blur.png";
import bg_project_medmex from "@theme/images/bg/project_medmex.png";
import bg_project_medmex_blur from "@theme/images/bg/project_medmex_blur.png";
import bg_project_wmobil from "@theme/images/bg/project_wmobil.png";
import bg_project_wmobil_blur from "@theme/images/bg/project_wmobil_blur.png";
import bg_project_circles from "@theme/images/bg/project_circles.png";
import autio_bg from "@theme/images/bg/autio_bg.png";
import atease_bg from "@theme/images/bg/atease_bg.png";
import herdx_bg from "@theme/images/bg/herdx_bg.png";
import neta from "@theme/images/bg/neta.png";

import bg_trans_white_in from "@theme/images/bg_trans/white_in.png";
import bg_trans_white_out from "@theme/images/bg_trans/white_out.png";

import icon_arrow_down from "@theme/images/icon/arrow_down.png";
import icon_clutch_hollow from "@theme/images/icon/clutch_hollow.png";
// import icon_facebook_hollow from "@theme/images/icon/facebook_hollow.png";
import icon_facebook_inside from "@theme/images/icon/facebook_inside.png";
import icon_instagram_inside from "@theme/images/icon/instagram_inside.png";
import icon_linkedin_hollow from "@theme/images/icon/linkedin_hollow.png";
import icon_linkedin_inside from "@theme/images/icon/linkedin_inside.png";
import icon_translate from "@theme/images/icon/translate.png";

import item_band_image from "@theme/images/item/band_image.png";
import item_circles from "@theme/images/item/circles.svg";

import item_displayer_bullground from "@theme/images/item/displayer_bullground.png";
import item_displayer_wmobil from "@theme/images/item/displayer_wmobil.png";
import item_displayer_medmex from "@theme/images/item/displayer_medmex.png";
import item_displayer_cienpotencia from "@theme/images/item/displayer_lenguas.png";
import item_displayer_cadefi from "@theme/images/item/displayer_cadefi.png";
import item_displayer_gm_eshop from "@theme/images/item/displayer_gm_eshop.png";

import item_imgcollection_01 from "@theme/images/item/imgcollection_01.png";
import item_imgcollection_02 from "@theme/images/item/imgcollection_02.png";
import item_imgcollection_03 from "@theme/images/item/imgcollection_03.png";
import item_portfolio01 from "@theme/images/item/portfolio01.png"; // temp
import item_portfolio02 from "@theme/images/item/portfolio02.png"; // temp

// ################# SERVICE FUNCTIONALITIES
import item_service_custom_software_development_0 from "@theme/images/item/item_service_custom_software_development_0.png";
import service_website_development from "@theme/images/item/service_website_development.png";
import service_custom_mobile_app_development from "@theme/images/item/service_custom_mobile_app_development.png";
import service_development_reactjs from "@theme/images/item/service_development_reactjs.png";

// ################# SERVICE ICONS
import icon_service_search_vector from "@theme/images/icon/icon_service_search_vector.svg";
import icon_paint_pad_vector from "@theme/images/icon/icon_paint_pad_vector.svg";
import icon_development_vector from "@theme/images/icon/icon_development_vector.svg";
import icon_rocket_vector from "@theme/images/icon/icon_rocket_vector.svg";
import icon_can_vector from "@theme/images/icon/icon_can_vector.svg";
import icon_check_arrow_vector from "@theme/images/icon/icon_check_arrow_vector.svg";
import icon_cloud_vector from "@theme/images/icon/icon_cloud_vector.svg";
import icon_probe_vector from "@theme/images/icon/icon_probe_vector.svg";
import icon_spanner_vector from "@theme/images/icon/icon_spanner_vector.svg";
import icon_stars_vector from "@theme/images/icon/icon_stars_vector.svg";


// ################# FLAGS
import us_flag from "@theme/images/icon/us_flag.png";
import mx_flag from "@theme/images/icon/mx_flag.png";

// ################# PROJECT FUNCTIONALITIES
import item_project_bullground_0 from "@theme/images/item/project_bullground_0.png";
import item_project_bullground_1 from "@theme/images/item/project_bullground_1.png";
import item_project_bullground_2 from "@theme/images/item/project_bullground_2.png";
import item_project_bullground_3 from "@theme/images/item/project_bullground_3.png";
import item_project_bullground_4 from "@theme/images/item/project_bullground_4.png";
import item_project_bullground_5 from "@theme/images/item/project_bullground_5.png";
import item_project_bullground_6 from "@theme/images/item/project_bullground_6.png";
import item_project_bullground_7 from "@theme/images/item/project_bullground_7.png";
import item_project_bullground_8 from "@theme/images/item/project_bullground_8.png";
import item_project_bullground_9 from "@theme/images/item/project_bullground_9.png";

import item_project_medmex_0 from "@theme/images/item/project_medmex_0.png";
import item_project_medmex_1 from "@theme/images/item/project_medmex_1.png";
import item_project_medmex_2 from "@theme/images/item/project_medmex_2.png";
import item_project_medmex_3 from "@theme/images/item/project_medmex_3.png";

import item_project_lg_autopartes_0 from "@theme/images/item/project_lg_autopartes_0.png";
import item_project_lg_autopartes_1 from "@theme/images/item/project_lg_autopartes_1.png";
import item_project_lg_autopartes_2 from "@theme/images/item/project_lg_autopartes_2.png";
import item_project_lg_autopartes_3 from "@theme/images/item/project_lg_autopartes_3.png";
import item_project_lg_autopartes_4 from "@theme/images/item/project_lg_autopartes_4.png";

import item_project_gm_eshop_0 from "@theme/images/item/project_gm_eshop_0.png";
import item_project_gm_eshop_1 from "@theme/images/item/project_gm_eshop_1.png";
import item_project_gm_eshop_2 from "@theme/images/item/project_gm_eshop_2.png";
import item_project_gm_eshop_3 from "@theme/images/item/project_gm_eshop_3.png";

import item_project_cadefi_0 from "@theme/images/item/project_cadefi_0.png";
import item_project_cadefi_1 from "@theme/images/item/project_cadefi_1.png";
import item_project_cadefi_2 from "@theme/images/item/project_cadefi_2.png";
import item_project_cadefi_3 from "@theme/images/item/project_cadefi_3.png";

import item_project_cienpotencia_0 from "@theme/images/item/project_cienpotencia_0.png";
import item_project_cienpotencia_1 from "@theme/images/item/project_cienpotencia_1.png";
import item_project_cienpotencia_2 from "@theme/images/item/project_cienpotencia_2.png";

import item_project_academia_de_lenguas_0 from "@theme/images/item/project_academia_de_lenguas_0.png";
import item_project_academia_de_lenguas_1 from "@theme/images/item/project_academia_de_lenguas_1.png";
import item_project_academia_de_lenguas_2 from "@theme/images/item/project_academia_de_lenguas_2.png";
import item_project_academia_de_lenguas_3 from "@theme/images/item/project_academia_de_lenguas_3.png";

import item_project_wmobil_0 from "@theme/images/item/project_wmobil_0.png";
import item_project_wmobil_1 from "@theme/images/item/project_wmobil_1.png";
import item_project_wmobil_2 from "@theme/images/item/project_wmobil_2.png";
import item_project_wmobil_3 from "@theme/images/item/project_wmobil_3.png";
import item_project_wmobil_4 from "@theme/images/item/project_wmobil_4.png";
import item_project_wmobil_5 from "@theme/images/item/project_wmobil_5.png";
import item_project_wmobil_6 from "@theme/images/item/project_wmobil_6.png";

import logo_academia_de_lenguas_white from "@theme/images/logo/academia_de_lenguas_white.png";
import logo_bullground from "@theme/images/logo/bullground.png";
import logo_bullground_white from "@theme/images/logo/bullground_white.png";
import logo_cadefi from "@theme/images/logo/cadefi.svg";
import logo_cadefi_white from "@theme/images/logo/cadefi_white.png";
import logo_cienpotencia from "@theme/images/logo/cienpotencia.svg";
import logo_cienpotencia_white from "@theme/images/logo/cienpotencia_white.png";
import logo_direxio from "@theme/images/logo/direxio.svg";
import logo_gm_eshop from "@theme/images/logo/gm_eshop.svg";
import logo_gm_eshop_white from "@theme/images/logo/gm_eshop_white.png";
import logo_lg_autopartes from "@theme/images/logo/lg_autopartes.png";
import logo_lg_autopartes_white from "@theme/images/logo/lg_autopartes_white.png";
import logo_medmex_white from "@theme/images/logo/medmex_white.png";
import logo_myinfo from "@theme/images/logo/myinfo.png";
import logo_inowu_gradient from "@theme/images/logo/inowu_gradient.png";
import logo_inowu_white from "@theme/images/logo/inowu_white.png";
import logo_sunum from "@theme/images/logo/sunum.png";
import logo_wmobil_white from "@theme/images/logo/wmobil_white.png";
import logo_autio_white from "@theme/images/logo/autio.png";
import logo_herdx_white from "@theme/images/logo/herdx.png";
import logo_atease_white from "@theme/images/logo/atease.png";

export const imgObj = {
  bg: {
    about: bg_about,
    about_float: bg_about_float, 
    services: {
      custom_software_development: bg_services_custom_software_development,
      website_development: bg_services_website_development,
      mobile_app_development: bg_services_mobile_app_development, 
      react_development: bg_services_react_development,
    },
    services_blur: {
      custom_software_development: bg_services_custom_software_development_blur,
      website_development: bg_services_website_development_blur,
      mobile_app_development: bg_services_mobile_app_development_blur, 
      react_development: bg_services_react_development_blur,
    },
    projects: {
      academia_de_lenguas: bg_project_academia_de_lenguas,
      bullground: bg_project_bullground,
      cadefi: bg_project_cadefi,
      cienpotencia: bg_project_cienpotencia,
      gm_eshop: bg_project_gm_eshop,
      lg_autopartes: bg_project_lg_autopartes,
      medmex: bg_project_medmex,
      wmobil: bg_project_wmobil,
      autio: autio_bg,
      atease: atease_bg,
      herdx: herdx_bg,
      neta: neta,
    },
    projects_blur: {
      academia_de_lenguas: bg_project_academia_de_lenguas_blur,
      bullground: bg_project_bullground_blur,
      cadefi: bg_project_cadefi_blur,
      cienpotencia: bg_project_cienpotencia_blur,
      gm_eshop: bg_project_gm_eshop_blur,
      lg_autopartes: bg_project_lg_autopartes_blur,
      medmex: bg_project_medmex_blur,
      wmobil: bg_project_wmobil_blur,
    },
    project_circles: bg_project_circles,
  },
  bg_trans: {
    white_in: bg_trans_white_in,
    white_out: bg_trans_white_out,
  },
  icon: {
    arrow_down: icon_arrow_down,
    clutch_hollow: icon_clutch_hollow,
    facebook_inside: icon_facebook_inside,
    instagram_inside: icon_instagram_inside,
    linkedin_hollow: icon_linkedin_hollow,
    linkedin_inside: icon_linkedin_inside,
    translate: icon_translate,
    search_icon: icon_service_search_vector,
    paint_pad: icon_paint_pad_vector,
    development: icon_development_vector,
    rocket: icon_rocket_vector,
    can: icon_can_vector,
    check_arrow: icon_check_arrow_vector,
    cloud: icon_cloud_vector,
    probe: icon_probe_vector,
    spanner:icon_spanner_vector,
    stars:icon_stars_vector,
    us_flag:us_flag,
    mx_flag:mx_flag,

  },
  item: {
    functionality: {
      academia_de_lenguas: [
        item_project_academia_de_lenguas_0,
        item_project_academia_de_lenguas_1,
        item_project_academia_de_lenguas_2,
        item_project_academia_de_lenguas_3,
      ],
      bullground: [
        item_project_bullground_0,
        item_project_bullground_1,
        item_project_bullground_2,
        item_project_bullground_3,
        item_project_bullground_4,
        item_project_bullground_5,
        item_project_bullground_6,
        item_project_bullground_7,
        item_project_bullground_8,
        item_project_bullground_9,
      ],
      cadefi: [
        item_project_cadefi_0,
        item_project_cadefi_1,
        item_project_cadefi_2,
        item_project_cadefi_3,
      ],
      cienpotencia: [
        item_project_cienpotencia_0,
        item_project_cienpotencia_1,
        item_project_cienpotencia_2,
      ],
      gm_eshop: [
        item_project_gm_eshop_0,
        item_project_gm_eshop_1,
        item_project_gm_eshop_2,
        item_project_gm_eshop_3,
      ],
      lg_autopartes: [
        item_project_lg_autopartes_0,
        item_project_lg_autopartes_1,
        item_project_lg_autopartes_2,
        item_project_lg_autopartes_3,
        item_project_lg_autopartes_4,
      ],
      medmex: [
        item_project_medmex_0,
        item_project_medmex_1,
        item_project_medmex_2,
        item_project_medmex_3,
      ],
      wmobil: [
        item_project_wmobil_0,
        item_project_wmobil_1,
        item_project_wmobil_2,
        item_project_wmobil_3,
        item_project_wmobil_4,
        item_project_wmobil_5,
        item_project_wmobil_6,
      ],
    },
    service_functionality: {
      custom_software_development: [
        item_service_custom_software_development_0,
      ],
      website_development: [
        service_website_development,
      ],
      mobile_app_development: [
        service_custom_mobile_app_development,
      ],
      react_development: [
        service_development_reactjs,
      ],
    },
    band_image: item_band_image,
    circles: item_circles,
    displays: {
      academia_de_lenguas: [],
      bullground: item_displayer_bullground,
      cadefi: item_displayer_cadefi,
      cienpotencia: item_displayer_cienpotencia,
      gm_eshop: item_displayer_gm_eshop,
      lg_autopartes: [],
      medmex: item_displayer_medmex,
      wmobil: item_displayer_wmobil,
    },
    imgcollection_01: item_imgcollection_01,
    imgcollection_02: item_imgcollection_02,
    imgcollection_03: item_imgcollection_03,
    portfolio01: item_portfolio01,
    portfolio02: item_portfolio02,
  },
  logo: {
    academia_de_lenguas: logo_academia_de_lenguas_white, // corregir
    academia_de_lenguas_white: logo_academia_de_lenguas_white,
    bullground_white: logo_bullground_white,
    bullground: logo_bullground,
    cadefi: logo_cadefi,
    cadefi_white: logo_cadefi_white,
    cienpotencia: logo_cienpotencia,
    cienpotencia_white: logo_cienpotencia_white,
    direxio: logo_direxio,
    // el_señorón: logo_el_señorón,
    lg_autopartes: logo_lg_autopartes,
    lg_autopartes_white: logo_lg_autopartes_white,
    gm_eshop: logo_gm_eshop,
    gm_eshop_white: logo_gm_eshop_white,
    medmex: logo_medmex_white, // corregir
    medmex_white: logo_medmex_white,
    myinfo: logo_myinfo,
    inowu_gradient: logo_inowu_gradient,
    inowu_white: logo_inowu_white,
    sunum: logo_sunum,
    wmobil: logo_wmobil_white, // corregir
    wmobil_white: logo_wmobil_white,
    autio_white: logo_autio_white,
    herdx_white: logo_herdx_white,
    neta_white: neta,
    atease_white: logo_atease_white,
  },
  misc: {},
};

/*
1) Bringing quality finance e-courses to Latam students.
-Bullground is a web platform based on monthly subscription for stock market
recommendations and financial courses.
2) Best contable EdTech of Latam.
-Cadefi is a web platform based on annual subscription for contable courses and certification
as a master.
3) Power coaches impacting with knowledge.
-Cienpotencia is a web platform that has different power coaches where you can subscribe
to them individually and watch the premium content that they offer.
4) Personalized electronics e-commerce
-Gm e-shop is a electronics e-commerce that is different from the other because it offers a
unique UX|UI design that attracts customers.
5) First medication app in Mexico
-Medmex is an app that help doctors and patients two have a clear and clean record of the
clinic history of the user.

`"The team was always willing to help."
INOWU successfully developed a user-friendly site
complete with all the functionalities that the client
required.`,

`"They speak their mind and look for the best solution to
solve a business problem."`,

`"The team at INOWU has always been ready to listen." INOWU
has led a successful project. They have created an easygoing
but professional environment for the client's needs while
encouraging them to express their concerns.`,

`"A great cost, they always offered discounts on iterations."
Although the site has not launched yet, the website has all the
features of an e-commerce site thanks to INOWU's work. The team
communicated regularly with daily meetings and was flexible with
requests.`,

`"They are very creative in accomplishing tasks that give them."
With the new platform, the client has noticed several positive
results: a 30% increase in retention rate, a dramatic reduction in
costs, and higher annual revenue.`,

`"The deliverables that we have received So far, it's been a productive
partnership. The client is impressed with the work done by INOWU, commending
them for their timeliness and quality outputs have been great and completed on
time."`,

`"The final result was as the designers envisioned it." The client gained
noteworthy leads since INOWU launched the web page.`,

`"Communication was always effective for any clarification or change for what
was needed." INOWU delivered the website in time for the launch meeting the
client's expectations. The team established effective communication and delivery
stages were fulfilled on time.`,


`*Patricio Madrazo* ;; #Co-Founder, _Cien potencia_#`,
`*Hector* ;; #CMO, _Bullground_#`,
`*Nicolas Blanco Storz* ;; #App Development Consultant, _My Info App_#`,
`*Leonardo Guerrero* ;; #E-Commerce Manager, _GM E-SHOP_#`,
`*Nancy Avila* ;; #COO, _Cadefi_#`,
`*Luis Gerardo Nuñez Alba* ;; #CEO, _LG AUTOPARTES_#`,
`*Gustavo Fernando Barreto Torija* ;; #CFO, _DIREXIO_#`,
`*Manuel Millones* ;; #CEO, _SUNUM_#`,

*/

/*
  <div style={{ display: "flex", flexDirection: "column" }}>
          <Subtitle
            head={`*Best contable $EdTech$ of Latam.*`}
            align={responsive920 ? "center" : "left"}
            body={`Cadefi is a web platform based on annual subscription for ;; 
          contable courses and certification as a master.`}
            buttonText="$Read the success history$"
            buttonURL="/portfolio/bullground"
            shrink
            buttonTransition={responsive920 ? "shrink both" : "shrink right"}
            style={{
              zIndex: 6,
              paddingBottom: "1rem",
              margin: responsive920 ? "2.5rem 1rem" : "",
            }}
          />

          {!responsive920 ? <></> : <Breaker size={parseRem("530px")} />}

          <ImageHolder
            imageLayer="top"
            canvasPosition={{
              right: "0%",
              top: responsive920 ? "" : parseRem("24.5px"),
              bottom: !responsive920 ? "" : "0px",
            }}
            canvasWidth={"100vw"}
            style={{ marginTop: "auto", zIndex: 4 }}
          >
            <Band
              background={"linear-gradient(92.25deg, #8C82FF 0%, #4592C8 100%)"}
            >
              <Breaker size="15.625rem" />
            </Band>
          </ImageHolder>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Subtitle
            head={`*$Power coaches$ impacting ;; with knowledge.*`}
            align={responsive920 ? "center" : "left"}
            body={`Cienpotencia is a web platform that has different power ;; 
              coaches where you can subscribe to them individually and watch ;;
              the premium content that they offer.`}
            buttonText="$Read the success history$"
            buttonURL="/portfolio/bullground"
            shrink
            buttonTransition={responsive920 ? "shrink both" : "shrink right"}
            style={{
              zIndex: 6,
              paddingBottom: "1rem",
              margin: responsive920 ? "2.5rem 1rem" : "",
            }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Subtitle
            head={`*Personalized electronics $e-commerce$*`}
            align={responsive920 ? "center" : "left"}
            body={`Gm e-shop is a electronics e-commerce that is different from ;;
              the other because it offers a unique UX|UI design that attracts customers.`}
            buttonText="$Read the success history$"
            buttonURL="/portfolio/bullground"
            shrink
            buttonTransition={responsive920 ? "shrink both" : "shrink right"}
            style={{
              zIndex: 6,
              paddingBottom: "1rem",
              margin: responsive920 ? "2.5rem 1rem" : "",
            }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Subtitle
            head={`*First $medication app$ in Mexico*`}
            align={responsive920 ? "center" : "left"}
            body={`Medmex is an app that help doctors and patients two have a ;;
              clear and clean record of the clinic history of the user.`}
            buttonText="$Read the success history$"
            buttonURL="/portfolio/bullground"
            shrink
            buttonTransition={responsive920 ? "shrink both" : "shrink right"}
            style={{
              zIndex: 6,
              paddingBottom: "1rem",
              margin: responsive920 ? "2.5rem 1rem" : "",
            }}
          />
        </div>
*/
