import ServicesScreen from "@screens/ServicesScreen";

const ServicesContainer = () => {
  return (
    <>
      <ServicesScreen />
    </>
  );
};

export default ServicesContainer;
