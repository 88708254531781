import BillsScreen from "@screens/BillsScreen";

function BillsContainer() {
  return (
    <>
      <BillsScreen />
    </>
  );
}

export default BillsContainer;